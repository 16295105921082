import React from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default class AppComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageError: false,
    };
  }

  handleToggleChange = (event) => {
    if (this.props.onModuleDataChange) {
      this.props.onModuleDataChange({
        enabled: event.target.checked,
      });
    }
  };

  render() {
    const {
      color,
      icon,
      name,
      description,
      type,
      minVersion,
      maxVersion,
    } = this.props;
    return (
      <div
        className="AppModuleItem"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          className="AppModule"
          style={{
            backgroundColor: color,
            width: 100,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {this.state.imageError || icon == null || icon.length === 0 ? null : (
            <div
              style={{
                flexShrink: 0,
                flexGrow: 0,
                width: "90%",
                height: "90%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "50% 50%",
                backgroundImage: `url(${icon})`,
              }}
            />
          )}
        </div>
        <div className="AppModuleText">
          <h1>{name}</h1>
          <h2>{description}</h2>
          {this.renderActions()}
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ textAlign: "right" }}>
              minVersion {maxVersion ? `(maxVersion)` : null}
            </h3>{" "}
            <h4 style={{ textAlign: "right" }}>
              {minVersion} {maxVersion ? `(${maxVersion})` : null}
            </h4>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3 style={{ textAlign: "right" }}>type</h3>{" "}
            <h4 style={{ textAlign: "right" }}>{type} </h4>
          </div>
        </div>
      </div>
    );
  }

  renderActions = () => {
    if (!this.props.readOnly) {
      return (
        <div style={{ display: "flex", alignContent: "center" }}>
          <Toggle
            icons={false}
            defaultChecked={this.props.enabled}
            onChange={this.handleToggleChange}
          />
          {this.props.editable ? (
            <div
              style={{ paddingLeft: 5 }}
              className="SmallIcon"
              onClick={this.onEditPressed}
            >
              <img
                alt="settings"
                src={require("../../../../img/settings.png")}
                height={25}
              />
            </div>
          ) : null}
        </div>
      );
    }
  };

  onEditPressed = () => {
    if (this.props.onEditPressed) {
      this.props.onEditPressed();
    }
  };
}
