import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { NotificationManager } from 'react-notifications';
import ReactGA from 'react-ga';

import ActiveOrderCollapsableItem from './ActiveOrderCollapsableItem'
import { closeOrder } from '../actions/orders';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
    orders: state.orders
  }
}

class ActiveOrdersView extends Component {

  _closeOrder = (orderKey, comments) => {
    closeOrder(this.props.configuration.data.id, orderKey, comments, true).then( () => {
      NotificationManager.success('Closed order ' + orderKey);

      ReactGA.event({
        category: 'Food Services',
        action: 'Close Order'
      });

    }).catch( (error) => {
      NotificationManager.error(error);
    });
  }

  _cancelOrder = (orderKey, comments) => {
    closeOrder(this.props.configuration.data.id, orderKey, comments, false).then( () => {
      NotificationManager.success('Cancelled order ' + orderKey);

      ReactGA.event({
        category: 'Food Services',
        action: 'Cancel Order'
      });

    }).catch( (error) => {
      NotificationManager.error(error);
    });
  }

  _renderError = () => {
    var bIsFetching = (this.props.orders.active.currentState === 'FETCHING');
    if (!bIsFetching && this.props.orders.active.data.length === 0) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
          There are no active orders
        </div>
      );
    }
  }

  render() {
    return (
      <div style={{paddingBottom: 20}}>
        <ListGroup>
          <ListGroupItem disabled style={{backgroundColor: '#d9ecdb', paddingTop: 0, paddingBottom: 0}}>
            <div className="ListHeader" style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{flex: 1}}>Active Orders</div>
            </div>
          </ListGroupItem>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ClipLoader loading={this.props.orders.active.currentState === 'FETCHING'}/>
          </div>
          {
            this.props.orders.active.data.map(order => {
              return (
                <ActiveOrderCollapsableItem key={order.id} order={order} onCloseOrder={this._closeOrder} onCancelOrder={this._cancelOrder}/>
              );
            })
          }
          {this._renderError()}
        </ListGroup>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ActiveOrdersView)
