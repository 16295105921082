import React, { useEffect, useState, useCallback } from "react";
import { NotificationManager } from "react-notifications";
import { createModule } from "../actions/settings";
import AppComponent from "./component/AppModule";
import LoadingScreen from "../../../screens/LoadingScreen";
import { fetchAllModules, updateModuleMetaData } from "../actions";
import { Button, Label } from "reactstrap";
import { CreateModuleModal } from "./component/CreateModuleModal";
import { withRouter } from "react-router-dom";

const SublanderModuleSettings = ({ history, site, moduleId }) => {
  const [createModal, setCreateModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const refresh = useCallback(async () => {
    fetchAllModules(site, moduleId)
      .then((modules) => {
        setModules(modules);
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setModules([]);
        setError(true);
        setLoading(false);
      });
  }, [moduleId, site]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const createModuleByType = (type, defaultMinVersion) => async () => {
    try {
      const newModuleId = await createModule(
        site,
        type,
        defaultMinVersion,
        moduleId
      );
      onModuleCreated(newModuleId);
    } catch {
      NotificationManager.error("Unable to create your module");
    }
  };

  const onModuleCreated = (subLanderModuleId) => {
    refresh();
    setCreateModal(false);

    history.push(
      `/modules/app/modules/settings/${moduleId}/${subLanderModuleId}`,
      {
        site: site,
        firstLaunch: true,
      }
    );
  };

  const onEditPressed = (subLanderModuleId) => () => {
    history.push(
      `/modules/app/modules/settings/${moduleId}/${subLanderModuleId}`,
      {
        site: site,
        firstLaunch: false,
      }
    );
  };

  const onModuleDataChange = (subModuleId) => (data) => {
    updateModuleMetaData(site, subModuleId, data)
      .then(() => {
        NotificationManager.success("Module Updated");
        refresh();
      })
      .catch(() => {
        NotificationManager.error("Module Failed to update");
        refresh();
      });
  };

  return (
    <>
      <div className="ModuleSettingsContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h1 style={{ flex: 1 }}>Sub-Modules</h1>
          <Button onClick={() => setCreateModal(true)}>
            Create Sub-Module
          </Button>
        </div>
        <Label>List of modules that belong to this Lander</Label>
        {!loading ? (
          modules.map((module) => (
            <div key={module.id} style={{ minWidth: 500, marginBottom: 10 }}>
              <AppComponent
                color={module.color}
                icon={module.icon}
                name={module.name}
                description={module.description}
                type={module.type}
                enabled={module.enabled}
                minVersion={module.minVersion}
                maxVersion={module.maxVersion}
                onModuleDataChange={onModuleDataChange(module.id)}
                editable={true}
                onEditPressed={onEditPressed(module.id)}
              />
            </div>
          ))
        ) : (
          <LoadingScreen />
        )}
      </div>
      <CreateModuleModal
        isOpen={createModal}
        toggle={() => setCreateModal(false)}
        onModuleTypeSelected={createModuleByType}
      />
    </>
  );
};

export default withRouter(SublanderModuleSettings);
