import store from '../../../store.js'

import { get, getDatabase, ref, set, push, off as stopListeningToRef, onValue, query, limitToLast, remove } from 'firebase/database';

export function listenForOrders(building, limitClosedOrders) {
  console.log("listening to all order transactions");
  listenActiveOrders(building);
  listenClosedOrders(building, limitClosedOrders);
}

export function stopListenerForOrders(building) {
  console.log("Stop listening to all order transactions");
  stopListeningToRef(ref(getDatabase(), `/sites/${building}/food/transactions/`))
}

export async function listenActiveOrders(building) {

  store.dispatch({
    type: 'ORDERS_ACTIVE_FETCHING',
    payload: {}
  })

  try {
    const activeOrdersRef = ref(getDatabase(), `/sites/${building}/food/transactions/active`);
    onValue(activeOrdersRef, (data) => {
      store.dispatch({
        type: 'ORDERS_ACTIVE_COMPLETE',
        payload: data.val()
      });
    });
  } catch (error) {
    console.log("Error fetching active transaction orders");

    store.dispatch({
      type: 'ORDERS_ACTIVE_ERROR',
      error: error.code
    });
  }
}

export function closeOrder(building, orderKey, comments, fulfilled) {
  return new Promise(async (resolve, reject) => {
    try {
      const activeRef = ref(getDatabase(), `/sites/${building}/food/transactions/active/${orderKey}`);
      const snapshot = await get(activeRef);

      let newClosedOrder = snapshot.val();

        if (comments == null) {
          comments = "";
        }

        // update with comments, status and fulfillment
        newClosedOrder.status = 'CLOSED';
        newClosedOrder.closedComments = comments;
        newClosedOrder.fulfilled = fulfilled;
        newClosedOrder.closedTimestamp = new Date().getTime();

        const newClosedRef = ref(getDatabase(), `/sites/${building}/food/transactions/closed`);
        await push(newClosedRef, newClosedOrder);
        await remove(activeRef);
        resolve();
    } catch {
      reject('Unable to find order to close.')
    }
  });

}

export async function fetchClosedOrders(building, limit) {

  if (limit) {
    console.log("Querying closed orders to limit " + limit)
  }

  try {
    const closedTransactionsQuery = query(ref(getDatabase(), `/sites/${building}/food/transactions/closed`), limitToLast(limit));
    const data = await get(closedTransactionsQuery);
    store.dispatch({
      type: 'ORDERS_CLOSED_COMPLETE',
      payload: data.val()
    });
  } catch (error) {
    console.log("Error fetching transaction orders");

    store.dispatch({
      type: 'ORDERS_CLOSED_ERROR',
      error: error.code
    });
  }

}

function listenClosedOrders(building, limit) {

  if (limit) {
    console.log("Querying to limit " + limit)
  } else {
    limit = 50; // upper limit
  }

  store.dispatch({
    type: 'ORDERS_CLOSED_FETCHING',
    payload: {}
  })

  try {
    const ordersRef = ref(getDatabase(), `/sites/${building}/food/transactions/closed`);
    onValue(ordersRef, (data) => {
      store.dispatch({
        type: 'ORDERS_CLOSED_COMPLETE',
        payload: data.val()
      });
    });
  } catch (error) {
    console.log("Error fetching closed transaction orders");

    store.dispatch({
      type: 'ORDERS_CLOSED_ERROR',
      error: error.code
    });
  }

}

export function fetchNotificationEmails(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const settingsRef = ref(getDatabase(), `/sites/${site}/food/config/notifications/emails`);
      const data = await get(settingsRef);
      resolve(data.val() || []);
    } catch {
      reject();
    }
  })
}

export function updateNotificationEmails(site, emails) {
  return new Promise(async (resolve, reject) => {
    try {
      const notificationEmailsRef = ref(getDatabase(), `/sites/${site}/food/config/notifications/emails`);
      await set(notificationEmailsRef, emails);
      resolve();
    } catch {
      reject('Unable to update menu');
    }
  })
}