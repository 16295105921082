import React, { Component } from 'react';
import { Button, Container, Col, Row, Collapse, ListGroupItem, Form, Input } from 'reactstrap';

export default class ActiveOrderCollapsableItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
  }

  _toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  _completeOrder = (event) => {

    this.props.onCloseOrder(this.props.order.id, this.state.comments);

    event.preventDefault();
  }

  _cancelOrder = (event) => {
    this.props.onCancelOrder(this.props.order.id, this.state.comments);

    event.preventDefault();
  }

  render() {

    const { email, room, openedTimestamp, notes, items, takeOut, pickupTime } = this.props.order;

    let bgStyle = {};
    if (this.state.collapse) {
      bgStyle = {backgroundColor: '#e9ecef'};
    }
    return (
      <ListGroupItem  className="CollapsingLineItem" style={bgStyle}>

      <Container className="ActiveCollapseItem">
        <Row onClick={this._toggle} style={{display: 'flex'}}>
          <Col>
          <h1>{email}</h1>
          <span>Room: {room}</span>
          </Col>
          <Col xs='auto'>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <h1>{openedTimestamp}</h1>
            </div>
          </Col>
        </Row>
      </Container>


        <Collapse isOpen={this.state.collapse}>
          <div style={{marginLeft: 15, marginRight: 10, marginBottom: 0, paddingTop: 5}}>
          <hr/>
          Order Received: {openedTimestamp}<br/>
          Takeout: { takeOut ? 'Yes' : 'No' }<br/>
          To Be Ready For: {pickupTime}<br/><br/>
          Order:
          <hr style={{margin:0}}/>
            <div className="TransactionGrid">
              <div>Item</div>
              <div>Price</div>
            {
              items.map(item => {
                return (
                  [
                    <div key="item" className="TransactionGridLeft">{item.description}</div>,
                    <div key="price" className="TransactionGridRight">{item.price}</div>
                  ]
                );
              })
            }
            </div>
            <div style={{marginLeft: 20}}>
              Order Notes:
              <div style={{backgroundColor: '#F9F9F9', border: '1', padding: 20}}>
              {notes}
              </div>
            </div>
            <hr/>

            <Form>

              <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Input type="text" name="comments" id="comments" placeholder="Notes" defaultValue={this.state.comments} onChange={ (event) => this.setState({comments: event.target.value})} />
                  <div style={{flex: 1, display: 'flex', flexDirection: 'row', marginTop: 10}}>
                    <div><Button color="danger" onClick={this._cancelOrder}>Cancel Order</Button></div>
                    <div style={{flex: 1}} />
                    <div><Button color="success" onClick={this._completeOrder}>Complete Order</Button></div>
                  </div>
              </div>
            </Form>


          </div>
        </Collapse>
      </ListGroupItem>
    )
  }
}
