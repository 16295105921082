import React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ModuleHeader from '../../../components/ModuleHeader';
import { listenForOrders, stopListenerForOrders } from '../actions/orders';
import ActiveOrdersView from './ActiveOrdersView';
import ClosedOrdersView from './ClosedOrdersView';

const NUM_CLOSED_ORDERS = 5;

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class OrderScreen extends React.Component {

  componentDidMount() {
    listenForOrders(this.props.configuration.data.id, NUM_CLOSED_ORDERS);
  }

  componentWillUnmount() {
    ReactGA.pageview('/modules/food/orders/');
    stopListenerForOrders(this.props.configuration.data.id);
  }

  render() {
    return (
      <div>
        <ModuleHeader title='Food Services' subTitle='Orders' />
        <ActiveOrdersView />
        <ClosedOrdersView />
      </div>

    );
  }
}

export default connect(mapStateToProps)(OrderScreen)