import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import ModuleHeader from '../../../components/ModuleHeader';
import 'react-toggle/style.css';
import { fetchSignFlagConfiguration, updateSignConfiguration } from '../action';
import { NotificationManager } from 'react-notifications';
import ReactGA from 'react-ga';

const FEATURE_FLAG_WEATHER = 'feature_flag_weather';
const FEATURE_FLAG_MENU = 'feature_flag_menu';

const configurable_settings = [FEATURE_FLAG_WEATHER, FEATURE_FLAG_MENU];
const settings = [
  {
    title: 'Display Local Weather',
    description: 'Enable to display local 5 day forecast.',
    type: 'toggle',
    node: FEATURE_FLAG_WEATHER,
  },
  {
    title: 'Display Daily Menu',
    description: 'Enable to display the daily menu',
    type: 'toggle',
    node: FEATURE_FLAG_MENU,
  },
]

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      flags: {}
    }
  }
  componentDidMount() {
    ReactGA.pageview('/modules/sign/settings');
    
    this.refreshSettings();
  }

  refreshSettings = () => {
    fetchSignFlagConfiguration(this.props.configuration.data.id).then( flags => {
      this.setState({ flags: flags });
    });
  }

  render() {
    return (
      <div>
        <ModuleHeader title='Digital Sign' subTitle='Settings' />
        <div className="Body">
          <div className="SettingsContainer">
            {
              settings.map( item => {
                // check if we support this flag
                if (this.state.flags[item.node] != null) {
                  return (
                    <div key={item.title} className="SettingsItem" style={{display: 'flex', flexDirection: 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row', flex: 1, justifyItems: 'center'}}>
                        <div style={{flex: 1}}>
                          <h1>{item.title}</h1>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          {
                            this.renderControl(item.type, item.node, this.state.flags[item.node])
                          }
                        </div>
                      </div>
                      <div>
                        <h2>{item.description}</h2>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            }

            
          </div>
        </div>
      </div>
    )
  }

  renderControl = (type, node, value) => {
    if (type === 'toggle') {
      return <Toggle defaultChecked={value} onChange={this.onSettingsChanged(node)} />;
    }
  }

  onSettingsChanged = node => event => {
    if (configurable_settings.includes(node)) {
      const checked = event.target.checked;
      updateSignConfiguration(this.props.configuration.data.id, node, checked).then( () => {
        NotificationManager.success('Settings have been updated');
        this.refreshSettings();

        ReactGA.event({
          category: 'Digital Sign',
          action: 'Toggled Setting',
          label: node,
          value: Number(checked)
        });

      }).catch( () => {
        NotificationManager.error('Unable to update settings');
      })
    }
  }
}

export default connect(mapStateToProps)(SettingsScreen)
