import { useEffect, useState } from "react";
import { fetchTickets } from "../action";
import { useSelector } from "react-redux";
import ModuleHeader from "../../../components/ModuleHeader";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";

const PAGINATION_NUMBER = 1;

const StateColor = {
  open: "success",
  closed: "danger",
};

export const TicketListView = ({ type, title }) => {
  const configuration = useSelector((state) => state.configuration);
  const history = useHistory();
  const [tickets, setTickets] = useState(null);

  const [pageNumber, setPageNumber] = useState(1);
  const [hideClosed, setHideClosed] = useState(false);

  useEffect(() => {
    reload();
  }, [configuration]);

  useEffect(() => {
    reload();
  }, [pageNumber]);

  useEffect(() => {
    reload();
  }, [hideClosed]);

  const reload = async () => {
    setTickets(
      await fetchTickets(configuration.data.id, type, pageNumber, hideClosed)
    );
  };

  const fetchNextBath = async () => {
    setPageNumber((page) => page + 1);
  };

  const truncate = (text) => {
    return text
      ? text.length > 150
        ? text.substring(0, 150) + "..."
        : text
      : "";
  };

  return (
    <>
      <ModuleHeader title="Ticketing" subTitle={title}>
        <Dropdown />
      </ModuleHeader>
      <Form>
        <FormGroup
          style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
        >
          <Label
            for="showClosedTickets"
            onClick={() => setHideClosed((value) => !value)}
          >
            <Input
              readOnly
              name="showClosedTickets"
              type="checkbox"
              checked={!hideClosed}
              style={{ marginRight: 8 }}
            />
            Show Closed Tickets
          </Label>
        </FormGroup>
      </Form>
      <ListGroup>
        <ListGroupItem
          disabled
          style={{
            backgroundColor: "#e8ecef",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <div
            className="ListHeader"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div style={{ width: 125, paddingRight: 10 }}></div>
            <div style={{ flex: 1 }}>Description</div>
            <div style={{ textAlign: "right" }}>Request Type</div>
            <div style={{ textAlign: "right", width: 70 }}>Status</div>
          </div>
        </ListGroupItem>
        {tickets && tickets.length === 0 ? (
          <div style={{ flex: 1, textAlign: "center", marginTop: 10 }}>
            No Tickets Found
          </div>
        ) : null}
        {tickets?.map((ticket) => {
          return (
            <ListGroupItem
              key={ticket.id}
              action
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push(`/modules/ticketing/${type}/${ticket.urn}`);
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{ width: 125, paddingRight: 10, fontSize: "small" }}
                >
                  {moment(ticket.createdDate).fromNow()}
                </div>
                <div style={{ flex: 1 }}>{truncate(ticket.description)}</div>
                <div style={{ textAlign: "right" }}>
                  <Badge>{ticket.tag}</Badge>
                </div>
                <div style={{ textAlign: "right", width: 70 }}>
                  <Badge color={StateColor[ticket.state]}>{ticket.state}</Badge>
                </div>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <div
        style={{
          display: "flex",
          marginTop: 10,
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <Button
          disabled={tickets?.length < PAGINATION_NUMBER}
          onClick={fetchNextBath}
        >
          View More
        </Button>
      </div>
    </>
  );
};
