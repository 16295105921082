import React, { Fragment, useEffect, useState } from "react";
import ContentStatic from "./PreviewDigitalSign";
import PreviewIpad from "./PreviewIpad";
import { Transition } from "react-transition-group";

const ImageWrapper = ({ isLoaded, children }) => {
  return (
    <Transition in={isLoaded} timeout={0}>
      {(state) => <div className={`imageFade-${state}`}>{children}</div>}
    </Transition>
  );
};

const LoadedImage = ({
  mode,
  canvasWidth,
  canvasHeight,
  containImage,
  imageSource,
  onUploadNewImage,
  onLoaded,
  blurImage,
}) => {
  const [imageRawProperties, setImageRawProperties] = useState(null);
  const [imageRefinedProperties, setImageRefinedProperties] = useState({
    width: null,
    height: null,
    fit: "cover",
    styling: {},
  });

  useEffect(() => {
    setImageRawProperties(null);
    setImageRefinedProperties({ width: null, height: null, fit: "cover" });
    onLoaded(false);
  }, [imageSource]);

  useEffect(() => {
    if (mode === "ds") {
      setImageRefinedProperties({
        width: canvasWidth,
        height: canvasHeight,
        fit: "cover",
        styling: blurImage ? { opacity: 0.3 } : { opacity: 1 },
      });
    } else {
      if (imageRawProperties) {
        const largeImage = imageRawProperties.width > canvasWidth;
        const fitStyling = largeImage
          ? containImage
            ? {
                aspectRatio:
                  imageRawProperties.width / imageRawProperties.height,
              }
            : { height: 200 }
          : {
              aspectRatio: imageRawProperties.width / imageRawProperties.height,
            };
        const imageSizeStying = largeImage ? {} : { margin: 10 };
        const maxWidth = largeImage ? canvasWidth : imageRawProperties.width;
        const resizeMode = largeImage
          ? containImage
            ? "contain"
            : "cover"
          : "contain";

        setImageRefinedProperties({
          width: maxWidth,
          fit: resizeMode,
          styling: { ...imageSizeStying, ...fitStyling },
        });
      }
    }
  }, [imageRawProperties, containImage, blurImage]);

  useEffect(() => {
    if (imageRefinedProperties.width) {
      onLoaded(true);
    }
  }, [imageRefinedProperties]);

  return (
    <label style={{ cursor: "pointer" }} onClick={() => onUploadNewImage()}>
      <img
        alt=""
        style={{
          height: imageRefinedProperties.height,
          width: imageRefinedProperties.width,
          objectFit: imageRefinedProperties.fit,
          ...imageRefinedProperties.styling,
        }}
        src={imageSource}
        onLoad={(event) => {
          setImageRawProperties({
            width: event.target.width,
            height: event.target.height,
          });
        }}
        onError={() => {
          this.setState({
            progress: "Error displaying image. Click to upload new image",
          });
        }}
      />
    </label>
  );
};

class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: props.initialValue == null ? "" : props.initialValue,
      progress: "Click to Select Image",
      imageLoaded: false,
    };
  }

  _uploadImage = () => {
    if (this.props.onUploadImage) {
      this.props.onUploadImage();
    }
  };

  renderUploader = (width, height) => {
    return (
      <label
        style={{
          display: "flex",
          backgroundColor: "#e9ecef",
          color: "white",
          padding: 10,
          borderRadius: 10,
          cursor: "pointer",
          height: height,
          width: width,
        }}
      >
        <div
          onClick={() => this._uploadImage()}
          className="ImageSelector"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            borderRadius: 10,
            backgroundColor: "#FFF",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ color: "#e9ecef" }}>{this.state.progress}</h1>
        </div>
      </label>
    );
  };

  renderSetImage = (width, height, bBlur = false) => {
    // if we are to just show a background colour

    if (this.props.imageUrl && this.props.imageUrl.length > 0) {
      return (
        <ImageWrapper isLoaded={this.state.imageLoaded}>
          <LoadedImage
            mode={this.props.previewMode}
            canvasWidth={width}
            canvasHeight={height}
            imageSource={this.props.imageUrl}
            containImage={this.props.containImage}
            onUploadNewImage={this._uploadImage}
            onLoaded={(loaded) => {
              this.setState({ imageLoaded: loaded });
            }}
            blurImage={bBlur}
          />
        </ImageWrapper>
      );
    } else {
      return this.renderUploader(width, height);
    }
  };

  render() {
    const { width, title, details, signOptions, imageUrl } = this.props;
    if (this.props.previewMode === "ds") {
      // do not display any overlay, just display the image
      // if (signOptions.overlay === "none") {
      //   return this.renderSetImage(width, width * 0.4167);
      // }
      return (
        <Fragment>
          <ContentStatic
            width={width}
            height={width * 0.4167}
            title={title}
            details={details}
            imageSource={imageUrl}
            signOptions={signOptions}
          >
            {signOptions.backgroundImage
              ? this.renderSetImage(
                  width,
                  width * 0.4167,
                  signOptions.fullImage
                )
              : null}
          </ContentStatic>
        </Fragment>
      );
    } else if (this.props.previewMode === "ipad") {
      return (
        <Fragment>
          <PreviewIpad title={title} details={details}>
            {this.renderSetImage(width, width * 0.277)}
          </PreviewIpad>
        </Fragment>
      );
    } else if (this.props.imageUrl && this.props.imageUrl.length > 0) {
      return this.renderSetImage(width, width * 0.4167);
    } else {
      return this.renderUploader(width, width * 0.4167);
    }
  }
}

export default ImageUploader;
