import { Input, Button, Label, FormGroup, Form } from "reactstrap";
import ModuleHeader from "../../../components/ModuleHeader";
import { useEffect, useState } from "react";
import {
  addEmail,
  createTag,
  deleteTag,
  fetchTags,
  getEmails,
  removeEmail,
} from "../action";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const TicketingTypeSettings = ({ type }) => {
  const configuration = useSelector((state) => state.configuration);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    reload();
  }, [configuration]);

  const reload = async () => {
    setTags(await fetchTags(configuration.data.id, type));
  };

  const onDelete = async (event) => {
    if (
      await deleteTag(configuration.data.id, type, event.target.tag_key.value)
    ) {
      NotificationManager.success("Tag Deleted");
    } else {
      NotificationManager.error("Tag failed to delete");
    }

    event.preventDefault();
  };

  const onCreate = async (event) => {
    if (
      await createTag(
        configuration.data.id,
        type,
        event.target.tag_id.value,
        event.target.tag_title.value,
        event.target.tag_description.value
      )
    ) {
      NotificationManager.success("Tag Created");
    } else {
      NotificationManager.error("Tag failed to create");
    }

    event.preventDefault();
  };

  return (
    <div
      key={type}
      className="SettingsContainer"
      style={{ marginBottom: 30, padding: 8 }}
    >
      <h1>{`${type} ticket types`}</h1>
      <div
        key={type}
        className="SettingsItem"
        style={{ display: "flex", flexDirection: "column", border: 0 }}
      >
        <Form
          onSubmit={onCreate}
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyItems: "center",
            margin: 20,
            backgroundColor: "#f9f9f9",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              paddingTop: 10,
            }}
          >
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Label style={{ flex: 1, marginRight: 4 }}>Tag ID</Label>
              <Input
                name="tag_id"
                style={{
                  flex: 1,
                  marginRight: 10,
                  minWidth: 200,
                  textAlign: "right",
                }}
                placeholder="Tag"
                onChange={(event) => {}}
              />
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Label style={{ flex: 1, marginRight: 4 }}>Title (In App)</Label>
              <Input
                name="tag_title"
                style={{
                  flex: 1,
                  marginRight: 10,
                  minWidth: 200,
                  textAlign: "right",
                }}
                placeholder="Title"
                onChange={(event) => {}}
              />
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <Label style={{ flex: 1, marginRight: 4 }}>Description</Label>
              <Input
                name="tag_description"
                style={{
                  flex: 1,
                  marginRight: 10,
                  minWidth: 200,
                  textAlign: "right",
                }}
                placeholder="Description"
              />
            </FormGroup>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button type="submit" color="success">
              Create
            </Button>
          </div>
        </Form>
        <hr />
        {tags.map((tag) => {
          return (
            <Form
              key={tag.tag}
              onSubmit={onDelete}
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyItems: "center",
                margin: 20,
                backgroundColor: "#f9f9f9",
                padding: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  paddingTop: 10,
                }}
              >
                <Input type="hidden" name="tag_key" value={tag.id} />
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Label style={{ flex: 1, marginRight: 4 }}>Tag ID</Label>
                  <Input
                    style={{
                      flex: 1,
                      marginRight: 10,
                      minWidth: 200,
                      textAlign: "right",
                    }}
                    disabled
                    placeholder="Tag"
                    defaultValue={tag.tag}
                    onChange={(event) => {}}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Label style={{ flex: 1, marginRight: 4 }}>
                    Title (In App)
                  </Label>
                  <Input
                    style={{
                      flex: 1,
                      marginRight: 10,
                      minWidth: 200,
                      textAlign: "right",
                    }}
                    disabled
                    placeholder="Title"
                    defaultValue={tag.title}
                    onChange={(event) => {}}
                  />
                </FormGroup>
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Label style={{ flex: 1, marginRight: 4 }}>Description</Label>
                  <Input
                    style={{
                      flex: 1,
                      marginRight: 10,
                      minWidth: 200,
                      textAlign: "right",
                    }}
                    disabled
                    placeholder="Description"
                    defaultValue={tag.description}
                    onChange={(event) => {}}
                  />
                </FormGroup>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button type="submit" color="danger">
                  Delete
                </Button>
              </div>
            </Form>
          );
        })}
      </div>
    </div>
  );
};

export const TicketingSettings = ({ types }) => {
  const configuration = useSelector((state) => state.configuration);
  const [notificationEmails, setNotificationEmails] = useState([]);

  useEffect(() => {
    reload();
  }, [configuration]);

  const reload = async () => {
    setNotificationEmails(await getEmails(configuration.data.id));
  };

  const onNewEmail = async (event) => {
    event.preventDefault();
    if (await addEmail(configuration.data.id, event.target.newEmail.value)) {
      NotificationManager.success("Email address added");
      reload();
    } else {
      NotificationManager.error("Unable to add email address");
    }
  };

  const onRemoveEmail = async (emailKey) => {
    if (await removeEmail(configuration.data.id, emailKey)) {
      NotificationManager.success("Email address removed");
      reload();
    } else {
      NotificationManager.error("Unable to remove email address");
    }
  };

  return (
    <div>
      <ModuleHeader title="Ticketing" subTitle="Settings" />
      <div className="Body">
        {types.map((type) => {
          return <TicketingTypeSettings key={type} type={type} />;
        })}
        <div className="SettingsContainer" style={{ marginBottom: 20 }}>
          <div
            className="SettingsItem"
            style={{ display: "flex", flexDirection: "column", border: 0 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyItems: "center",
              }}
            >
              <div style={{ flex: 1 }}>
                <h1>Notification Settings</h1>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <Toggle defaultChecked={true} /> */}
              </div>
            </div>
            <div
              style={{ borderBottom: "1px solid #d3d3d3", paddingBottom: 10 }}
            >
              <h2>
                Email Addresses to receive notifications when tickets are
                created
              </h2>
            </div>
            <div className="SettingsEmailList">
              <Form
                style={{ display: "flex", flexDirection: "row" }}
                onSubmit={onNewEmail}
              >
                <div style={{ width: 250, paddingRight: 10 }}>
                  <Input
                    type="email"
                    placeholder="Add email address"
                    name="newEmail"
                  />
                </div>
                <Button color="success" type="submit" style={{ width: 50 }}>
                  +
                </Button>
              </Form>

              {notificationEmails
                ? notificationEmails.map((email, index) => {
                    return (
                      <div
                        key={email.id}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingTop: 5,
                        }}
                      >
                        <div className="SettingsEmailItem">{email.value}</div>
                        <img
                          alt="delete"
                          src={require("./../../../img/delete.png")}
                          style={{
                            marginLeft: 10,
                            height: 15,
                            width: 15,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            onRemoveEmail(email.id);
                          }}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
