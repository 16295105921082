import React from "react";
import UserManagementScreen from "./view/UserManagementScreen";
import AppModuleScreen from "./view/AppModuleScreen";
import UserScreen from "./view/UserScreen";
import { AppModuleSettingsScreen } from "./view/AppModuleSettingsScreen";

const config = {
  sidebar: {
    title: "App Management",
    items: [
      {
        title: "Residents",
        path: "/modules/app/users",
      },
      {
        title: "Modules",
        path: "/modules/app/modules",
      },
    ],
  },
  routes: [
    {
      path: "/modules/app/users/create",
      main: () => <UserScreen />,
    },
    {
      path: "/modules/app/users",
      main: () => <UserManagementScreen />,
    },
    {
      path: "/modules/app/modules/settings/:parentId/:id",
      main: () => <AppModuleSettingsScreen />,
    },
    {
      path: "/modules/app/modules/settings/:id",
      main: () => <AppModuleSettingsScreen />,
    },
    {
      path: "/modules/app/modules",
      main: () => <AppModuleScreen />,
    },
  ],
};

export default config;
