import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const MODULE_CREATE_TYPES = [
  {
    type: "link",
    label: "External Link",
    description: "Launch a web browser with a specific web address",
    defaultMinVersion: "1.0.0",
  },
  {
    type: "webview",
    label: "Webview",
    description: "Open a screen in the app to a specific web address",
    defaultMinVersion: "1.0.0",
  },
  {
    type: "sublander",
    label: "SubLander",
    description: "A way to group modules together on a lander ",
    defaultMinVersion: "1.0.6",
  },
  {
    type: "feedback",
    label: "Comment Card",
    description: "Provide users a way to submit feedback",
    defaultMinVersion: "1.0.6",
  },
];

const CreateModuleModal = ({ isOpen, toggle, onModuleTypeSelected }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Select Module Type</ModalHeader>
      <ModalBody>
        {MODULE_CREATE_TYPES.map((moduleToCreate) => (
          <div
            key={moduleToCreate.type}
            className="LargeModalButtonDark"
            onClick={onModuleTypeSelected(
              moduleToCreate.type,
              moduleToCreate.defaultMinVersion
            )}
          >
            <h1>{moduleToCreate.label}</h1>
            <h2>{moduleToCreate.description}</h2>
          </div>
        ))}
      </ModalBody>
    </Modal>
  );
};

export { CreateModuleModal };
