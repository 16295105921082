import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import { unregister } from './registerServiceWorker';

import { Provider } from 'react-redux';
import store from './store';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import 'bootstrap/dist/css/bootstrap.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>,
  </React.StrictMode>
);

unregister();
