import React from 'react';

export default class BackButton extends React.PureComponent {
  render() {
    const { title } = this.props;
    return (
      <div className="BackButton" style={{ padding: 8 }} onClick={this.props.onClick}>
        <img alt='back' src={require('../img/back.png')} />
        {title}
      </div>
    );

  }
}