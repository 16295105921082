import React, { Fragment, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { authStateMonitor, setUserDefaultSite } from './actions/authentication';
import { fetchConfiguration } from './actions/config';

import { BrowserRouter } from 'react-router-dom';
import { ActionRoutes } from './navigation/ActionRoutes';
import LoadingScreen from './screens/LoadingScreen';
import NavHeader from './components/NavHeader';
import { initializeApp } from 'firebase/app';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactGA from 'react-ga';

const Config = process.env.REACT_APP_FB_ENV === "prod" ? require('./FirebaseConstants-prod.json') : require('./FirebaseConstants-dev.json');

// initialize global firebase configuration
initializeApp(Config.firebase);

// initialize google analytics
ReactGA.initialize(Config.ga);

const NewApp = () => {
  const authentication = useSelector(state => state.authentication);
  const configuration = useSelector(state => state.configuration);

  useEffect(() => {
    // track auth state
    authStateMonitor();
  }, []);

  // when user logs in, fetch site configuration
  useEffect(() => {
    if (authentication.loggedIn && authentication.user?.defaultSite) {
      fetchConfiguration(authentication.user.defaultSite);
    }
  }, [authentication.loggedIn, authentication.user]);

  const onRouterRef = useCallback(ref => {
    if (ref) {
      ref.history.listen(() => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  // when our site changes, let's grab new configuration
  const onSiteChange = useCallback(site => {
    setUserDefaultSite(site);
    fetchConfiguration(site);
  }, []);

  const authProcessing = authentication.initializing || authentication.processing;
  const configProcessing = authentication.loggedIn && configuration.currentState !== 'SUCCESS';
  const bShowSpinner = authProcessing || configProcessing;
  return (
    <Fragment>
      <NavHeader onSiteChange={onSiteChange} />
      <BrowserRouter ref={onRouterRef}>
        <div>
          <NotificationContainer />
          {bShowSpinner ? (
            <div style={{ marginTop: 30 }}>
              <LoadingScreen />
            </div>
          ) : (
            <Fragment>
              <ActionRoutes />
            </Fragment>
          )}
        </div>
      </BrowserRouter>
    </Fragment>
  );
};

export default NewApp;
