import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { userLogin } from '../actions/authentication'

const mapStateToProps = function(state){
  return {
    authentication: state.authentication
  }
}

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidMount() {
    if (this.props.authentication.error) {
      this.setState({ error: 'Invalid email/password combination' });
    }
  }

  render() {
    if (!this.props.authentication.loggedIn) {
      return (
        <div className="App">
          {this.renderLoginForm()}
        </div>
      );
    }
  }

  renderLoginError = () => {
    return (
      <div style={{paddingBottom: 20, color: 'red'}}>{this.state.error}</div>
    )
  }

  renderLoginForm = () => {
    if (this.props.authentication.processing || this.props.authentication.initializing) {
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <ClipLoader loading={true}/>
        </div>
      )
    } else {
      return (
        <div className="LoginForm">
          <div>
            <Form onSubmit={this._handleSubmit}>
              <FormGroup>
                <Label for="userEmail">Email</Label>
                <Input type="email" width={200} name="email" id="userEmail" placeholder="" onChange= { (event) => { this.setState({email: event.target.value}) }}/>
              </FormGroup>
              <FormGroup>
                <Label for="userPassword">Password</Label>
                <Input type="password" name="password" id="userPassword" placeholder="" onChange= { (event) => { this.setState({password: event.target.value}) }}/>
              </FormGroup>
              {this.renderLoginError()}
              <div>
                <Button color="success" type="submit">Submit</Button>
              </div>
            </Form>
          </div>
        </div>
      )
    }
  }

  _handleSubmit = (event) => {

    if (this.state.email && this.state.password) {
      userLogin(this.state.email, this.state.password)
    } else {
      this.setState({error: 'Email and Password required to login'})
    }

    event.preventDefault();
  }
}

export default connect(mapStateToProps)(LoginScreen);
