import React, { Component, createElement } from "react";
import ReactMarkdown from "react-markdown";

class ContentStatic extends Component {
  render() {
    const {
      width,
      height,
      title,
      details,
      signOptions,
      imageSource,
    } = this.props;

    let multiplier = 0;
    if (signOptions.overlay === "half") {
      multiplier = 0.5;
    } else if (signOptions.overlay === "full") {
      multiplier = 1;
    }
    const overlayWidth = width * multiplier;
    const imageOverlayWidth = width * 0.5;
    return (
      <div style={{ position: "relative", width, height }}>
        <div
          key={0}
          style={{
            position: "absolute",
            width: overlayWidth,
            height: height,
            backgroundColor: "black",
            opacity: "0.5",
            zIndex: 98,
            pointerEvents: "none",
            top: 0,
            left: 0,
          }}
        />
        <div
          key={1}
          className="PreviewDigitalSign"
          style={{
            position: "absolute",
            width: overlayWidth,
            height: height,
            overflow: "hidden",
            fontSize: 12,
            zIndex: 99,
            pointerEvents: "none",
            top: 20,
          }}
        >
          <h1
            style={{
              color: "white",
              padding: 10,
              fontWeight: 500,
              fontSize: "3em",
            }}
          >
            {title}
          </h1>
          <div
            style={{
              fontSize: "1.5em",
              color: "white",
              padding: 10,
              paddingTop: 0,
              fontWeight: 500,
            }}
          >
            <ReactMarkdown
              children={details}
              components={{
                list: ListWithPadding,
                image: () => null,
              }}
            />
          </div>
        </div>
        <div
          key={4}
          style={{
            position: "absolute",
            width: imageOverlayWidth,
            height: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            top: 0,
            right: 0,
            zIndex: 98,
            pointerEvents: "none",
            opacity: signOptions.fullImage ? 1 : 0,
          }}
        >
          <div>
            <img
              alt=""
              src={imageSource}
              width={imageOverlayWidth * 0.8}
              height={null}
              style={{ maxHeight: height }}
            />
          </div>
        </div>
        <div
          key={3}
          style={{
            height,
            width,
            backgroundColor: signOptions.backgroundImage
              ? "#000"
              : signOptions.backgroundColor,
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

function ListWithPadding(props) {
  var attrs = getCoreProps(props);
  if (props.start !== null && props.start !== 1) {
    attrs.start = props.start.toString();
  }
  return createElement(
    "div",
    { style: { padding: 10, fontSize: "1em" } },
    createElement(props.ordered ? "ol" : "ul", attrs, props.children)
  );
}

function getCoreProps(props) {
  return props["data-sourcepos"]
    ? { "data-sourcepos": props["data-sourcepos"] }
    : {};
}

export default ContentStatic;
