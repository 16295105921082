import React, { Component } from 'react';

import { Button, Form, Input, InputGroup, InputGroupText } from 'reactstrap';

var itemCounter = 0;

export default class MenuSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuSection: this._getMenuSectionFromProps(props),
      newItem: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.menuSection !== prevProps.menuSection) {
      this.setState({ menuSection: this._getMenuSectionFromProps(this.props) })
    }
  }

  _getMenuSectionFromProps = (props) => {
    let initialItems = [];
    let keyedItems = [];
    let note = (props.menuSection != null ? props.menuSection.note : null);
    if (props.menuSection != null) {
      initialItems = props.menuSection.items ? props.menuSection.items.slice() : [];
      initialItems.map(item => {
        return keyedItems.push({
          key: itemCounter++,
          data: item
        });
      })
    }

    let menuSection = {
      items: keyedItems,
    };
    if (note != null) {
      menuSection.note = note;
    }

    return menuSection;
  }

  shouldComponentUpdate(nextProps, nextState) {

    // notify parent of change of state
    if (this.state.menuSection !== nextState.menuSection) {
      // remove wrapper when saving to database
      let lineItems = [];
      nextState.menuSection.items.map(item => {
        return lineItems.push(item.data);
      });

      // update what is not null
      let data = { items: lineItems }
      if (nextState.menuSection.note != null) {
        data.note = nextState.menuSection.note;
      }
      this.props.onUpdate(nextProps.id, data);
    }

    // default to base behaviour
    return true;
  }

  _renderLineItem = (index, id, description, details, price) => {
    return (
      <div key={id} style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
        <Input style={{ flex: '3', marginRight: 10, minWidth: 200 }} defaultValue={description} onChange={(event) => {

          // update state to reflect new value
          let note = this.state.menuSection.note;
          var currentLineItems = this.state.menuSection.items.slice();
          currentLineItems[index].data.description = event.target.value;

          let section = { items: currentLineItems };
          if (note != null) {
            section.note = note;
          }

          this.setState({ menuSection: section });


        }} />
        <Input style={{ flex: '8', marginRight: 10 }} defaultValue={details} onChange={(event) => {

          let note = this.state.menuSection.note;
          var currentLineItems = this.state.menuSection.items.slice();
          currentLineItems[index].data.details = event.target.value;

          let section = { items: currentLineItems };
          if (note != null) {
            section.note = note;
          }

          this.setState({ menuSection: section });

        }} />
        <InputGroup style={{ flex: '2', marginRight: 10, minWidth: 95 }}>
          <InputGroupText>$</InputGroupText>
          <Input defaultValue={price} onChange={(event) => {

            // update state to reflect new value
            let note = this.state.menuSection.note;
            var currentLineItems = this.state.menuSection.items.slice();
            currentLineItems[index].data.price = event.target.value;

            let section = { items: currentLineItems };
            if (note != null) {
              section.note = note;
            }

            this.setState({ menuSection: section });

          }} />
        </InputGroup>
        <Button color="danger" style={{ width: 50 }} onClick={() => {

          // remove this entry from the list
          let note = this.state.menuSection.note;
          var currentLineItems = this.state.menuSection.items.slice();
          currentLineItems.splice(index, 1);

          let section = { items: currentLineItems };
          if (note != null) {
            section.note = note;
          }

          this.setState({ menuSection: section });

        }}>-</Button>
      </div>
    );
  }

  _renderEmptyLineItem = () => {
    return (
      <Form innerRef={(form) => (this.newLineItemForm = form)}>
        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>

          <Input style={{ flex: '3', marginRight: 10, minWidth: 200 }} placeholder="item" onChange={(event) => {

            let newItemCurrentState = this.state.newItem;
            newItemCurrentState.description = event.target.value.length > 0 ? event.target.value : null;
            this.setState({ newItem: newItemCurrentState });

          }} />
          <Input style={{ flex: '8', marginRight: 10 }} placeholder="details" onChange={(event) => {

            let newItemCurrentState = this.state.newItem;
            newItemCurrentState.details = event.target.value.length > 0 ? event.target.value : null;
            this.setState({ newItem: newItemCurrentState });

          }} />
          <InputGroup style={{ flex: '2', marginRight: 10, minWidth: 95 }}>
            <InputGroupText>$</InputGroupText>
            <Input placeholder="-" onChange={(event) => {

              let newItemCurrentState = this.state.newItem;
              newItemCurrentState.price = event.target.value.length > 0 ? event.target.value : null;
              this.setState({ newItem: newItemCurrentState });

            }} />
          </InputGroup>

          <Button color="success" style={{ width: 50 }} onClick={() => {

            // ensure we don't nullify values, empty strings are better
            let description = this.state.newItem.description ? this.state.newItem.description : "";
            let details = this.state.newItem.details ? this.state.newItem.details : "";
            let price = this.state.newItem.price ? this.state.newItem.price : "";

            var currentLineItems = this.state.menuSection.items.slice();
            currentLineItems.push({
              key: itemCounter++,
              data: {
                description: description,
                details: details,
                price: price
              }
            })

            this.setState({ menuSection: { items: currentLineItems, note: this.state.menuSection.note }, newItem: {} })
            this.newLineItemForm.reset();
          }} disabled={this.state.newItem.description == null || this.state.newItem.price == null}>+</Button>
        </div>
      </Form>
    );
  }

  _renderNote = (key, note) => {
    if (!this.props.disableNote) {
      return (
        <div style={{ display: 'flex' }}>
          <Input type="textarea" style={{ flex: '1', margin: 30 }} value={this.state.menuSection.note} placeholder="notes" onChange={(event) => {

            let note = event.target.value.length > 0 ? event.target.value : null;
            this.setState({ menuSection: { items: this.state.menuSection.items, note: note } });

          }} />
        </div>
      )
    } else {
      return (null);
    }

  }

  render() {
    const { title } = this.props;
    var index = 0;
    return (
      <div className="MenuSection">
        <h1>{title}</h1>
        {
          this.state.menuSection.items.map(lineItem => {
            return this._renderLineItem(index++, lineItem.key, lineItem.data.description, lineItem.data.details, lineItem.data.price);
          })
        }
        {this._renderEmptyLineItem()}

        {this._renderNote(++index, this.state.menuSection.note)}
      </div>
    )
  }
}
