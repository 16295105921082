import React from 'react';
import { connect } from 'react-redux';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import ReactGA from 'react-ga';
import moment from 'moment';


import ModuleHeader from '../../../components/ModuleHeader';
import MenuView from './MenuView';
import LoadingScreen from '../../../screens/LoadingScreen';
import { fetchWeeklyMenu, saveWeeklyMenu } from '../actions';
import classnames from 'classnames';
import WeekRangePicker from './WeekRangePicker';

const DATE_FORMAT = "M/D/YYYY";

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class WeeklyMenuScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      menu: {},
      activeTab: 'mon',
      startDate: moment(moment().subtract(moment().isoWeekday() - 1, 'days')).format(DATE_FORMAT),
      endDate: moment(moment().add(7 - moment().isoWeekday(), 'days')).format(DATE_FORMAT)
    };
    
    

    this.draftMenu = {};
  }

  componentDidMount() {
    ReactGA.pageview('/modules/food/menu/weekly/');

    this.setState({ processing: true });
    this.refreshMenu();
  }

  componentDidUpdate(prevState, prevProps) {
    if (prevState.dateSelector && !this.state.dateSelector) {
      this.stopListenForClick();
    } else if (!prevState.dateSelector && this.state.dateSelector) {
      this.listenForClick();
    }
  }

  refreshMenu = () => {
    fetchWeeklyMenu(this.props.configuration.data.id, moment(this.state.startDate, DATE_FORMAT)).then( menu => {
      this.setState({ menu: menu, processing: false });
    }).catch( () => {
      this.setState({ processing: false });
    })
  }

  render() {
    return (
      <div>
      <ModuleHeader title='Food Services' subTitle='Weekly Menu' datePicker action actionTitle="Save & Publish" onAction={this.onSaveAndPublish}>
        <div ref={ref => this.weekRangePickerRef = ref}>
        <Button onClick={this.openWeekPicker} style={{width: 220, height: 40, marginRight: 10}}>{`${this.state.startDate} to ${this.state.endDate}`}</Button>
        {
          this.state.dateSelector
          ?
          <div style={{position: 'absolute', zIndex: 2, marginTop: 10}}>
            <WeekRangePicker onDatesChange={this.onDatesChange} initialStartDate={moment()}/>
          </div>
          :
          null
        }
        </div>
      </ModuleHeader>
        {this.renderMenu()}
      </div>
    );
  }

  openWeekPicker = () => {
    this.setState({ dateSelector: !this.state.dateSelector });
  }

  listenForClick = () => {
    document.addEventListener('mousedown', this.handleClick);
  }

  stopListenForClick = () => {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = event => {
    if (this.weekRangePickerRef && !this.weekRangePickerRef.contains(event.target)) {
      if (this.state.dateSelector) {
        this.setState({ dateSelector: false });
      }
    }
  }

  renderMenu = () => {
    if (this.state.processing) {
      return <LoadingScreen />
    }
    
    return (
      <div>
        <div style={{marginBottom: 20}}>
          
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'mon' })} onClick={() => { this._toggle('mon'); }}>Monday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'tues' })} onClick={() => { this._toggle('tues'); }}>Tuesday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'wed' })} onClick={() => { this._toggle('wed'); }}>Wednesday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'thurs' })} onClick={() => { this._toggle('thurs'); }}>Thursday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'fri' })} onClick={() => { this._toggle('fri'); }}>Friday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'sat' })} onClick={() => { this._toggle('sat'); }}>Saturday</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: this.state.activeTab === 'sun' })} onClick={() => { this._toggle('sun'); }}>Sunday</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="mon">
            <MenuView id="mon" menu={this.state.menu.mon} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="tues">
            <MenuView id="tues" menu={this.state.menu.tues} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="wed">
            <MenuView id="wed" menu={this.state.menu.wed} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="thurs">
            <MenuView id="thurs" menu={this.state.menu.thurs} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="fri">
            <MenuView id="fri" menu={this.state.menu.fri} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="sat">
            <MenuView id="sat" menu={this.state.menu.sat} onSave={this._onMenuSave} disableNote/>
          </TabPane>
          <TabPane tabId="sun">
            <MenuView id="sun" menu={this.state.menu.sun} onSave={this._onMenuSave} disableNote/>
          </TabPane>
        </TabContent>
      </div>
    )
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({ startDate: moment(startDate).format(DATE_FORMAT), endDate: moment(endDate).format(DATE_FORMAT), dateSelector: false }, () => {
      this.setState({ processing: true });
      this.refreshMenu();
    });
  }

  _toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onSaveAndPublish = () => {
    saveWeeklyMenu(this.props.configuration.data.id, this.draftMenu, moment(this.state.startDate, DATE_FORMAT)).then( () => {
        NotificationManager.success('Updated Weekly Menu')

        ReactGA.event({
          category: 'Food Services',
          action: 'Publish Weekly Menu'
        });

      }, (error) => {
        NotificationManager.error(error)
      });
  }

  _onMenuSave = (menu, menuItems) => {
    this.draftMenu[menu] = menuItems;
  }
}

export default connect(mapStateToProps)(WeeklyMenuScreen)