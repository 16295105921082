import React, { useState, useEffect } from 'react';

import { Nav, NavLink } from 'reactstrap';

import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';

import './../App.css';
import { ModuleMapUserInfo } from '../screens/user/config';

const LoggedInUserInfoSideBarView = () => {
  const [modules, setModules] = useState([]);

  useEffect(() => {
    let modules = [];
    for (var module in ModuleMapUserInfo) {
      modules.push(ModuleMapUserInfo[module].sidebar);
    }
    setModules(modules);
  }, []);

  return (
    <Nav navbar className='SideBar'>
      {modules.map(module => {
        return (
          <div key={module.title} className='SideBarModule'>
            <div className='SideBarHeader'>{module.title}</div>
            <div className='SideBarModuleLinks'>
              {module.items.map(item => {
                return (
                  <LineItem key={item.path} show={true}>
                    <LinkContainer to={item.path} activeStyle={{ color: '#0056b3' }} style={{ padding: 0 }}>
                      <NavLink>{item.title}</NavLink>
                    </LinkContainer>
                  </LineItem>
                );
              })}
            </div>
          </div>
        );
      })}
    </Nav>
  );
};

function LineItem(props) {
  if (props.show === true) {
    return props.children;
  } else {
    return null;
  }
}

export default withRouter(LoggedInUserInfoSideBarView);
