import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import ModuleHeader from '../../../components/ModuleHeader';
import BackButton from '../../../components/BackButton';
import ModalDialog from '../../../components/ModalDialog';
import LoadingScreen from '../../../screens/LoadingScreen';
import { saveTickerTapeItem, updateTickerTapeItem, deleteTickerTapeItem } from '../action';
import ReactGA from 'react-ga';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class TickerTapeItemScreen extends React.Component {
  constructor(props) {
    super(props);

    const existingItem = this.props.location.state.item || {};
    const bCreateMode = existingItem.id == null;

    this.state = {
      loading: false,
      createMode: bCreateMode,
      itemId: existingItem.id,
      description: existingItem.title || '',
      published_sign: bCreateMode ? true : existingItem.published_sign,
      errors: [],
      confirmDelete: false,
    }
  }

  componentDidMount() {

    const mode = this.state.editMode ? 'edit' : 'create';
    ReactGA.pageview(`/modules/sign/tickertape/${mode}`);

    if (!this.state.createMode && this.props.configuration.data.id !== this.props.location.state.site) {
      this.goBack();
    }
  }

  render() {
    const action = this.state.createMode ? 'Create' : 'Edit';
    const disableInput = this.state.loading;
    const counterColor = this.state.description.length < 190 ? '#000000' : '#dc3545';

    return (
      <div>
        <ModuleHeader title='Digital Sign' subTitle={`TickerTape / ${action} Item`} />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <BackButton title='Back to Tickertape' onClick={this.goBack} />
          <div style={{flex: 1}}/>
        </div>
        <div className="" style={{padding: 20, borderTop: "1px solid #D3D3D3"}}>
          <Form onSubmit={this.onSave}>
            <FormGroup>
              <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Enter tickertape text here ..."
                maxLength={200}
                value={ this.state.description }
                onChange={ (event) => this.setState({description: event.target.value})}
                disabled={disableInput}
              />
              <div style={{display: 'flex', flexDirection: 'row', paddingTop: 3}}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.published_sign}
                      onChange={ event => this.setState({published_sign: event.target.checked})}
                      disabled={disableInput}
                    />Publish to Digital Sign
                  </Label>
                </FormGroup>
                <div style={{
                flex: 1, 
                textAlign: 'right',
                fontSize: '0.75em',
                color: counterColor
              }}>
                {this.state.description.length} / 200
              </div>
              </div>
              
            </FormGroup>

            {
              this.state.errors.length > 0
              ?
              <Alert color="danger" style={{marginTop: 10}}>
                {
                  this.state.errors.map( error => {
                    return (
                      <h1 className="ErrorItem" key={error}>{error}</h1>
                    );
                  })
                }
                </Alert>
              :
              null
            }

            <div style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{flex: 1, display: 'flex', alignItems: 'center', marginTop: 10}}>
                <Button
                  color="success"
                  type="submit"
                  disabled={disableInput}
                  style={{marginRight: 5}}>{!this.state.createMode ? 'Update Item' : 'Create Item'}</Button>
                { this.state.loading ? <LoadingScreen /> : null }
              </div>
              {
                !this.state.createMode
                ?
                <div style={{alignItems: 'right', display: 'flex', marginTop: 10}}>
                  <Button
                    onClick={ () => { this.setState({confirmDelete: true}) }}
                    color="danger"
                    disabled={disableInput}>Delete Item</Button>
                  <ModalDialog
                    active={this.state.confirmDelete}
                    title='Delete Confirmation'
                    body='Are you sure you want to delete this item?'
                    confirmColor='danger'
                    cancelColor='secondary'
                    onConfirm={this.onDelete}
                    onCancel={this.onCancelDelete}
                  />
                </div>
                :
                null
              }
            </div>
          </Form>
        </div>
      </div>
    )
  }

  goBack = () => {
    this.props.history.push('/modules/sign/tickertape');
  }

  onSave = event => {

    const payload = {
      description: this.state.description,
      published_sign: this.state.published_sign
    }

    this.setState({ loading: true });
    if (this.state.createMode) {
      saveTickerTapeItem(this.props.configuration.data.id, payload).then( () => {
        NotificationManager.success('Tickertape item created');
        this.props.history.push('/modules/sign/tickertape');

        ReactGA.event({
          category: 'Digital Sign',
          action: 'Create Tickertape Item',
        });

      });
    } else {
      updateTickerTapeItem(this.props.configuration.data.id, this.state.itemId, payload).then( () => {
        NotificationManager.success('Tickertape item updated');
        this.setState({ loading: false });

        ReactGA.event({
          category: 'Digital Sign',
          action: 'Modify Tickertape Item',
        });

      });
    }

    event.preventDefault();
  }

  onCancelDelete = () => {
    this.setState({ confirmDelete: false });
  }

  onDelete = () => {
    deleteTickerTapeItem(this.props.configuration.data.id, this.state.itemId).then( () => {
      NotificationManager.success("Tickertape item deleted");
      this.props.history.push('/modules/sign/tickertape');

      ReactGA.event({
        category: 'Digital Sign',
        action: 'Delete Tickertape Item',
      });

    }).catch( error => {
      NotificationManager.error(error);
      this.setState({ confirmDelete: false });
    })
  }
}

export default withRouter(connect(mapStateToProps)(TickerTapeItemScreen))