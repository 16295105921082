import React from 'react';
import { useSelector } from 'react-redux';
import ModuleHeader from '../../../../components/ModuleHeader';

const UserProfileScreen = () => {
  const authentication = useSelector(state => state.authentication);
  return (
    <div>
      <ModuleHeader title='Account' subTitle='User Profile' />
      <div className='SettingsContainer'>
        <LineItem item='Email' value={authentication.user.email} />
        <LineItem item='Name' value={authentication.user.name} />
        <LineItem item='Sites' value={authentication.user.sites.toString()} />
      </div>
    </div>
  );
};

const LineItem = ({ item, value }) => {
  return (
    <div className="SettingsItem" style={{ display: 'flex', flexDirection: 'row' }}>
      <div>{item}</div>
      <div style={{ flex: 1, textAlign: 'right' }}>{value}</div>
    </div>
  );
};

export default UserProfileScreen;
