import React, { Component } from 'react';
import { Badge, Container, Col, Row, Collapse, ListGroupItem } from 'reactstrap';

export default class ClosedOrderCollapsableItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };
  }

  _toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  _renderBadge = () => {
    if (this.props.order.fulfilled) {
      return (
        <Badge>fulfilled</Badge>
      );
    } else {
      return (
        <Badge>un-fulfilled</Badge>
      );
    }
  }

  render() {
    const { email, room, closedComments, closedTimestamp, openedTimestamp, notes, items, takeOut, pickupTime } = this.props.order;

    let bgStyle = {};
    if (this.state.collapse) {
      bgStyle = {backgroundColor: '#e9ecef'};
    }
    return (
      <ListGroupItem className="CollapsingLineItem" style={bgStyle}>
      <Container className="ActiveCollapseItem">
        <Row onClick={this._toggle} style={{display: 'flex'}}>
          <Col>
          <h1>{email}</h1>
          <span>Room: {room}</span>
          </Col>
          <Col xs='auto'>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              {this._renderBadge()}
            </div>
          </Col>
        </Row>
      </Container>


        <Collapse isOpen={this.state.collapse}>
        <div style={{marginLeft: 15, marginRight: 10, marginBottom: 0, paddingTop: 5}}>
          <hr/>
          Order Received: {openedTimestamp}<br/>
          Takeout: { takeOut ? 'Yes' : 'No' }<br/>
          To Be Ready For: {pickupTime}<br/>
          Order Closed: {closedTimestamp}<br/>
          Comments: {closedComments}<br/><br/>
          Order:
          <hr style={{margin:0}}/>
          <div className="TransactionGrid">
            <div>Item</div>
            <div>Price</div>
          {
            items.map(item => {
              return (
                [
                  <div key="item" className="TransactionGridLeft">{item.description}</div>,
                  <div key="price" className="TransactionGridRight">{item.price}</div>
                ]
              );
            })
          }
          </div>
          <div style={{marginLeft: 20}}>
            Order Notes:
            <div style={{backgroundColor: '#F9F9F9', border: '1', padding: 20}}>
            {notes}
            </div>
          </div>
          <hr/>


        </div>
        </Collapse>
      </ListGroupItem>
    )
  }
}
