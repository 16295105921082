const initialState = {
  active: {
    currentState: 'READY',
    data: [],
    error: null
  },
  closed: {
    currentState: 'READY',
    data: [],
    error: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "ORDERS_ACTIVE_FETCHING": {
      return {
        ...state,
        active: {
          currentState: 'FETCHING',
          data: [],
          error: null
        }
      }
    }
    case "ORDERS_ACTIVE_COMPLETE": {
      return {
        ...state,
        active: {
          currentState: 'READY',
          data: _processActiveOrders(action.payload),
          error: null,
        }
      }
    }
    case "ORDERS_ACTIVE_ERROR": {
      return {
        ...state,
        active: {
          currentState: 'ERROR',
          data: [],
          error: action.error,
        }
      }
    }
    case "ORDERS_CLOSED_FETCHING": {
      return {
        ...state,
        closed: {
          currentState: 'FETCHING',
          data: [],
          error: null,
        }
      }
    }
    case "ORDERS_CLOSED_COMPLETE": {
      return {
        ...state,
        closed: {
          currentState: 'READY',
          data: _processClosedOrders(action.payload),
          error: null,
        }
      }
    }
    case "ORDERS_CLOSED_ERROR": {
      return {
        ...state,
        closed: {
          currentState: 'ERROR',
          data: [],
          error: action.error,
        }
      }
    }
    default: {
      return state;
    }
  }
}

function _processActiveOrders(payload) {
  let orders = _processOrders(payload);
  return orders.sort(_compareOpened);
}

function _processClosedOrders(payload) {
  let orders = _processOrders(payload);
  return orders.sort(_compareClosed);
}

function _processOrders(payload) {
  var orders = [];

  for (var key in payload) {

    var order = payload[key];

    var orderItems = [];
    for (var itemKey in order.items) {

      var item = order.items[itemKey];

      orderItems.push({
        id: itemKey,
        description: item.description,
        price: item.price
      })
    }

    orders.push({
      id: key,
      email: order.email,
      room: order.room,
      takeOut: order.takeOut,
      pickupTime: order.time,
      openedTimestampMs: order.openedTimestamp,
      closedTimestampMs: order.closedTimestamp,
      openedTimestamp: _prettifyTimestamp(order.openedTimestamp),
      closedTimestamp: _prettifyTimestamp(order.closedTimestamp),
      status: order.status,
      notes: order.notes,
      fulfilled: order.fulfilled,
      items: orderItems,
      closedComments: order.closedComments
    });
  }

  return orders;
}

function _prettifyTimestamp(epochTime) {
  if (epochTime) {
    var tsOptions = {
      month: 'numeric',
      year: 'numeric',
      day: 'numeric',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    };
    return new Date(epochTime).toLocaleDateString('en-CA', tsOptions);
  }
}

function _compareOpened(a, b) {
  if (a.openedTimestampMs < b.openedTimestampMs) {
    return 1;
  } else if (a.openedTimestampMs > b.openedTimestampMs) {
    return -1;
  }
  return 0;
}

function _compareClosed(a, b) {
  if (a.closedTimestampMs < b.closedTimestampMs) {
    return 1;
  } else if (a.closedTimestampMs > b.closedTimestampMs) {
    return -1;
  }
  return 0;
}
