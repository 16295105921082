import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { HuePicker } from "react-color";
import ReactGA from "react-ga";

import ModuleHeader from "../../../components/ModuleHeader";
import BackButton from "../../../components/BackButton";
import AppComponent from "./component/AppModule";
import { updateModuleMetaData, updateModuleDescription } from "../actions";
import { NotificationManager } from "react-notifications";
import InformationModuleSettings from "./InformationModuleSettings";
import FoodModuleSettings from "./FoodModuleSettings";
import SublanderModuleSettings from "./SublanderModuleSettings";
import { WebviewModuleSettings } from "./WebviewModuleSettings";
import { deleteModuleById, fetchModuleById } from "../actions/settings";
import LoadingScreen from "../../../screens/LoadingScreen";
import { FeedbackModuleSettings } from "./FeedbackModuleSettings";
import FirebaseFileUploader from "../../../components/FirebaseUploader";

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

const MUTABLE_MODULE_TYPES = ["link", "webview", "feedback", "sublander"];

const MODULE_SETTINGS = {
  information: {
    type: InformationModuleSettings,
  },
  food: {
    type: FoodModuleSettings,
  },
  sublander: {
    type: SublanderModuleSettings,
  },
  webview: {
    type: WebviewModuleSettings,
  },
  link: {
    type: WebviewModuleSettings,
  },
  feedback: {
    type: FeedbackModuleSettings,
  },
};

class AppModuleSettingsScreenPriv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      id: null,
      parentModuleId: null,
      type: null,
      meta: null,
      data: null,
      editMetaData: this.props.location.state.firstLaunch,
    };
  }

  componentDidMount() {
    ReactGA.pageview("/modules/app/modules/settings/");
    if (this.props.location.state.site !== this.props.configuration.data.id) {
      this.goBack();
    }

    this.fetchModuleInfo(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.setState(
        {
          ready: false,
          id: null,
          parentModuleId: null,
          type: null,
          meta: null,
          data: null,
        },
        () => {
          this.fetchModuleInfo(this.props.match.params.id);
        }
      );
    }
  }

  editModuleMeta = () => {
    this.setState({ editMetaData: true });
  };

  render() {
    return (
      <div>
        <ModuleHeader title="App Management" subTitle={`Modules / Edit`}>
          {MUTABLE_MODULE_TYPES.includes(this.state.type) ? (
            <Button
              color="danger"
              style={{ height: 40 }}
              onClick={this.onDelete}
            >
              Delete Module
            </Button>
          ) : null}
        </ModuleHeader>
        <DeleteConfirmationModal
          isOpen={this.state.confirmDeleteModal}
          onToggle={() => this.setState({ confirmDeleteModal: false })}
          onConfirm={this.onDeleteConfirm}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BackButton
            title={
              this.state.parentModuleId
                ? "Back to Module Settings"
                : "Back to App Management"
            }
            onClick={this.goBack}
          />
          <div style={{ flex: 1 }} />
        </div>

        {this.state.ready ? (
          <div className="Body">
            <div className="Hoverable" onClick={this.editModuleMeta}>
              <AppComponent
                readOnly
                color={this.state.meta?.color}
                icon={this.state.meta?.icon}
                name={this.state.meta?.name}
                description={this.state.meta?.description}
                type={this.state.type}
                minVersion={this.state.meta?.minVersion}
                maxVersion={this.state.meta?.maxVersion}
              />
            </div>
            <div style={{ height: 20 }} />
            {MODULE_SETTINGS[this.state.type] ? (
              <div className="AppModuleSettings">
                <div className="SettingsContainer">
                  {React.createElement(MODULE_SETTINGS[this.state.type].type, {
                    moduleId: this.state.id,
                    site: this.props.configuration.data.id,
                    data: this.state.data,
                  })}
                </div>
                <div style={{ height: 50 }} />
              </div>
            ) : (
              <div className="AppModuleSettings">
                <div className="SettingsContainer">
                  <span
                    className="InfoText"
                    style={{
                      padding: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    There are no specific settings for this module type
                  </span>
                </div>
              </div>
            )}
            <MetaDataModal
              isOpen={this.state.editMetaData}
              onToggle={() => this.setState({ editMetaData: false })}
              metadata={this.state.meta}
              onUpdateMetaData={this.updateMetaData}
              mutable={MUTABLE_MODULE_TYPES.includes(this.state.type)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  fetchModuleInfo = async (moduleId) => {
    try {
      const module = await fetchModuleById(
        this.props.configuration.data.id,
        moduleId
      );
      this.setState({
        id: module.id,
        parentModuleId: module.parentModuleId,
        type: module.type,
        meta: module.meta,
        data: module.data,
        ready: true,
        confirmDeleteModal: false,
      });
    } catch (error) {
      this.setState({ ready: true });
    }
  };

  updateMetaData = async ({
    name,
    description,
    icon,
    color,
    trackingId,
    minVersion,
    maxVersion,
  }) => {
    try {
      await updateModuleMetaData(
        this.props.configuration.data.id,
        this.state.id,
        {
          landerColor: color ?? "",
          landerIcon: icon ?? "",
          minVersion: minVersion ?? "",
          maxVersion: maxVersion ?? "",
          title: name ?? "",
          trackingId: trackingId ?? "",
        }
      );
      await updateModuleDescription(
        this.props.configuration.data.id,
        this.state.id,
        description
      );

      this.setState({ editMetaData: false }, () => {
        NotificationManager.success("Module updated");

        ReactGA.event({
          category: "App Management",
          action: "Modify Module Settings",
        });

        // refetch
        this.fetchModuleInfo(this.state.id);
      });
    } catch {
      NotificationManager.error("Module failed to update");
    }
  };

  onDelete = () => {
    this.setState({ confirmDeleteModal: true });
  };

  onDeleteConfirm = async () => {
    try {
      await deleteModuleById(this.props.configuration.data.id, this.state.id);
      this.goBack();
      NotificationManager.success("Module was deleted.");
    } catch (error) {
      NotificationManager.error("Unable to delete Module.");
    }
  };

  onPickerChange = (color) => {
    this.setState({ color: color.hex });
  };

  onPickerClick = () => {
    this.setState({ pickerOpen: !this.state.pickerOpen });
  };

  goBack = () => {
    // if we are looking at a module settings that has a parent, let's go back to that parent
    if (this.state.parentModuleId != null) {
      this.props.history.push(
        `/modules/app/modules/settings/${this.state.parentModuleId}`,
        {
          site: this.props.location.state.site,
          firstLaunch: false,
        }
      );
    } else {
      this.props.history.push("/modules/app/modules");
    }
  };
}

const MetaDataModal = ({
  isOpen,
  onToggle,
  metadata,
  onUpdateMetaData,
  mutable,
}) => {
  const [name, setName] = useState(metadata.name ?? undefined);
  const [description, setDescription] = useState(
    metadata?.description ?? undefined
  );
  const [icon, setIcon] = useState(metadata?.icon ?? undefined);
  const [color, setColor] = useState(metadata?.color ?? undefined);
  const [minVersion, setMinVersion] = useState(
    metadata?.minVersion ?? undefined
  );
  const [maxVersion, setMaxVersion] = useState(
    metadata?.maxVersion ?? undefined
  );
  const [trackingId, setTrackingId] = useState(
    metadata?.trackingId ?? undefined
  );
  const [processing, setProcessing] = useState(false);

  const onUploadSuccess = (downloadUrl) => {
    setIcon(downloadUrl);
    setProcessing(false);
  };

  const onSubmit = (event) => {
    if (onUpdateMetaData) {
      onUpdateMetaData({
        name,
        description,
        icon,
        color,
        trackingId,
        minVersion,
        maxVersion,
      });
    }
    event.preventDefault();
  };

  const onReset = () => {
    setName(metadata.name ?? undefined);
    setDescription(metadata.description ?? undefined);
    setIcon(metadata.icon ?? undefined);
    setColor(metadata.color ?? undefined);
    setTrackingId(metadata.trackingId ?? undefined);
    setMinVersion(metadata.minVersion ?? undefined);
    setMaxVersion(metadata.maxVersion ?? undefined);
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader>Modify Module metadata</ModalHeader>
        <ModalBody>
          <div>
            <AppComponent
              readOnly
              color={color}
              icon={icon}
              name={name}
              minVersion={minVersion}
              maxVersion={maxVersion}
            />

            <FormGroup>
              <Label>Name</Label>
              <Input
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <Input
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Icon</Label>
              <Label
                className="SelectableButton"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: 50,
                }}
              >
                {processing ? <LoadingScreen /> : `Select File to Upload`}
                <FirebaseFileUploader
                  hidden={true}
                  accept="image/*"
                  storageDir="lander_icons"
                  onUploadStart={() => setProcessing(true)}
                  onUploadSuccess={onUploadSuccess}
                />
              </Label>
            </FormGroup>
            <FormGroup>
              <Label>Background Color</Label>
              <HuePicker
                color={color}
                onChange={(pickerColor) => setColor(pickerColor.hex)}
              />
            </FormGroup>
            {mutable ? (
              <FormGroup>
                <Label>Analytics Tracking ID</Label>
                <Input
                  defaultValue={trackingId}
                  onChange={(event) => setTrackingId(event.target.value)}
                />
              </FormGroup>
            ) : null}

            <FormGroup>
              <Label>Minimum Version</Label>
              <Input
                defaultValue={minVersion}
                onChange={(event) => setMinVersion(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Maximum Version</Label>
              <Input
                value={maxVersion}
                onChange={(event) => setMaxVersion(event.target.value)}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <Button
              color="secondary"
              onClick={onToggle}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button color="secondary" onClick={onReset}>
              Reset
            </Button>
            <div style={{ flex: 1 }} />
            <Button color="success" type="submit">
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const DeleteConfirmationModal = ({ isOpen, onToggle, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>Confirm Deletion</ModalHeader>
      <ModalBody>Are you sure you want to delete this module?</ModalBody>
      <ModalFooter>
        <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
          <Button onClick={onToggle}>Cancel</Button>
          <div style={{ flex: 1 }} />
          <Button color="danger" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const AppModuleSettingsScreen = withRouter(
  connect(mapStateToProps)(AppModuleSettingsScreenPriv)
);

export { AppModuleSettingsScreen };
