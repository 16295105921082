import React from 'react';
import TeamScreen from './view/TeamScreen';
import TeamAgentScreen from './view/TeamAgentScreen';

const config = {
  sidebar: {
    title: 'Organization',
    items: [
      {
        title: 'Team',
        path: '/modules/organization/team'
      },
    ]
  },
  routes: [
    {
      path: '/modules/organization/team/agent',
      main: () => <TeamAgentScreen />,
    },
    {
      path: '/modules/organization/team',
      main: () => <TeamScreen />,
    },
  ]
  
}

export default config