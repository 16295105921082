const initialState = {
  initializing: true,
  loggedIn: false,
  user: {
    email: null,
    name: null,
    defaultSite: null,
    sites: [],
    permissions: [],
    type: null
  },
  processing: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_USER_LOGIN_PROCESSING": {
      console.log("Auth: Login Processing");
      return {
        ...state,
        initializing: false,
        processing: true,
        error: false,
      }
    }
    case "AUTH_USER_LOGIN_SUCCESS": {
      console.log("Auth: Login Success");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: false,
        loggedIn: true,
        user: {
          email: action.user.email,
          name: action.user.name,
          defaultSite: action.user.defaultSite || processSites(action.user.sites)[0],
          sites: processSites(action.user.sites),
          permissions: processPermissions(action.user.permissions),
          type: action.user.type
        }
      }
    }
    case "AUTH_USER_LOGIN_ERROR": {
      console.log("Auth: Login Error");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: true,
        loggedIn: false,
        user: null
      }
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      console.log("Auth: Silent Logout");
      return {
        ...state,
        initializing: false,
        processing: false,
        error: false,
        loggedIn: false,
        user: null
      }
    }
  default: {
      return state;
    }
  }
}

function processPermissions(payload) {
  let permissions = [];
  for(let permission in payload) {
    if (payload[permission]) {
      permissions.push(permission);
    }
  }
  return permissions;
}

function processSites(payload) {
  let sites = [];
  for(let site in payload) {
    sites.push(site);
  }
  return sites;
}
