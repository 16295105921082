import store from '../store.js';
import { getDatabase, ref, get, update } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import ReactGA from 'react-ga';

export function authStateMonitor() {
  getAuth().onAuthStateChanged(function (user) {
    if (user) {
      const userRef = ref(getDatabase(), `/Users/${user.uid}`);
      get(userRef).then((basicUserData) => {
        const superUserRef = ref(getDatabase(), `/superusers/${user.uid}`);
        get(superUserRef).then((superUserData) => {
          if (superUserData.val().sites == null) {
            forceLogoutAndNotify();
            return;
          }

          // process sites
          let authorizedSites = {};
          for (var siteKey in superUserData.val().sites) {
            if (superUserData.val().sites[siteKey]) {
              authorizedSites[siteKey] = true;
            }
          }

          if (Object.keys(authorizedSites).length === 0) {
            forceLogoutAndNotify();
            return;
          }

          // analytics
          ReactGA.set({ userId: user.uid });

          // notify we have silently logged in
          store.dispatch({
            type: 'AUTH_USER_LOGIN_SUCCESS',
            user: {
              email: user.email,
              name: basicUserData.val().firstName,
              defaultSite: basicUserData.val().defaultSite || authorizedSites[0],
              sites: authorizedSites,
              permissions: superUserData.val().permissions,
              type: superUserData.val().type
            }
          });

        });
      });
    } else {
      // notify we have silently logged out
      store.dispatch({
        type: 'AUTH_USER_SILENT_LOGOUT',
        payload: {}
      });
    }
  });
}

function forceLogoutAndNotify() {
  getAuth()
    .signOut()
    .then(function () {
      // dispatch error
      store.dispatch({
        type: 'AUTH_USER_LOGIN_ERROR',
        payload: {}
      });
    });
}

export function setUserDefaultSite(site) {
  try {
    const userRef = ref(getDatabase(), `/Users/${getAuth().currentUser.uid}`);
    update(userRef, { defaultSite: site });
  } catch {
    console.log("Unable to set default site");
  }
}

export function userLogout() {
  signOut(getAuth());
}

export function userLogin(email, password) {
  store.dispatch({
    type: 'AUTH_USER_LOGIN_PROCESSING',
    payload: {}
  });

  // attempt to sign in
  signInWithEmailAndPassword(getAuth(), email.trim(), password)
    .then(function () {
      // allow authStateMonitor to log us in now.
    })
    .catch(function (error) {
      console.log('AuthAction: big error, bigly');

      // notify error
      store.dispatch({
        type: 'AUTH_USER_LOGIN_ERROR',
        payload: {}
      });
    });
}
