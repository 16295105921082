/**

 * FirebaseFileUploader for React

 * @flow

 */
import { getStorage, uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";
import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';

function extractExtension(filename) {
  let ext = /(?:\.([^.]+))?$/.exec(filename);
  if (ext != null && ext[0] != null) {
    return ext[0];
  } else {
    return "";
  }
}

export default class FirebaseFileUploader extends Component {
  uploadTasks = [];

  // Cancel all running uploads before unmount

  componentWillUnmount() {
    this.cancelRunningUploads();
  }

  cancelRunningUploads() {
    while (this.uploadTasks.length > 0) {
      const task = this.uploadTasks.pop();

      if (task.snapshot.state === "running") {
        task.cancel();
      }
    }
  }

  // Remove a specific task from the uploadTasks

  removeTask(task) {
    for (let i = 0; i < this.uploadTasks.length; i++) {
      if (this.uploadTasks[i] === task) {
        this.uploadTasks.splice(i, 1);

        return;
      }
    }
  }

  startUpload(file) {
    const {
      onUploadStart,
      storageDir,
      metadata
    } = this.props;

    let filenameToUse;

    filenameToUse = uuidv4();

    // Ensure there is an extension in the filename

    if (!extractExtension(filenameToUse)) {
      filenameToUse += extractExtension(file.name);
    }

    Promise.resolve()
      .then(() => {
        return file;
      })

      .then((file) => {
        const storageRef = ref(getStorage(), `${storageDir}/${filenameToUse}`);
        const task = uploadBytesResumable(storageRef, file, metadata);

        if (onUploadStart) {
          onUploadStart(file, task);
        }

        task.on(
          "state_changed",

          (snapshot) =>
            this.props.onProgress &&
            this.props.onProgress(
              Math.round(
                (100 * snapshot.bytesTransferred) / snapshot.totalBytes
              ),

              task
            ),

          (error) =>
            this.props.onUploadError && this.props.onUploadError(error, task),

          async () => {
            this.removeTask(task);

            // get download url
            const downloadUrl = await getDownloadURL(storageRef);
            return (
              this.props.onUploadSuccess &&
              this.props.onUploadSuccess(downloadUrl)
            );
          }
        );

        this.uploadTasks.push(task);
      });
  }

  handleFileSelection = (event) => {
    const {
      target: { files },
    } = event;

    for (let i = 0; i < files.length; i++) {
      this.startUpload(files[i]);
    }
  };

  render() {
    const {
      storageRef,
      onUploadStart,
      onProgress,
      onUploadSuccess,
      onUploadError,
      randomizeFilename,
      metadata,
      filename,
      maxWidth,
      maxHeight,
      hidden,
      as: Input = "input",
      ...props
    } = this.props;

    const inputStyle = hidden
      ? Object.assign({}, props.style, {
        width: "0.1px",

        height: "0.1px",

        opacity: 0,

        overflow: "hidden",

        position: "absolute",

        zIndex: -1,
      })
      : props.style;

    return (
      <Input
        type="file"
        onChange={this.handleFileSelection}
        {...props}
        style={inputStyle}
      />
    );
  }
}
