import { getDatabase, ref, get, push, update, set, remove } from "firebase/database";

export function fetchNewsItems(site) {
  return new Promise(async (resolve, reject) => {

    try {
      const newsItemsRef = ref(getDatabase(), `/sites/${site}/information/screen/`);
      const newsItemsData = await get(newsItemsRef);

      const payload = newsItemsData.val();
      var screenItems = [];
      if (payload) {
        if (payload.items) {
          // retrieve position data
          let posObj = payload.position || {};

          for (var key in payload.items) {
            var item = payload.items[key];

            let pData = posObj[key] || {};
            let position = pData.position || 0;
            screenItems.push({
              id: key,
              title: item.title,
              imageUrl: item.imageUrl,
              description: item.description,
              published_ipad: item.published_ipad,
              published_sign: item.published_sign,
              ds_image_only: item.ds_image_only,
              contain_image: item.contain_image,
              position: position,
              signOptions: item.signOptions,
            });
          }
        }
      }
      resolve(screenItems.sort(_sortPosition));

    } catch (error) {
      reject()
    }
  });
}

export function saveInformationItem(site, item) {
  return new Promise(async (resolve, reject) => {

    try {
      const newInfoRef = ref(getDatabase(), `sites/${site}/information/screen/items`);
      await push(newInfoRef, item);
      resolve();
    } catch {
      reject("Something went wrong, unable to add item.");
    }
  });
}

export function updateInformationItem(site, itemKey, item) {
  return new Promise(async (resolve, reject) => {
    try {
      const infoItemRef = ref(getDatabase(), `sites/${site}/information/screen/items/${itemKey}`);
      await update(infoItemRef, item);
      resolve();
    } catch {
      reject("Something went wrong, unable to update item.");
    }
  });
}

export function updateInformationItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const positionRef = ref(getDatabase(), `/sites/${site}/information/screen/position`);
      await set(positionRef, items);
      resolve();
    } catch {
      reject("Something went wrong, unable to update order.");
    }
  });
}

export function fetchTickertapeItems(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const tickerTapeDataRef = ref(getDatabase(), `/sites/${site}/information/tickertape/`);
      const data = await get(tickerTapeDataRef);

      const payload = data.val();
      var tickertapeItems = [];
      if (payload && payload.items) {
        // retrieve position data
        let posObj = payload.position || {};

        for (var tickertapKey in payload.items) {
          var tickerTapeItem = payload.items[tickertapKey];

          let pData = posObj[tickertapKey] || {};
          let position = pData.position || 0;

          tickertapeItems.push({
            id: tickertapKey,
            title: tickerTapeItem.description,
            published_ipad: tickerTapeItem.published_ipad,
            published_sign: tickerTapeItem.published_sign,
            position: position,
          });
        }
      }
      resolve(tickertapeItems.sort(_sortPosition));
    } catch {
      reject();
    }
  });
}

export function updateTickerTapeItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const positionRef = ref(getDatabase(), `/sites/${site}/information/tickertape/position`);
      await set(positionRef, items);
      resolve();
    } catch {
      reject("Something went wrong, unable to update order.");
    }
  });
}

export function fetchSignFlagConfiguration(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const digitalSignConfigRef = ref(getDatabase(), `/config/sites/${site}/ds/`);
      const data = await get(digitalSignConfigRef);
      let flags = {};
      const supportedFlags = ["feature_flag_weather", "feature_flag_menu"];
      if (data.val()) {
        supportedFlags.forEach((flag) => {
          if (data.val()[flag] !== null) {
            flags[flag] = data.val()[flag];
          }
        });
      }
      resolve(flags);
    } catch {
      reject();
    }
  });
}

export function updateSignConfiguration(site, node, value) {
  return new Promise(async (resolve, reject) => {
    try {
      const signConfigurationRef = ref(getDatabase(), `/config/sites/${site}/ds/${node}`);
      await set(signConfigurationRef, value);
      resolve();
    } catch {
      reject();
    }
  });
}

export function saveTickerTapeItem(site, item) {
  return new Promise(async (resolve, reject) => {
    try {
      const tickerTapeItemRef = ref(getDatabase(), `sites/${site}/information/tickertape/items/`);
      await push(tickerTapeItemRef, item);
      resolve();
    } catch {
      reject("Something went wrong, unable to add item.");
    }
  });
}

export function updateTickerTapeItem(site, itemKey, item) {
  return new Promise(async (resolve, reject) => {
    try {
      const tickerTapeItemRef = ref(getDatabase(), `sites/${site}/information/tickertape/items/${itemKey}`);
      await update(tickerTapeItemRef, item);
      resolve();
    } catch {
      reject("Something went wrong, unable to update item.");
    }
  });
}

export function deleteTickerTapeItem(site, itemKey) {
  return new Promise(async (resolve, reject) => {
    try {
      const tickerTapeItemRef = ref(getDatabase(), `sites/${site}/information/tickertape/items/${itemKey}`);
      await remove(tickerTapeItemRef);
      resolve();
    } catch {
      reject("Something went wrong, unable to delete item.");
    }
  });
}

export function deleteInformationItem(site, itemKey) {
  return new Promise(async (resolve, reject) => {
    try {
      const informationItemRef = ref(getDatabase(), `sites/${site}/information/screen/items/${itemKey}`);
      await remove(informationItemRef);

      const positionRef = ref(getDatabase(), `sites/${site}/information/screen/position/${itemKey}`);
      await remove(positionRef);

      resolve();
    } catch {
      reject("Something went wrong, unable to delete item.");
    }
  });
}

export function getInformationImages(site) {
  return new Promise(async (resolve) => {
    try {
      const imagesRef = ref(getDatabase(), `sites/${site}/information/screen/images`);
      const data = await get(imagesRef);
      let payload = data.val();

      var images = {
        templates: [],
        uploads: [],
      };

      if (payload) {
        let templates = [];
        if (payload.templates) {
          for (var templateKey in payload.templates) {
            templates.push({
              imageKey: templateKey,
              imageUrl: payload.templates[templateKey],
            });
          }
          images.templates = templates;
        }
        let uploads = [];
        if (payload.uploads) {
          for (var uploadKey in payload.uploads) {
            uploads.unshift({
              imageKey: uploadKey,
              imageUrl: payload.uploads[uploadKey],
            });
          }
          images.uploads = uploads;
        }
      }
      resolve(images);
    } catch {
      resolve({
        templates: [],
        uploads: [],
      });
    }
  });
}

export function addUploadedImage(site, imageKey) {
  return new Promise(async (resolve, reject) => {
    try {
      const newUploadedImageRef = ref(getDatabase(), `sites/${site}/information/screen/images/uploads`);
      await push(newUploadedImageRef, imageKey);
      resolve();
    } catch {
      reject("Something went wrong, unable to add uploaded image.");
    }
  });
}

export function removeUploadedImage(site, imageKey) {
  return new Promise(async (resolve, reject) => {
    try {
      const uploadedImageRef = ref(getDatabase(), `sites/${site}/information/screen/images/uploads/${imageKey}`);
      await remove(uploadedImageRef);
      resolve();
    } catch {
      reject("Something went wrong, unable to delete item.");
    }
  });
}

function _sortPosition(a, b) {
  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}
