import { get, getDatabase, ref, push, update, remove, set } from 'firebase/database';
import moment from 'moment';

const DB_DATE_FORMAT = 'YYYY/M/D';

export function fetchStaticMenus(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const staticMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/static`);
      const staticMenu = await get(staticMenuRef);
      const menus = [];
      if (staticMenu.exists()) {
        const items = staticMenu.val().items;
        const posObj = staticMenu.val().position || {};
        for (var menuId in items) {
          // retrieve position metadata and apply
          let pData = posObj[menuId] || {};
          let position = pData.position || 0;

          menus.push({
            id: menuId,
            header: items[menuId].header,
            url: items[menuId].url,
            position: position
          });
        }
      }
      resolve(menus.sort(_sortPosition));
    } catch {
      reject();
    }
  });
}

export function updateStaticMenu(site, { id, url, header }) {
  return new Promise(async (resolve, reject) => {
    try {
      const staticMenuItemRef = ref(getDatabase(), `/sites/${site}/food/menu/static/items/${id}`);
      await update(staticMenuItemRef, { url, header });
      resolve();
    } catch {
      reject('Unable to update menu');
    }
  });
}

export function addStaticMenu(site, { header, url }) {
  return new Promise(async (resolve, reject) => {
    try {
      const newMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/static/items/`);
      await push(newMenuRef, { header, url });
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteStaticMenu(site, menuId) {
  return new Promise(async (resolve, reject) => {
    try {
      const staticMenuItemRef = ref(getDatabase(), `/sites/${site}/food/menu/static/items/${menuId}`);
      await remove(staticMenuItemRef);
      resolve();
    } catch {
      reject('Something went wrong, unable to delete item.');
    }
  });
}

export function updateStaticMenuItemPositionByIndex(site, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const positionRef = ref(getDatabase(), `/sites/${site}/food/menu/static/position`);
      await set(positionRef, items);
      resolve();
    } catch {
      reject('Something went wrong, unable to update order.');
    }
  });
}

export function fetchPermanentMenu(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const permanentMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/permanent/prod/`);
      const data = await get(permanentMenuRef);

      const sectionsRef = ref(getDatabase(), `/sites/${site}/food/menu/sections`);
      const sections = await get(sectionsRef);

      resolve(_processMenuAndSections(data.val(), sections.val()));
    } catch {
      reject();
    }
  });
}

export function fetchWeeklyMenu(site, startDate) {
  return new Promise(async (resolve, reject) => {
    try {
      const weeklyMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/weekly/${moment(startDate).format(DB_DATE_FORMAT)}/`);
      const data = await get(weeklyMenuRef);

      const sectionsRef = ref(getDatabase(), `/sites/${site}/food/menu/sections`);
      const sections = await get(sectionsRef);

      resolve(_processWeeklyMenu(data.val(), sections.val()));
    } catch {
      reject();
    }
  });
}

export function saveWeeklyMenu(site, menu, startDate) {
  return new Promise(async (resolve, reject) => {
    try {
      const weeklyMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/weekly/${moment(startDate).format(DB_DATE_FORMAT)}/`);
      await update(weeklyMenuRef, menu);
      resolve();
    } catch {
      reject('Unable to update menu');
    }
  });
}

export function savePermanentMenu(site, menu) {
  return new Promise(async (resolve, reject) => {
    try {
      const permanentMenuRef = ref(getDatabase(), `/sites/${site}/food/menu/permanent/prod/`);
      await update(permanentMenuRef, menu);
      resolve();
    } catch {
      reject("unable to update menu");
    }
  });
}

function _processMenuAndSections(payload, sections) {
  let menu = {};
  for (var sectionKey in sections) {
    menu[sectionKey] = {
      header: sections[sectionKey].title,
      items: payload == null || payload[sectionKey] == null ? [] : payload[sectionKey].items,
      note: payload == null || payload[sectionKey] == null ? null : payload[sectionKey].note
    };
  }

  return menu;
}

function _processWeeklyMenu(payload, sections) {
  let days = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];
  let weeklyMenu = {};
  days.map(day => {
    weeklyMenu[day] = {};
    for (var sectionKey in sections) {
      if (payload && payload[day] != null) {
        weeklyMenu[day][sectionKey] = {
          header: sections[sectionKey].title,
          items: payload[day][sectionKey] == null ? [] : payload[day][sectionKey].items,
          note: payload[day][sectionKey] == null ? null : payload[day][sectionKey].note
        };
      } else {
        weeklyMenu[day][sectionKey] = {
          header: sections[sectionKey].title,
          items: [],
          note: null
        };
      }
    }
    return true; //lint error
  });
  return weeklyMenu;
}

function _sortPosition(a, b) {
  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}
