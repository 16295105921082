import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import { fetchAllUsers } from '../actions';
import LoadingScreen from '../../../screens/LoadingScreen';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class UserManagementScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      residents: [],
      searchFilter: null
    }
  }
  componentDidMount() {

    ReactGA.pageview('/modules/app/users/');

    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configuration.data.id !== this.props.configuration.data.id) {
      this.refresh();
    }
  }

  refresh() {
    this.setState({ loading: true });

    fetchAllUsers(this.props.configuration.data.id).then( residents => {
      this.setState({ residents: residents, loading: false })
    }).catch( () => {
      this.setState({ residents: [], loading: false });
    });
  }

  onSearch = text => {
    this.setState({ searchFilter: text });
  }

  render() {
    return (
      <Fragment>
        <ModuleHeader title='App Management' subTitle='Residents' action actionTitle='Create Resident' onAction={this.onCreateUser} search onSearchChange={this.onSearch} />
        {
          this.renderResidents()
        }
      </Fragment>
    )
  }

  renderResidents = () => {
    if (this.state.loading) {
      return <LoadingScreen />
    } else {
      return (
        <Fragment>
          
          <ListGroup>
            <ListGroupItem disabled style={{backgroundColor: '#e8ecef', paddingTop: 0, paddingBottom: 0}}>
              <div className="ListHeader" style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{flex: 1}}>email</div>
                <div style={{flex: 1}}>name</div>
                <div style={{flex: 1}}>room</div>
              </div>
            </ListGroupItem>
            {
              this.state.residents.length > 0
              ?
              this.state.residents.map( resident => {

                // filter out based on search filter (email / firstname / lastname)
                const searchString = resident.email + resident.firstName + resident.lastName;
                if (this.state.searchFilter && !searchString.toLowerCase().includes(this.state.searchFilter.toLowerCase())) {
                  return null;
                }

                return (
                  <ListGroupItem key={resident.id} action style={{cursor: 'pointer' }} onClick={this.onModifyUser(resident)}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{flex: 1}}>{resident.email}</div>
                    <div style={{flex: 1}}>{(resident.lastName + resident.firstName).length > 0 ? `${resident.lastName}, ${resident.firstName}` : null}</div>
                    <div style={{flex: 1}}>{resident.room}</div>
                  </div>
                  </ListGroupItem>
                );
              })
              :
              <div className="EmptyListResponse" style={{marginTop: 20, textAlign: 'center'}}>There are no residents</div>
            }
          </ListGroup>
        </Fragment>
      );
    }
  }

  onModifyUser = user => () => {
    this.props.history.push({
      pathname: '/modules/app/users/create',
      state: { user: user, building: this.props.configuration.data.id }
    })
  }

  onCreateUser = () => {
    this.props.history.push({
      pathname: '/modules/app/users/create',
      state: { user: null, building: this.props.configuration.data.id  }
    })
  }
}

export default withRouter(connect(mapStateToProps)(UserManagementScreen));
