import React, { useEffect, useState } from 'react';
import { Button, Input, Label, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import ModuleHeader from '../../../components/ModuleHeader';
import LoadingScreen from '../../../screens/LoadingScreen';
import {
  fetchStaticMenus,
  updateStaticMenu,
  addStaticMenu,
  deleteStaticMenu,
  updateStaticMenuItemPositionByIndex
} from '../actions';
import { NotificationManager } from 'react-notifications';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';

import FirebaseFileUploader from '../../../components/FirebaseUploader';

export default function StaticMenuScreen() {
  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState({});

  const configuration = useSelector(state => state.configuration);

  useEffect(() => {
    fetchMenus(configuration.data.id);
  }, [configuration]);

  const fetchMenus = async site => {
    setLoading(true);
    try {
      const menus = await fetchStaticMenus(site);
      setMenus(menus);
      setLoading(false);
    } catch {
      setMenus([]);
      setLoading(false);
    }
  };

  const onAddMenu = () => {
    setCurrentMenu({});
    setModalOpen(true);
  };

  const onSelectItem = menu => {
    setCurrentMenu(menu);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setCurrentMenu({});
    setModalOpen(false);
  };

  const onUpdateMenu = menu => {
    updateStaticMenu(configuration.data.id, menu)
      .then(() => {
        NotificationManager.success('Menu has been updated');
        onCloseModal();
        fetchMenus(configuration.data.id);
      })
      .catch(() => {
        NotificationManager.error('Menu unable to be updated');
        onCloseModal();
      });
  };

  const onCreateMenu = menu => {
    addStaticMenu(configuration.data.id, menu)
      .then(() => {
        NotificationManager.success('Menu has been added');
        onCloseModal();
        fetchMenus(configuration.data.id);
      })
      .catch(() => {
        NotificationManager.error('Menu could not be added');
        onCloseModal();
      });
  };

  const onDeleteMenu = menuId => {
    deleteStaticMenu(configuration.data.id, menuId)
      .then(() => {
        NotificationManager.success('Menu has been deleted');
        onCloseModal();
        fetchMenus(configuration.data.id);
      })
      .catch(() => {
        NotificationManager.error('Menu could not be deleted');
        onCloseModal();
      });
  };

  const DragHandle = SortableHandle(() => (
    <img alt='' src={require('./../../../img/sort.png')} style={{ height: 20, width: 30, marginRight: 20 }} />
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newSortedMenus = arrayMove(menus, oldIndex, newIndex);

    setMenus(newSortedMenus);

    // update the backend with new sort order
    let posObject = {};
    newSortedMenus.map((menuItem, index) => {
      posObject[menuItem.id] = { position: index };
      return true;
    });
    updateStaticMenuItemPositionByIndex(configuration.data.id, posObject);
  };

  const SortableItem = SortableElement(({ menu, onSelectItem }) => {
    return (
      <ListGroupItem key={menu.id} action style={{ cursor: 'pointer' }} onClick={() => onSelectItem(menu)}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <DragHandle />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>{menu.header}</div>
          </div>
        </div>
      </ListGroupItem>
    );
  });

  const SortableList = SortableContainer(({ menus, onSelectItem }) => {
    return (
      <ListGroup>
        <ListGroupItem disabled style={{ backgroundColor: '#e8ecef', paddingTop: 0, paddingBottom: 0 }}>
          <div className='ListHeader' style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 1 }}>Menu</div>
          </div>
        </ListGroupItem>
        {menus.length === 0 ? (
          <div className='ListEmpty'>There are no entries here</div>
        ) : (
          menus.map((menu, index) => {
            return <SortableItem key={index} index={index} menu={menu} onSelectItem={onSelectItem} />;
          })
        )}
      </ListGroup>
    );
  });

  return (
    <div>
      <ModuleHeader
        title='Food Services'
        subTitle='Static PDF Menus'
        action
        actionTitle='Add New Menu'
        onAction={onAddMenu}
      />
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <SortableList menus={menus} onSelectItem={onSelectItem} useDragHandle={true} onSortEnd={onSortEnd} />
        </>
      )}
      <MenuItemModal
        active={modalOpen}
        menu={currentMenu}
        onCancel={onCloseModal}
        onUpdate={onUpdateMenu}
        onCreate={onCreateMenu}
        onDelete={onDeleteMenu}
      />
    </div>
  );
}

function MenuItemModal({ active, menu, onUpdate, onCreate, onDelete, onCancel }) {
  const [createMode, setCreateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(undefined);
  const [url, setUrl] = useState('');
  const [header, setHeader] = useState('');
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    setCreateMode(menu.id === undefined);
    setId(menu.id);
    setUrl(menu.id === undefined ? '' : menu.url);
    setHeader(menu.id === undefined ? '' : menu.header);
  }, [menu]);

  useEffect(() => {
    const bHasUrl = url && url.trim().length > 0;
    const bHasHeader = header && header.trim().length > 0;
    setSubmittable(bHasUrl && bHasHeader);
  }, [url, header]);

  const onCancelPressed = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const onDeletePressed = () => {
    if (onDelete) {
      onDelete(menu.id);
    }
  };

  const onConfirmPressed = () => {
    if (!createMode && onUpdate) {
      onUpdate({ id, url, header });
    } else if (createMode && onCreate) {
      onCreate({ url, header });
    }
  };

  const onToggleModal = () => onCancelPressed();

  const onUploadStart = () => setLoading(true);

  const onUploadError = () => {
    setLoading(false);
  };

  const onUploadSuccess = async (downloadUrl) => {
    setUrl(downloadUrl);
    setLoading(false);
  };

  return (
    <Modal isOpen={active} toggle={onToggleModal}>
      <ModalHeader>{createMode ? 'Add New Menu' : 'Modify Menu Item'}</ModalHeader>
      <ModalBody>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Label style={{ margin: 0, padding: 0, paddingLeft: 5 }}>Header: </Label>
            <Input
              defaultValue={header}
              onChange={event => setHeader(event.target.value)}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
            <Label style={{ margin: 0, padding: 0, paddingLeft: 5 }}>Menu: </Label>

            <div>
              <Label
                className='SelectableButton'
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                {url ? `Replace Uploaded Menu` : `Select Menu to Upload`}
                <FirebaseFileUploader
                  hidden={true}
                  accept='image/*, application/pdf'
                  storageDir='static_menu'
                  onUploadStart={onUploadStart}
                  onUploadError={onUploadError}
                  onUploadSuccess={onUploadSuccess}
                />
              </Label>
            </div>
            {url ? (
              <Label
                className='SelectableButton'
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
                onClick={() => window.open(url)}
              >
                Preview Uploaded Menu
              </Label>
            ) : null}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1 }}>
            {!createMode ? (
              <Button color='danger' onClick={onDeletePressed}>
                Delete
              </Button>
            ) : null}
          </div>
          {loading ? (
            <div style={{ marginRight: 3 }}>
              <LoadingScreen />
            </div>
          ) : null}
          <Button color='secondary' onClick={onCancelPressed}>
            Cancel
          </Button>
          <div style={{ width: 5 }} />
          <Button color='primary' onClick={onConfirmPressed} disabled={!submittable || loading}>
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
