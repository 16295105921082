import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ScrollArea from 'react-scrollbar';
import { getInformationImages, addUploadedImage, removeUploadedImage } from '../action';
import FirebaseFileUploader from '../../../components/FirebaseUploader';

class ImageSelectorModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeImageModalTab: 'templates',
      selectedItem: null,
      progress: 'Upload New Image',
      uploads: [],
      templates: []
    };
  }

  getAndSetImages = () => {
    getInformationImages(this.props.building).then(images => {
      this.setState({
        uploads: images.uploads,
        templates: images.templates
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.active && this.props.active) {
      this.getAndSetImages();
    }
  }

  _handleUploadStart = () => {
    this.setState({
      selectedItem: null,
      progress: '0 %'
    })
  }

  _handlerUploadError = error => {
    this.setState({
      progress: 'An error occurred. Click to upload new image.'
    })
  }

  _handleUploadSuccess = downloadUrl => {
    this.setState({
      selectedItem: downloadUrl,
      progress: 'Upload New Image'
    })

    addUploadedImage(this.props.building, downloadUrl).then(() => {
      this.getAndSetImages();
    });
  }

  _onProgress = progress => {
    this.setState({
      progress: progress + ' %'
    })
  }

  renderUploader = (width, height) => {
    return (
      <label style={{ display: 'flex', backgroundColor: '#e9ecef', color: 'white', padding: 10, borderRadius: 10, cursor: 'pointer', height: height, width: width }}>
        <div className="ImageSelector" style={{ flex: 1, display: 'flex', flexDirection: 'column', borderRadius: 10, backgroundColor: '#FFF', justifyContent: 'center', textAlign: 'center' }}>
          <h1 style={{ color: '#e9ecef' }}>{this.state.progress}</h1>
          <FirebaseFileUploader
            hidden
            accept="image/*"
            storageDir="information"
            onUploadStart={this._handleUploadStart}
            onUploadError={this._handlerUploadError}
            onUploadSuccess={this._handleUploadSuccess}
            onProgress={this._onProgress}
          />
        </div>
      </label>
    );
  }

  deleteItem = imageKey => {
    // remove from database, then reload data
    removeUploadedImage(this.props.building, imageKey).then(() => {
      this.getAndSetImages();
    });
  }

  render() {
    const {
      active,
      title,
      cancelColor,
      onCancel
    } = this.props;
    return (
      <div>
        <Modal isOpen={active} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeImageModalTab === 'uploads' })}
                  onClick={() => { this.setState({ activeImageModalTab: 'uploads' }) }}
                >
                  Uploaded Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeImageModalTab === 'templates' })}
                  onClick={() => { this.setState({ activeImageModalTab: 'templates' }) }}
                >
                  Templates
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeImageModalTab}>
              <TabPane tabId="uploads">
                <div style={{ flex: 1, height: 500, padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ScrollArea>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                      {this.renderUploader(404, 154)}
                    </div>
                    {
                      this.state.uploads.map(({ imageKey, imageUrl }) => {

                        let bgColor = imageUrl === this.state.selectedItem ? '#2a2e89' : 'transparent';
                        return (
                          <div key={imageKey} style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                            <img
                              alt=''
                              style={{
                                backgroundColor: bgColor,
                                height: 168,
                                width: 404,
                                objectFit: 'cover',
                                padding: 5,
                              }}
                              src={imageUrl}
                              onClick={() => this.setState({ selectedItem: imageUrl })}
                            />
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 5, paddingRight: 15 }}>
                              <img
                                alt='Wellings'
                                src={require('./../../../img/delete.png')}
                                style={{ height: 30, width: 30, cursor: 'pointer' }}
                                onClick={() => {

                                  // if this item was selected, un-select it
                                  if (imageUrl === this.state.selectedItem) {
                                    this.setState({ selectedItem: null });
                                  }

                                  this.deleteItem(imageKey);
                                }}
                              />
                            </div>
                          </div>

                        );
                      })
                    }
                  </ScrollArea>
                </div>
              </TabPane>
              <TabPane tabId="templates">
                <div style={{ flex: 1, height: 500, padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ScrollArea>
                    {
                      this.state.templates.map(({ imageKey, imageUrl }) => {

                        let bgColor = imageUrl === this.state.selectedItem ? '#2a2e89' : 'transparent';
                        return (
                          <div key={imageKey} style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <img
                              alt=''
                              style={{
                                backgroundColor: bgColor,
                                height: 188,
                                width: 450,
                                objectFit: 'cover',
                                padding: 5,
                                marginBottom: 10
                              }}
                              src={imageUrl}
                              onClick={() => this.setState({ selectedItem: imageUrl })}
                            />
                          </div>
                        );
                      })
                    }
                  </ScrollArea>
                </div>
              </TabPane>
            </TabContent>

          </ModalBody>
          <ModalFooter>
            <Button color={cancelColor} onClick={() => onCancel()}>Cancel</Button>
            <Button color="success" disabled={this.state.selectedItem == null} onClick={() => {
              // notify completion
              if (this.props.onSuccess) {
                this.props.onSuccess(this.state.selectedItem);
              }
            }}>Select</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ImageSelectorModal;