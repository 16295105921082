import { combineReducers } from 'redux';
import authenticationReducer from '../reducers/authentication';
import configurationReducer from '../reducers/config';
import orderReducer from '../modules/food/reducers/orders';

export default combineReducers({
  authentication: authenticationReducer,
  configuration: configurationReducer,
  orders: orderReducer,
})
