import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Nav, NavLink } from "reactstrap";

import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";

import "./../App.css";
import { ModuleMap } from "../modules/config";

const LoggedInSideBarView = () => {
  const authentication = useSelector((state) => state.authentication);
  const configuration = useSelector((state) => state.configuration);

  const [modules, setModules] = useState([]);

  useEffect(() => {
    if (authentication.user && configuration.data) {
      let modules = [];
      const bIsAdmin = authentication.user.type.toLowerCase() === "admin";
      for (var supportedModuleKey in configuration.data.modules) {
        const bEnabled =
          configuration.data?.modules[supportedModuleKey]?.enabled;
        const bHasPermission =
          authentication.user.permissions.includes(supportedModuleKey);
        if (
          ModuleMap[supportedModuleKey] &&
          ((bEnabled && bHasPermission) || bIsAdmin)
        ) {
          modules.push(ModuleMap[supportedModuleKey].sidebar);
        }
      }

      // check if this is an admin user
      if (bIsAdmin) {
        modules.push(ModuleMap.organization.sidebar);
      }

      setModules(modules);
    }
  }, [authentication.user, configuration.data]);

  return (
    <Nav navbar className="SideBar">
      {modules.map((module) => {
        return (
          <div key={module.title} className="SideBarModule">
            <div className="SideBarHeader">{module.title}</div>
            <div className="SideBarModuleLinks">
              {module.items.map((item) => {
                return (
                  <LineItem key={item.path} show={true}>
                    <LinkContainer
                      to={item.path}
                      activeStyle={{ color: "#0056b3" }}
                      style={{ padding: 0 }}
                    >
                      <NavLink>{item.title}</NavLink>
                    </LinkContainer>
                  </LineItem>
                );
              })}
            </div>
          </div>
        );
      })}
    </Nav>
  );
};

function LineItem(props) {
  if (props.show === true) {
    return props.children;
  } else {
    return null;
  }
}

export default withRouter(LoggedInSideBarView);
