import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {  Breadcrumb, BreadcrumbItem, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import { resetPassword } from '../../app/actions';
import BackButton from '../../../components/BackButton';
import { modifyAgent, fetchAgent, inviteAgent } from '../action';
import LoadingScreen from '../../../screens/LoadingScreen';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration
  }
}

class TeamAgentScreen extends Component {
  constructor(props) {
    super(props);

    const { state } = this.props.location;
    const existingUser = state && state.user ? state.user : {};

    this.state = {
      createMode: existingUser.id == null,
      email: '',
      permissions: [],
      error: null,
      processing: false
    };
  }

  componentDidMount() {

    ReactGA.pageview('/modules/organization/team/agent');

    if (!this.state.createMode && this.props.configuration.data.id !== this.props.location.state.building) {
      this.goBack();
    } else if (!this.state.createMode) {
      this.setState({ processing: true });
      this.refresh();
    }
  }

  handleForm = (event) => {

    if (this.state.createMode) {
      this.inviteNewUser();

      ReactGA.event({
        category: 'Organization',
        action: 'Create Agent',
      });

    } else {
      this.modifyUser();

      ReactGA.event({
        category: 'Organization',
        action: 'Modify Agent',
      });

    }

    event.preventDefault();
  }

  inviteNewUser = () => {
    inviteAgent(this.state.email, this.state.permissions, this.props.configuration.data.id).then( () => {
      NotificationManager.success(`Invitation has been sent to ${this.state.email}`);
      this.goBack();
    }).catch( () => {
      NotificationManager.error('Invitation was not able to be sent.');
    });
  }

  modifyUser = () => {
    this.setState({ processing: true });
    modifyAgent(
      this.props.location.state.user.id, 
      this.state.permissions,
      this.state.pending,
      this.props.configuration.data.id
    ).then( () => {
      this.refresh();
      NotificationManager.success('Agent permissions have been updated');
    }).catch( error => {
      this.setState({ processing: false });
      NotificationManager.error('Unable to update Agent permissions');
    })
  }

  _renderResponse = () => {
    if (this.state.error) {
      return (
        <Breadcrumb style={{backgroundColor: '#fdf2f5', margin: 30}}>
          <BreadcrumbItem active>{this.state.error}</BreadcrumbItem>
        </Breadcrumb>
      );
    }

    return (null);
  }

  goBack = () => {
    this.props.history.push('/modules/organization/team');
  }

  refresh = () => {
    fetchAgent(
      this.props.location.state.user.id, 
      this.props.location.state.user.pending,
      this.props.configuration.data.id
    ).then( agent => {
      this.setState({
        email: agent.email,
        permissions: agent.permissions,
        processing: false,
        pending: agent.pending,
        error: false
      })
    }).catch( () => {
      this.setState({
        error: true,
      })
    });
  }

  handleResetPassword = () => {
    if (this.state.createMode) {
      return;
    }

    if (this.state.email.length > 0) {
      resetPassword(this.state.email).then( () => {
        NotificationManager.success(`Password reset instructions sent to ${this.state.email}`)
      }).catch( () => {
        NotificationManager.error("Unable to send password reset instructions");
      })
    }
  }

  renderContent = action => {
    if (this.state.error) {
      return this.renderError();
    } else if (this.state.processing && this.state.email.length === 0) {
      return <LoadingScreen />;
    } else if (this.state.email.length > 0 || this.state.createMode) {
      return this.renderAgent(action);
    }
  }

  renderAgent = (action) => {
    const activeAgent = !this.state.createMode && !this.state.pending;
    return (
      <Form onSubmit={this.handleForm}>
        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input type="email" name="email" id="userEmail" defaultValue={this.state.email} onChange={ (event) => this.setState({email: event.target.value})} disabled={!this.state.createMode}/>
        </FormGroup>
        <FormGroup>
          <Label for="userpermissions">Permissions</Label>
          {
            this.props.configuration.data.permissions.map( permission => {
              const id = permission.id;
              const title = permission.title;
              const description = permission.description;
              return (
                <FormGroup check key={id} style={{marginLeft: 20}}>
                  <Input
                    type="checkbox"
                    disabled={this.state.processing}
                    checked={this.state.permissions.includes(id)}
                    style={{cursor: 'pointer'}}
                    onChange={ event => {
                      // remove or add this item from the list of permissions locally
                      let permissions = this.state.permissions.slice(0);
                      event.target.checked ? permissions.push(id) : permissions.splice(permissions.indexOf(id), 1);
                      this.setState({ permissions: permissions});
                    }}
                  />
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Label style={{padding: 0, margin: 0}}>{title}</Label>
                    <Label style={{fontSize: '0.75em'}}>{description}</Label>
                  </div>
                </FormGroup>
              )
            })
          }
        </FormGroup>
        {
          this.state.pending
          ?
          <div className="Note" style={{marginBottom: 10}}>
            This is a pending invitation
          </div>
          :
          null
        }
        <div style={{display: 'flex', alignItems: 'center', height: 40}}>
          <Button color="success" type="submit">{action}</Button>
          <div style={{paddingLeft: 20}}>
            <ClipLoader loading={this.state.processing}/>
          </div>
          <div style={{flex: 1}} />
        { !activeAgent ? null : <Button color="success" onClick={this.handleResetPassword}>Send Reset Password Email</Button> }
        </div>
        {this._renderResponse()}
      </Form>
    );
  }

  renderError = () => {
    return (
      <div className="Note" style={{marginBottom: 10}}>
        {
          this.state.pending
          ?
          'This invite could not be found'
          :
          'This agent could not be found'
        }
      </div>
    );
  }

  render() {
    const title = this.state.createMode ? 'Invite Agent' : this.state.pending ? 'Modify Invitation' : 'Modify Agent';
    return (
      <Fragment>
        <ModuleHeader title='Organization' subTitle={`Residents / ${title}`} />

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <BackButton title='Back to Team' onClick={this.goBack} />
          <div style={{flex: 1}}/>
        </div>
        
        <div className="UserForm" style={{padding: 20}}>
          {this.renderContent(title)}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(TeamAgentScreen))
