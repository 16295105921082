import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

export default class DropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      value: props.defaultValue,
    }
  }

  render() {
    const { items } = this.props;
    return (
      <Dropdown direction="down" size="sm" isOpen={this.state.open} toggle={this.onDropDownStateChange}>
        <DropdownToggle tag="span" caret style={{}}>
          <span className="DropDownTitle">{this.state.value}</span>
        </DropdownToggle>
        <DropdownMenu >
          {
            items.map( item => {
              const id = item.id;
              const title = item.name; // TODO get this value
              return (
                <DropdownItem key={id} onClick={this.onDropDownItemSelected(id, title)}>{title}</DropdownItem>
              );
            })
          }
        </DropdownMenu>
      </Dropdown>
    );
  }

  onDropDownStateChange = () => {
    this.setState({ open: !this.state.open });
  }

  onDropDownItemSelected = (id, item) => () => {
    this.setState({ value: item });

    if (this.props.onDropDownChange) {
      this.props.onDropDownChange(id);
    }
  }
}