import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import { createNewUser, updateUser, resetPassword, enableUser, fetchUser } from '../actions';
import BackButton from '../../../components/BackButton';

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration
  }
}

class UserScreen extends Component {

  constructor(props) {
    super(props);

    const existingUser = this.props.location.state.user || {};

    this.state = {
      createMode: existingUser.id == null,
      email: existingUser.email,
      firstName: '',
      lastName: '',
      phone: '',
      room: '',
      comments: '',
      disabled: false,
      password: null,
      passwordConfirm: null,
      error: null,
      processing: false
    };
  }

  componentDidMount() {

    if (this.props.location.state.user) {
      this.fetchUser(this.props.location.state.user.id);
    }

    ReactGA.pageview('/modules/app/users/' + this.state.createMode ? 'create' : 'edit');

    if (!this.state.createMode && this.props.configuration.data.id !== this.props.location.state.building) {
      this.goBack();
    }
  }

  fetchUser = () => {
    fetchUser(this.props.location.state.user.id).then(user => {
      this.setState({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        room: user.room,
        comments: user.comments,
        disabled: user.flagDisabled,
      });
    })
  }

  handleForm = (event) => {

    if (this.state.createMode) {
      this.createNewUser();
    } else {
      this.modifyUser();
    }

    event.preventDefault();
  }

  createNewUser = () => {

    let email = this.state.email;
    let password = this.state.password;
    let passwordConfirm = this.state.passwordConfirm;

    let newUser = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      room: this.state.room,
      comments: this.state.comments,
      building: this.props.configuration.data.id
    }

    this.setState({ processing: true });

    createNewUser(email, password, passwordConfirm, newUser).then(() => {
      NotificationManager.success("A new user has been created");
      this.props.history.push('/modules/app/users');

      ReactGA.event({
        category: 'App Management',
        action: 'Create Resident',
      });

    }).catch(error => {
      this.setState({ error: error, processing: false });
    });
  }

  modifyUser = () => {
    const id = this.props.location.state.user.id;
    let existingUser = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      room: this.state.room,
      comments: this.state.comments,
      building: this.props.configuration.data.id
    }

    updateUser(id, existingUser).then(() => {
      NotificationManager.success("User Update Complete");

      ReactGA.event({
        category: 'App Management',
        action: 'Modify Resident',
      });

      // refresh user data
      this.fetchUser();

    }).catch(() => {
      NotificationManager.error("Unable to update user");
    });
  }

  _renderResponse = () => {
    if (this.state.error) {
      return (
        <Breadcrumb style={{ backgroundColor: '#fdf2f5', margin: 30 }}>
          <BreadcrumbItem active>{this.state.error}</BreadcrumbItem>
        </Breadcrumb>
      );
    }

    return (null);
  }

  goBack = () => {
    this.props.history.push('/modules/app/users');
  }

  handleResetPassword = () => {
    if (this.state.createMode) {
      return;
    }

    if (this.state.email.length > 0) {
      resetPassword(this.state.email).then(() => {
        NotificationManager.success(`Password reset instructions sent to ${this.state.email}`)

        ReactGA.event({
          category: 'App Management',
          action: 'Send Password Reset Email'
        });

      }).catch(() => {
        NotificationManager.error("Unable to send password reset instructions");
      })
    }
  }

  handleDisableAccount = () => {
    if (this.state.createMode) {
      return;
    }

    enableUser(this.props.location.state.user.id, this.state.disabled).then(() => {
      NotificationManager.success(`User has been disabled`);
      this.fetchUser(this.props.location.state.user.id);
    }).catch(() => {
      NotificationManager.error(`User could not be disabled`);
    });

  }

  render() {
    const title = this.state.createMode ? 'Create Resident' : 'Modify Resident';
    return (
      <Fragment>
        <ModuleHeader title='App Management' subTitle={`Residents / ${title}`} />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <BackButton title='Back to User Management' onClick={this.goBack} />
          {/* <div style={{flex: 1}}/> */}
        </div>

        <div className="UserForm" style={{ padding: 20 }}>

          {
            this.state.disabled
              ?
              <div className="Notification">
                This user is deactivated
              </div>
              :
              null
          }


          <Form onSubmit={this.handleForm}>
            <FormGroup>
              <Label for="userEmail">Email</Label>
              <Input type="email" name="email" id="userEmail" defaultValue={this.state.email} onChange={(event) => this.setState({ email: event.target.value })} disabled={!this.state.createMode} />
            </FormGroup>
            {
              this.state.createMode
                ?
                <Fragment>
                  <FormGroup>
                    <Label for="userPassword">Password</Label>
                    <Input type="password" name="password" id="userPassword" onChange={(event) => this.setState({ password: event.target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="userPasswordConfirm">Confirm Password</Label>
                    <Input type="password" name="passwordConfirm" id="userPasswordConfirm" onChange={(event) => this.setState({ passwordConfirm: event.target.value })} />
                  </FormGroup>
                </Fragment>
                :
                null
            }
            <FormGroup>
              <Label for="firstName">First Name</Label>
              <Input type="text" name="firstName" id="firstName" defaultValue={this.state.firstName} onChange={(event) => this.setState({ firstName: event.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Last Name</Label>
              <Input type="text" name="lastName" id="lastName" defaultValue={this.state.lastName} onChange={(event) => this.setState({ lastName: event.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="userPhone">Phone Number</Label>
              <Input type="text" name="phone" id="userPhone" defaultValue={this.state.phone} onChange={(event) => this.setState({ phone: event.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="userRoom">Room Number</Label>
              <Input type="text" name="room" id="userRoom" defaultValue={this.state.room} onChange={(event) => this.setState({ room: event.target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="comments">Additional Comments</Label>
              <Input type="textarea" name="comments" id="userName" defaultValue={this.state.comments} onChange={(event) => this.setState({ comments: event.target.value })} />
            </FormGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button color="success" type="submit">{title}</Button>
              <div style={{ paddingLeft: 20 }}>
                <ClipLoader loading={this.state.processing} />
              </div>
              <div style={{ flex: 1 }} />
              {
                this.state.createMode
                  ?
                  null
                  :
                  <Fragment>
                    {
                      this.state.disabled
                        ?
                        <Button color="success" onClick={this.handleDisableAccount} style={{ marginRight: 10 }}>Enable Account</Button>
                        :
                        <Button color="danger" onClick={this.handleDisableAccount} style={{ marginRight: 10 }}>Disable Account</Button>
                    }
                    <Button color="success" onClick={this.handleResetPassword}>Send Reset Password Email</Button>
                  </Fragment>
              }
            </div>
            {this._renderResponse()}
          </Form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(UserScreen))
