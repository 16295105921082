import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import {withRouter} from 'react-router-dom';

import ClosedOrderCollapsableItem from './ClosedOrderCollapsableItem'

const mapStateToProps = function(state){
  return {
    orders: state.orders,
    configuration: state.configuration
  }
}

const ViewAllButton = withRouter(({ history }) => (
  <Button onClick={ () => { history.push('/modules/food/orders/closed') }}>
    View All
  </Button>
));

class ClosedOrdersView extends Component {

  _viewMoreClosed = () => {
    this.props.onPaginate();
  }

  _renderError = () => {
    var bIsFetching = (this.props.orders.closed.currentState === 'FETCHING');
    if (!bIsFetching && this.props.orders.closed.data.length === 0) {
      return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: 10}}>
          There are no closed orders
        </div>
      );
    }
  }

  _renderMore = () => {
    var bIsFetching = (this.props.orders.closed.currentState === 'FETCHING');
    if (!bIsFetching && this.props.orders.closed.data.length > 0) {
      if (this.props.paginate) {
        return (
          <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10}}>
            <Button onClick={this._viewMoreClosed}>View More</Button>
          </div>
        );
      } else {
        return (
          <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 10}}>
            <ViewAllButton/>
          </div>
        );
      }
    }
  }

  render() {
    return (
      <div style={{paddingBottom: 20}}>
        <ListGroup>
          <ListGroupItem disabled style={{backgroundColor: '#e8ecef', paddingTop: 0, paddingBottom: 0}}>
            <div className="ListHeader" style={{display: 'flex', flexDirection: 'row'}}>
              <div style={{flex: 1}}>Closed Orders</div>
            </div>
          </ListGroupItem>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <ClipLoader loading={this.props.orders.closed.currentState === 'FETCHING'}/>
          </div>
        {
          this.props.orders.closed.data.map(order => {
            return (
              <ClosedOrderCollapsableItem key={order.id} order={order}/>
            );
          })
        }
        {this._renderError()}
        {this._renderMore()}
        </ListGroup>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ClosedOrdersView)
