import React from 'react';
import NewsManagementScreen from './view/NewsManagementScreen';
import TickerTapeManagementScreen from './view/TickerTapeManagementScreen';
import SettingsScreen from './view/SettingsManagementScreen';
import NewsItemScreen from './view/NewsItemScreen';
import TickerTapeItemScreen from './view/TickerTapeItemScreen';

const config = {
  sidebar: {
    title: 'Digital Sign',
    items: [
      {
        title: 'News & Events',
        path: '/modules/sign/news'
      },
      {
        title: 'Ticker Tape',
        path: '/modules/sign/tickertape'
      },
      {
        title: 'Settings',
        path: '/modules/sign/settings'
      },
    ]
  },
  routes: [
    {
      path: '/modules/sign/news/create',
      main: () => <NewsItemScreen />,
    },
    {
      path: '/modules/sign/news',
      main: () => <NewsManagementScreen />,
    },
    {
      path: '/modules/sign/tickertape/create',
      main: () => <TickerTapeItemScreen />,
    },
    {
      path: '/modules/sign/tickertape',
      main: () => <TickerTapeManagementScreen />,
    },
    {
      path: '/modules/sign/settings',
      main: () => <SettingsScreen />,
    },
  ]
  
}

export default config