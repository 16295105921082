import React from 'react';
import { Button, Input } from 'reactstrap';

export default class ModuleHeader extends React.PureComponent {

  render() {
    const { title, subTitle, action, actionTitle, search } = this.props;
    return (
      <div className="ModuleHeader" style={{display: 'flex', flexDirection: 'column'}}>
        <h1>{title}</h1>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          
          <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <h2>{subTitle}</h2>
          </div>
          <div style={{alignContent: 'flex-end', display: 'flex', flexDirection: 'row'}}>
            {
              this.props.children
            }
            {
              search
              ?
              <div style={{paddingRight: 10}}>
                <Input style={{padding: 10, height: 40}} type="search" placeholder='Search...' onChange={this.onSearchChange} />
              </div>
              :
              null
            }
            {
              action
              ?
                <Button onClick={this.onAction} style={{height: 40}}>{actionTitle}</Button>
              :
              null
            }
          </div>
        </div>
      </div>
    );
  }

  onSearchChange = event => {
    if (this.props.onSearchChange) {
      this.props.onSearchChange(event.target.value);
    }
  }

  onAction = () => {
    if (this.props.onAction) {
      this.props.onAction();
    }
  }
}