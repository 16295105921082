import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClosedOrdersView from './ClosedOrdersView';
import { fetchClosedOrders } from '../actions/orders';
import ModuleHeader from '../../../components/ModuleHeader';
import BackButton from '../../../components/BackButton';

import {withRouter} from 'react-router-dom';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
    orders: state.orders
  }
}

const INITIAL_FETCH_LIMIT = 10;
const PAGINATION_AMOUNT = 10;
var numPaginations = 0;

class ClosedOrderScreen extends Component {

  componentDidMount() {
    if (this.props.orders.closed.currentState === 'READY') {
      numPaginations = 0;
      fetchClosedOrders(this.props.configuration.data.id, INITIAL_FETCH_LIMIT);
    }
  }

  render() {
    return (
      <div>
        <ModuleHeader title='Food Services' subTitle={`Orders / Closed Orders`} />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <BackButton title='Back to All Orders' onClick={this.goBack} />
          <div style={{flex: 1}}/>
        </div>
        <ClosedOrdersView breadcrumb={true} paginate={true} onPaginate={this.onFetchMore}/>
      </div>
    );
  }

  goBack = () => {
    this.props.history.push('/modules/food/orders/')
  }

  onFetchMore = () => {
    numPaginations++;
    let newLimit = INITIAL_FETCH_LIMIT + (numPaginations*PAGINATION_AMOUNT);
    fetchClosedOrders(this.props.configuration.data.id, newLimit);
  }
}

export default withRouter(connect(mapStateToProps)(ClosedOrderScreen))
