import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { userLogout } from '../actions/authentication';
import DropDown from './DropDown';

const LoggedInInformation = ({ onSiteChange }) => {
  const authentication = useSelector(state => state.authentication);
  const configuration = useSelector(state => state.configuration);

  const [sites, setSites] = useState([]);
  const [showProfileOptions, setShowProfileOptions] = useState(false);

  const handleClick = useCallback(event => {
    let dropdown = document.getElementById('user_profile_dropdown');
    if (!dropdown.contains(event.target)) {
      setShowProfileOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    if (authentication.loggedIn) {
      // transform sites to friendly names using global config data
      let names = [];
      authentication.user.sites.map(site => {
        return names.push({ id: site, name: configuration.data.sitenames[site] });
      });
      setSites(names);
    }
  }, [configuration.data.sitenames, authentication]);

  if (authentication.loggedIn) {
    return (
      <div className='LoggedInHeader'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {configuration.currentState === 'SUCCESS' ? (
            <DropDown
              defaultValue={configuration.data.sitenames[configuration.data.id]}
              items={sites}
              onDropDownChange={onSiteChange}
            />
          ) : null}
          <h1>&nbsp;|&nbsp;</h1>
          <div id='user_profile_dropdown'>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: 30 / 2,
                backgroundColor: '#A8A8A8',
                textAlign: 'center',
                margin: 0,
                padding: 0,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
              onClick={() => setShowProfileOptions(!showProfileOptions)}
            >
              {authentication.user.email.charAt(0).toUpperCase()}
            </div>
            {showProfileOptions ? <ProfileDropdown email={authentication.user.email} /> : null}
          </div>
        </div>
      </div>
    );
  } else if (!authentication.initializing && !authentication.processing) {
    return (
      <div className='LoggedInHeader'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <a href='/login'>
              <Button style={{ padding: 5 }}>
                <h2>Login</h2>
              </Button>
            </a>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const NavHeader = ({ onSiteChange }) => {
  const authentication = useSelector(state => state.authentication);

  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#2e3294',
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <div style={{ flex: 1 }} />
      <div style={{ flex: 5, display: 'flex', flexDirection: 'row' }}>
        <div className='App-title'>
          <a href='/'>
            <img alt='logo' src={require('../img/wellings_logo_admin.png')} />
          </a>
        </div>
        <div style={{ flex: '1' }} />
        {authentication.loggedIn ? <LoggedInInformation onSiteChange={onSiteChange} /> : null}
      </div>
      <div style={{ flex: 1 }} />
    </div>
  );
};

const ProfileDropdown = ({ email }) => {
  return (
    <div className='ProfileDropdown' style={{ position: 'absolute', zIndex: 2 }}>
      <div className='ProfileDropdownHeader'>
        <h1>Logged in as</h1>
        <h2>{email}</h2>
      </div>
      <a href='/user/'>
        <div
          className='ProfileDropdownItem'
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          <div style={{ marginRight: 10, display: 'flex', justifyContent: 'center' }}>
            <img height={24} width={24} alt='' src={require('../img/account_circle.png')} />
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            User Profile
          </div>
        </div>
      </a>
      <div
        className='ProfileDropdownFooter'
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
      >
        <button onClick={() => userLogout()}>Logout</button>
      </div>
    </div>
  );
};

export default NavHeader;
