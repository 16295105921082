import React, { Component } from 'react';

import MenuSection from './MenuSection';

function _createDraftMenu(rawMenu) {
  let draftMenu = {};
  for (var sectionKey in rawMenu) {

    let bHasItems = rawMenu[sectionKey].items != null && rawMenu[sectionKey].items.length > 0;
    let bHasNote = rawMenu[sectionKey].note != null;

    if (bHasItems || bHasNote) {
      draftMenu[sectionKey] = {};

      if (bHasItems) {
        draftMenu[sectionKey].items = rawMenu[sectionKey].items
      }

      if (bHasNote) {
        draftMenu[sectionKey].note = rawMenu[sectionKey].note
      }
    }
  }
  return draftMenu;
}

export default class MenuView extends Component {
  constructor(props) {
    super(props);

    // menu from props to pre-populate
    this.draftMenu = _createDraftMenu(props.menu);
  }

  componentDidUpdate(prevProps, prevState) {
    let nextMenu = this.props.menu;
    let oldMenu = prevProps.menu;

    if (nextMenu !== oldMenu) {
      this.draftMenu = this.props.menu ? _createDraftMenu(this.props.menu) : {};
    }
  }

  _onSaveAndPublish = () => {
    if (this.props.onSave) {
      this.props.onSave(this.props.id, this.draftMenu);
    }
  }

  _onMenuSectionUpdate = (section, menuSection) => {
    if (section.length === 0) {
      console.log("Invalid section");
      return;
    }
    var draftMenu = this.draftMenu ? this.draftMenu : {};
    draftMenu[section] = menuSection;
    this.draftMenu = draftMenu;

    this._onSaveAndPublish();

  }

  _renderMenu = () => {
    let menu = (this.props.menu != null ? this.props.menu : {})
    return (
      <div>
        <BuildMenuSections key="menu" menu={menu} onUpdate={this._onMenuSectionUpdate} disableNote={this.props.disableNote}/>
      </div>
    )
  }

  render() {
    return (
      <div style={{paddingBottom: 20}}>
        {this._renderMenu()}
      </div>
    );
  }
}

function BuildMenuSections(props) {
  const { disableNote, menu, onUpdate } = props;
  let sections = [];

  for (var sectionKey in menu) {
    let section = menu[sectionKey];
    sections.push(
      <MenuSection key={sectionKey} id={sectionKey} title={section.header} menuSection={section} onUpdate={onUpdate} disableNote={disableNote}/>
    )
  }

  return sections;
}

