import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoggedInSideBarView from '../components/LoggedInSideBarView';
import { ModuleMap } from '../modules/config';
import Welcome from '../view/Welcome';
import { ModuleMapUserInfo } from '../screens/user/config';
import LoggedInUserInfoSideBarView from '../components/LoggedInUserInfoSideBarView';
import Login from '../screens/Login';
import NotFoundScreen from '../screens/NotFoundScreen';

export const ActionRoutes = () => {
  const authentication = useSelector(state => state.authentication);
  const configuration = useSelector(state => state.configuration);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={props => <Component {...props} />} />;
  };

  const privateRoutes = (() => {
    let routes = [];

    // welcome screen route
    routes.push({
      path: '/welcome',
      main: () => <Welcome />
    });

    if (!authentication.loggedIn) {
      return routes;
    }

    const bIsAdmin = authentication.user.type.toLowerCase() === 'admin';
    for (var module in ModuleMap) {
      const bHasModule = configuration.data.modules[module] != null;
      const bEnabled = bHasModule && configuration.data.modules[module].enabled;
      const bHasPermission = authentication.user.permissions.includes(module);
      if ((bEnabled && bHasPermission) || bIsAdmin) {
        routes.push(...ModuleMap[module].routes);
      }
    }
    return routes;
  }).call();

  const privateUserRoutes = (() => {
    let routes = [];

    if (!authentication.loggedIn) {
      return routes;
    }

    for (var module in ModuleMapUserInfo) {
      routes.push(...ModuleMapUserInfo[module].routes);
    }

    return routes;
  }).call();

  if (authentication.loggedIn) {
    return (
      <div>
        {/* Redirect root and login to welcome screen when logged in */}
        <Route key='root' path='/' exact={true} component={() => <Redirect from='/' to='/welcome' />} />
        <Route key='login' path='/login' exact={true} component={() => <Redirect from='/' to='/welcome' />} />

        <Route
          path='/'
          component={({ location }) => {
            // determine base path in case this has different look and feel
            const pathBase = location.pathname.split('/')[1];
            // user path
            if (pathBase === 'user') {
              return (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={{ marginTop: 10 }}>
                    <LoggedInUserInfoSideBarView />
                  </div>
                  <div style={{ width: '50vw', minWidth: 580, margin: 10 }}>
                    <Switch>
                      {/* Redirect logged in users to user profile for user root for simplicity */}
                      <Route
                        key='profile'
                        path='/user'
                        exact={true}
                        component={() => <Redirect from='/user' to='/user/profile' />}
                      />

                      {// determine routes based on module map and permissions
                        privateUserRoutes.map((item, index) => {
                          return <PrivateRoute key={index} path={item.path} component={item.main} />;
                        })}
                    </Switch>
                  </div>
                </div>
              );
            }

            // root path
            return (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ marginTop: 10 }}>
                  <LoggedInSideBarView />
                </div>
                <div style={{ width: '50vw', minWidth: 580, margin: 10 }}>
                  <Switch>
                    {// determine routes based on module map and permissions
                      privateRoutes.map((item, index) => {
                        return <PrivateRoute key={index} path={item.path} component={item.main} />;
                      })}
                    {/* Fall through screen is 404 */}
                    <Route component={() => <NotFoundScreen />} />
                  </Switch>
                </div>
              </div>
            );
          }}
        />
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ width: '50vw', minWidth: 580 }}>
        <Switch>
          <Route key='login' path='/login' exact={false} component={() => <Login />} />
          <Route
            render={props => <Redirect to={{ pathname: '/login', state: { from: { pathname: '/welcome' } } }} />}
          />
        </Switch>
      </div>
    </div>
  );
};
