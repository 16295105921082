import {
  query,
  getDatabase,
  ref,
  get,
  push,
  remove,
  update,
  orderByChild,
  equalTo,
  limitToFirst,
  startAt,
  orderByKey,
  limitToLast,
  endAt,
} from "firebase/database";

export async function fetchTickets(site, type, pageNumber, hideClosed = false) {
  try {
    const BATCH_SIZE = 50;
    const ticketsRef = hideClosed
      ? query(
          ref(getDatabase(), `/sites/${site}/ticketing/${type}/tickets`),
          orderByChild("state"),
          equalTo("open"),
          limitToLast(BATCH_SIZE * pageNumber)
        )
      : query(
          ref(getDatabase(), `/sites/${site}/ticketing/${type}/tickets`),
          limitToLast(BATCH_SIZE * pageNumber)
        );

    const ticketsData = await get(ticketsRef);

    const tickets = [];
    ticketsData.forEach((ticketData) => {
      tickets.push({
        id: ticketData.key,
        urn: ticketData.child("urn").val(),
        title: ticketData.child("title").val(),
        tag: ticketData.child("tag").val(),
        description: ticketData.child("description").val(),
        uid: ticketData.child("uid").val(),
        createdDate: ticketData.child("createdDate").val(),
        state: ticketData.child("state").val(),
      });
    });
    return tickets.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function fetchTicket(site, type, urn) {
  try {
    const ticketRef = query(
      ref(getDatabase(), `/sites/${site}/ticketing/${type}/tickets`),
      orderByChild("urn"),
      equalTo(urn)
    );
    const ticketDataResult = await get(ticketRef);
    const key = Object.keys(ticketDataResult.val()).at(0);
    if (!key) return null;
    const ticketData = ticketDataResult.child(key);

    const userRef = ref(
      getDatabase(),
      `/Users/${ticketData.child("uid").val()}/`
    );
    const userData = await get(userRef);

    return {
      id: ticketData.key,
      urn: ticketData.urn,
      tag: ticketData.child("tag").val(),
      description: ticketData.child("description").val(),
      createdDate: ticketData.child("createdDate").val(),
      state: ticketData.child("state").val(),
      user: {
        name: `${userData.child("lastName").val()}, ${userData
          .child("firstName")
          .val()}`,
        room: userData.child("room").val(),
        email: userData.child("email").val(),
        phone: userData.child("phone").val(),
      },
    };
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function updateTicketState(site, type, id, newState) {
  try {
    if (!["open", "closed"].includes(newState)) return false;

    const ticketRef = ref(
      getDatabase(),
      `/sites/${site}/ticketing/${type}/tickets/${id}`
    );

    await update(ticketRef, { state: newState });
    return true;
  } catch {
    return false;
  }
}

export async function fetchTags(site, type) {
  try {
    const ret = [];
    const tagsRef = ref(getDatabase(), `sites/${site}/ticketing/${type}/tags`);
    const data = await get(tagsRef);
    // console.log(data.val());
    for (const tag in data.val()) {
      ret.push({
        id: data.child(tag).key,
        description: data.child(tag).child("description").val(),
        title: data.child(tag).child("title").val(),
        tag: data.child(tag).child("tag").val(),
      });
    }
    // console.log(ret);
    return ret;
  } catch {
    return [];
  }
}

export async function createTag(site, type, tag, title, description) {
  try {
    if (!tag || tag.trim().length == 0) return false;
    if (!description || description.trim().length == 0) return false;
    if (!title || title.trim().length == 0) return false;

    const tagsRef = ref(getDatabase(), `sites/${site}/ticketing/${type}/tags`);
    await push(tagsRef, {
      tag,
      description,
      title,
    });
    return true;
  } catch {
    return false;
  }
}

export async function deleteTag(site, type, id) {
  try {
    const tagsRef = ref(
      getDatabase(),
      `sites/${site}/ticketing/${type}/tags/${id}`
    );
    await remove(tagsRef);
    return true;
  } catch {
    return false;
  }
}

export async function getEmails(site) {
  try {
    const ret = [];
    const emailRef = ref(
      getDatabase(),
      `sites/${site}/ticketing/settings/emails`
    );
    const emails = await get(emailRef);
    for (const email in emails.val()) {
      ret.push({
        id: emails.child(email).key,
        value: emails.child(email).val(),
      });
    }
    return ret;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function addEmail(site, email) {
  try {
    if (!email || email.trim().length == 0) return false;

    const emailRef = ref(
      getDatabase(),
      `sites/${site}/ticketing/settings/emails`
    );
    await push(emailRef, email);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function removeEmail(site, id) {
  try {
    const emailRef = ref(
      getDatabase(),
      `sites/${site}/ticketing/settings/emails/${id}`
    );
    await remove(emailRef);
    return true;
  } catch {
    return false;
  }
}
