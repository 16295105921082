import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, ListGroup, ListGroupItem } from 'reactstrap';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from 'react-sortable-hoc';
import { fetchTickertapeItems, updateTickerTapeItemPositionByIndex } from '../action';
import ModuleHeader from '../../../components/ModuleHeader';
import LoadingScreen from '../../../screens/LoadingScreen';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

const DragHandle = SortableHandle(() => <img alt='' src={require('./../../../img/sort.png')} style={{height: 20, width: 30, marginRight: 20}}/>);

const SortableNewsItem = SortableElement(({item, history, site}) => {
  return (
    <ListGroupItem
      action
      onClick={ () => { history.push({
        pathname: '/modules/sign/tickertape/create/', 
        state: { item: item, site: site }
      })}}
      style={{cursor: 'pointer' }}
    >
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <DragHandle />
        <div className="ActiveCollapseItem" style={{flex: 1, justifyContent: 'center'}}>
          <h1 style={{padding: 0, margin: 0}}>{item.title}</h1>
        </div>
        <div style={{alignContent: 'center', justifyContent: 'center'}}>
          { item.published_ipad ? <Badge>iPad</Badge> : null }
          { item.published_sign ? <Badge style={{marginLeft: 5}}>Digital Sign</Badge> : null }
        </div>
      </div>
    </ListGroupItem>
  );
});

const SortableNewsList = SortableContainer(({items, history, site}) => {
  return (
    <ListGroup>
      <ListGroupItem disabled style={{backgroundColor: '#e8ecef', paddingTop: 0, paddingBottom: 0}}>
        <div className="ListHeader" style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{width: 50}} />
          <div style={{flex: 1}}>item</div>
          <div style={{}}>published</div>
        </div>
      </ListGroupItem>
      {
        items.length === 0
        ?
        <div className="ListEmpty">
          There are no entries here
        </div>
        :
        items.map((value, index) => (
          <SortableNewsItem key={`item-${index}`} index={index} item={value} history={history} site={site} />
        ))
      }
    </ListGroup>
  );
});

class TickerTapeManagementScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sortedItems: [],
      loading: false
    };
  }

  componentDidMount() {
    ReactGA.pageview(`/modules/sign/tickertape/`);
    this.setState({ loading: true });
    this.refreshItems();
  }

  render() {
    return (
      <div style={{paddingBlockEnd: 50}}>
       <ModuleHeader title='Digital Sign' subTitle='TickerTape'  action actionTitle='Create Item' onAction={this.onCreate}/>
      {
        this.state.loading
        ?
        <LoadingScreen />
        :
        <SortableNewsList items={this.state.sortedItems} history={this.props.history} useDragHandle={true} onSortEnd={this.onSortEnd} site={this.props.configuration.data.id}/>
      }
      </div>
    );
  }

  onCreate = () => {
    if (this.props.history) {
      this.props.history.push({
        pathname: '/modules/sign/tickertape/create',
        state: { item: null, site: this.props.configuration.data.id  }
      });
    }
  }

  refreshItems = () => {
    fetchTickertapeItems(this.props.configuration.data.id).then( items => {
      this.setState({ sortedItems: items, loading: false });
    }).catch( () => {
      this.setState({ loading: false });
    });
  }

  onSortEnd = ({oldIndex, newIndex}) => {

    let newSortedItems = arrayMove(this.state.sortedItems, oldIndex, newIndex);

    this.setState({
      sortedItems: newSortedItems,
    });

    // update the backend with new sort order
    let posObject = {};
    newSortedItems.map((newsItem, index) => {
      posObject[newsItem.id] = { position: index };
      return true;
    });
    updateTickerTapeItemPositionByIndex(this.props.configuration.data.id, posObject);
  };
}

export default withRouter(connect(mapStateToProps)(TickerTapeManagementScreen))
