import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, query, orderByChild, equalTo, update, set } from "firebase/database";
import { getAuth, sendPasswordResetEmail, createUserWithEmailAndPassword } from "firebase/auth";
const Config = process.env.REACT_APP_FB_ENV === "prod" ? require('../../../FirebaseConstants-prod.json') : require('../../../FirebaseConstants-dev.json');

// create secondary auth user for creation of users (using default will cause system to logout)
var createUserAuthRef = initializeApp(
  Config.firebase,
  "secondaryAuth"
);

export function fetchAllModules(site, filteredParentId = null) {
  return new Promise(async (resolve, reject) => {

    try {
      const modulesListRef = ref(getDatabase(), `sites/${site}/modules`);
      const moduleListData = await get(modulesListRef);
      const modules = [];
      for (const module in moduleListData.val()) {
        const parentModuleId = moduleListData.val()[module].parentModuleId;

        // if there is a parent filter, ignore any modules where parent doesn't match
        if (filteredParentId && filteredParentId !== parentModuleId) {
          continue;
        }

        // if there is no parent filter, ignore any modules with a parent
        if ((filteredParentId == null) & (parentModuleId != null)) {
          continue;
        }

        const metaData = moduleListData.val()[module].meta || {};
        modules.push({
          id: module,
          color: metaData.landerColor || "#E6E6E6",
          icon: metaData.landerIcon || null,
          name: metaData.title,
          enabled: metaData.enabled,
          type: moduleListData.val()[module].type,
          description: moduleListData.val()[module].description,
          minVersion: metaData.minVersion,
          maxVersion: metaData.maxVersion,
        });
      }
      resolve(modules);
    } catch {
      reject();
    }
  });
}

export function updateModuleMetaData(site, moduleId, data) {
  return new Promise(async (resolve, reject) => {

    const moduleMetaDataRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/meta`);
    try {
      await update(moduleMetaDataRef, data);
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateModuleDescription(site, moduleId, description) {
  return new Promise(async (resolve, reject) => {
    const moduleRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}`);
    try {
      await update(moduleRef, { description });
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateSublanderModuleMetaData(
  site,
  sublanderModuleId,
  moduleId,
  data
) {
  return new Promise(async (resolve, reject) => {
    const moduleSubmoduleMetaDataRef = ref(getDatabase(), `sites/${site}/modules/${sublanderModuleId}/data/modules/${moduleId}/meta`);
    try {
      await update(moduleSubmoduleMetaDataRef, data);
      resolve();
    } catch {
      reject();
    }
  });
}

export function fetchAllUsers(site) {
  return new Promise(async (resolve, reject) => {

    try {
      const userDataRef = query(ref(getDatabase(), `/Users`), orderByChild("building"), equalTo(site));
      const userData = await get(userDataRef);

      let residents = [];
      if (userData.val()) {
        for (let id in userData.val()) {
          const user = userData.val()[id];
          residents.push({
            id: id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            room: user.room,
            comments: user.comments,
            flagDisabled: user.flagDisabled,
          });
        }
      }
      resolve(residents);
    } catch {
      reject();
    }
  });
}

export function fetchUser(uid) {
  return new Promise(async (resolve, reject) => {

    try {
      const userDataRef = ref(getDatabase(), `/Users/${uid}`);
      const userData = await get(userDataRef);
      resolve({
        id: uid,
        email: userData.val().email,
        firstName: userData.val().firstName,
        lastName: userData.val().lastName,
        phone: userData.val().phone,
        room: userData.val().room,
        comments: userData.val().comments,
        flagDisabled: userData.val().flagDisabled,
      });
    } catch {
      reject();
    }
  });
}

export function createNewUser(email, password, passwordConfirm, newUser) {
  return new Promise(async (resolve, reject) => {
    // ensure we control what goes into database at this level
    let payload = {
      building: newUser.building,
      email: newUser.email,
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      phone: newUser.phone,
      room: newUser.room,
      passwordResetFlag: true,
    };

    // client side error handling
    let error = null;
    if (email == null) {
      error = "Email cannot be empty, enter a valid email address.";
    } else if (password !== passwordConfirm) {
      error = "Password fields must match, please enter and confirm password.";
    } else if (payload == null) {
      error = "Invalid user data";
    } else if (payload.building == null) {
      error =
        "A building has not been specified (this is determined by the logged in superuser). Cannot create resident.";
    }

    if (error) {
      reject(error);
    } else {
      payload.email = payload.email.toLowerCase();

      try {
        await createUserWithEmailAndPassword(getAuth(createUserAuthRef), email, password);
        getAuth(createUserAuthRef).onAuthStateChanged(async (newUser) => {
          if (newUser) {
            // replace undefined values with empty strings
            for (var key in payload) {
              if (payload[key] == null) {
                payload[key] = "";
              }
            }
            const newUserRef = ref(getDatabase(), `/Users/${newUser.uid}`);
            await set(newUserRef, payload)
            resolve();
          }
        });
      } catch (e) {
        reject();
      }

    }
  });
}

export function updateUser(key, updatedUser) {
  return new Promise(async (resolve, reject) => {

    const userRef = ref(getDatabase(), `/Users/${key}`);

    // replace undefined values with empty strings
    for (var field in updatedUser) {
      if (updatedUser[field] == null) {
        updatedUser[field] = "";
      }
    }

    try {
      await update(userRef, updatedUser);
      resolve();
    } catch {
      reject();
    }

  });
}

export function resetPassword(email) {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(getAuth(), email)
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        reject();
      });
  });
}

export function enableUser(uid, enabled) {
  return new Promise(async (resolve, reject) => {
    const userDisabledFlagRef = ref(getDatabase(), `/Users/${uid}/flagDisabled`);
    try {
      await set(userDisabledFlagRef, !enabled);
      resolve();
    } catch {
      reject();
    }
  });
}
