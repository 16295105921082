import React, { useRef, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";

import { useSelector } from "react-redux";
import { updateFeedbackSettings } from "../actions/settings";

const FeedbackModuleSettings = ({ moduleId, data }) => {
  const [description, setDescription] = useState(data?.description ?? "");

  const configuration = useSelector((state) => state.configuration);
  const [emails, setEmails] = useState(data?.emails ?? []);

  const handleForm = (event) => {
    if (!description || description.length === 0) {
      NotificationManager.error("A Description is required");
    } else {
      updateFeedbackSettings(
        configuration.data.id,
        moduleId,
        description,
        emails
      )
        .then(() => {
          NotificationManager.success("Module settings updated.");
        })
        .catch(() => {
          NotificationManager.error(
            "Module was not updated, something went wrong"
          );
        });
    }

    event.preventDefault();
  };

  const onAddEmail = (email) => {
    setEmails((existing) => existing.concat([email]));
  };

  const onRemoveEmail = (email) => {
    setEmails((existing) => existing.filter((e) => e !== email));
  };

  return (
    <div className="ModuleSettingsContainer">
      <Form onSubmit={handleForm}>
        <FormGroup>
          <h1>Comment Card Description</h1>
          <Label>Describe what feedback you are looking for</Label>
          <Label>
            <i>
              e.g Please provide comments and feedback on your experience at our
              restaurants
            </i>
          </Label>
          <Input
            type="textarea"
            placeholder="Fill out this comment card to let us know ..."
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <h1>Email Receipients</h1>
          <Label>
            Add e-email addresses that will receive these comments directly
          </Label>
          <EmailSubmission onAdd={onAddEmail} />
          <div style={{ display: "flex", flexWrap: "wrap", marginTop: 20 }}>
            {emails.map((email) => (
              <SubmittedEmail
                key={email}
                email={email}
                onRemove={onRemoveEmail}
              />
            ))}
          </div>
        </FormGroup>
        <div
          style={{
            borderTop: "1px solid #A3A3A3",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button color="success" type="submit">
            Update
          </Button>
          <div style={{ flex: 1 }} />
        </div>
      </Form>
    </div>
  );
};

const EmailSubmission = ({ onAdd }) => {
  const buttonRef = useRef();
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(true);
  const validator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onSubmit = () => {
    if (validator.test(email)) {
      setValid(true);
      onAdd(email);
      setEmail("");
    } else {
      setValid(false);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ marginRight: 10 }}>
        <Input
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          invalid={!valid}
          type="email"
          style={{ maxWidth: 400 }}
          onKeyPress={(keyEvent) => {
            // capture enter key as this is nested in a form
            if (keyEvent.key === "Enter") {
              keyEvent.preventDefault();
              onSubmit();
            }
          }}
        />
        <FormFeedback>Must be a valid e-mail address</FormFeedback>
      </div>
      <div>
        <Button ref={buttonRef} style={{ display: "flex" }} onClick={onSubmit}>
          Add
        </Button>
      </div>
    </div>
  );
};

const SubmittedEmail = ({ email, onRemove }) => {
  return (
    <>
      <div
        className="EmailBadge"
        style={{ position: "relative", marginRight: 10, marginBottom: 10 }}
      >
        {email}
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            marginTop: -10,
            marginRight: -10,
            height: 20,
            width: 20,
            borderRadius: 20,
            backgroundColor: "#f6f6f6",
            border: "solid 1px #a3a3a3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon onClick={() => onRemove(email)} />
        </div>
      </div>
    </>
  );
};

const CloseIcon = (props) => (
  <svg width={10} height={10} viewBox="0 0 14 14" {...props}>
    <path
      d="M1.613.21l.094.083L7 5.585 12.293.293a1 1 0 0 1 1.497 1.32l-.083.094L8.415 7l5.292 5.293a1 1 0 0 1-1.32 1.497l-.094-.083L7 8.415l-5.293 5.292a1 1 0 0 1-1.497-1.32l.083-.094L5.585 7 .293 1.707A1 1 0 0 1 1.613.21z"
      fill="#11342F"
      fillRule="nonzero"
    />
  </svg>
);

export { FeedbackModuleSettings };
