import React, { useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Input, Form, FormGroup, Label, Button } from "reactstrap";
import LoadingScreen from "../../../screens/LoadingScreen";
import { useSelector } from "react-redux";
import { updateWebviewUri } from "../actions/settings";
import FirebaseFileUploader from "../../../components/FirebaseUploader";

const WebviewModuleSettings = ({ moduleId, data }) => {
  const [uri, setUri] = useState(data?.uri ?? "");
  const [processing, setProcessing] = useState(false);

  const configuration = useSelector((state) => state.configuration);

  const handleForm = (event) => {
    if (!uri || uri.length === 0) {
      NotificationManager.error("A URL is required");
    } else {
      // ensure the URL begins with https://
      const mungedUrl = !uri.startsWith("https://") ? "https://" + uri : uri;

      updateWebviewUri(configuration.data.id, moduleId, mungedUrl)
        .then(() => {
          NotificationManager.success("URI was updated");
        })
        .catch(() => {
          NotificationManager.error(
            "Module was not updated, something went wrong"
          );
        });
    }

    event.preventDefault();
  };

  const onUploadSuccess = (downloadUrl) => {
    setUri(downloadUrl);
    setProcessing(false);
  };

  return (
    <div className="ModuleSettingsContainer">
      <Form onSubmit={handleForm}>
        <FormGroup>
          <h1>Web Address</h1>
          <Label>
            Enter the web address (URL) that will be shown when you select this
            module
          </Label>
          <Input
            placeholder="https://google.com"
            value={uri}
            onChange={(event) => setUri(event.target.value)}
          />
        </FormGroup>
        <div style={{ flex: 1 }}>
          <h1 style={{ textAlign: "center" }}>OR</h1>
        </div>
        <FormGroup>
          <h1>Upload File to Display</h1>
          <Label>
            Upload a file (PDF or Image) to display when you select this module.
            It will upload the file to the cloud, and set the above Web Address
            to it's location.
          </Label>
          <Label
            className="SelectableButton"
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: 50,
            }}
          >
            {processing ? <LoadingScreen /> : `Select File to Upload`}
            <FirebaseFileUploader
              hidden={true}
              accept="image/*, application/pdf"
              storageDir="module_web_links"
              onUploadStart={() => setProcessing(true)}
              onUploadSuccess={onUploadSuccess}
            />
          </Label>
        </FormGroup>
        <div
          style={{
            borderTop: "1px solid #A3A3A3",
            paddingBottom: 5,
            paddingTop: 5,
          }}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button color="success" type="submit">
            Update
          </Button>
          <div style={{ flex: 1 }} />
        </div>
      </Form>
    </div>
  );
};

export { WebviewModuleSettings };
