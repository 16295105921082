import React from 'react';
import UserProfileScreen from './view/UserProfileScreen';

const config = {
  sidebar: {
    title: 'Account',
    items: [
      {
        title: 'User Profile',
        path: '/user/profile'
      },
    ]
  },
  routes: [
    {
      path: '/user/profile',
      main: () => <UserProfileScreen />,
    }
  ]

}

export default config