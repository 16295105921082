const initialState = {
  currentState: 'READY',
  data: {
    id: null,
    name: null,
    modules: {},
    permissions: {},
    sitenames: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONFIG_FETCHING": {
      return {
        currentState: 'FETCHING',
        data: {
          ...state.data
        }
      }
    }
    case "CONFIG_SUCCESS": {
      return {
        currentState: 'SUCCESS',
        data: {
          id: action.site,
          name: action.payload.name,
          modules: getSupportedModules(action.payload),
          permissions: getPermissions(action.permissions),
          sitenames: getSiteNames(action.global)
        }
      }
    }
    case "CONFIG_FAILURE": {
      return {
        currentState: 'ERROR',
        data: {
          id: null,
          name: null,
          modules: {}
        }
      }
    }
    default: {
      return state;
    }
  }
}

function getSiteNames(payload) {
  return payload.sitenames;
}

function getPermissions(payload) {
  let permissions = [];
  for (const permissionKey in payload) {
    permissions.push({
      id: permissionKey,
      title: payload[permissionKey].title || '',
      description: payload[permissionKey].description || '',
    })
  }
  return permissions;
}

function getSupportedModules(payload) {
  let modules = {};
  let rModules = [];
  if (payload.modules) {

    // yes yes, we're n^2 the modules to sort them. n is like 4, who cares.
    for (var moduleKey in payload.modules) {
      let m = payload.modules[moduleKey];
      m['id'] = moduleKey;
      rModules.push(m);
    }

    rModules.sort(_sortOrder).forEach( item => {
      modules[item.id] = {
        enabled: item.enabled,
        flags: item.flags || {}
      }
    });
  }
  return modules;
}

function _sortOrder(a, b) {
  if (a.order < b.order) {
    return -1;
  } else if (a.order > b.order) {
    return 1;
  }
}
