import React from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import { fetchAllModules, updateModuleMetaData } from "../actions";
import AppComponent from "./component/AppModule";
import ModuleHeader from "../../../components/ModuleHeader";
import LoadingScreen from "../../../screens/LoadingScreen";
import { createModule } from "../actions/settings";
import { CreateModuleModal } from "./component/CreateModuleModal";

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};

class AppModuleScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: [],
      loading: false,
      createModal: false,
    };
  }

  componentDidMount() {
    ReactGA.pageview("/modules/app/modules/");
    this.setState({ loading: true });
    this.refreshModules();
  }

  render() {
    const bEditModules =
      this.props.configuration.data.modules.app.flags &&
      this.props.configuration.data.modules.app.flags.editModules;
    return (
      <div>
        <ModuleHeader
          title="App Management"
          subTitle="Modules"
          action
          actionTitle="Create Module"
          onAction={() => this.setState({ createModal: true })}
        />
        {!this.state.loading ? (
          this.state.modules.map((module) => (
            <div key={module.id} style={{ minWidth: 500, marginBottom: 20 }}>
              <AppComponent
                color={module.color}
                icon={module.icon}
                name={module.name}
                description={module.description}
                type={module.type}
                enabled={module.enabled}
                minVersion={module.minVersion}
                maxVersion={module.maxVersion}
                onModuleDataChange={this.onModuleDataChange(module.id)}
                editable={bEditModules}
                onEditPressed={this.onEditPressed(module)}
              />
            </div>
          ))
        ) : (
          <LoadingScreen />
        )}
        <CreateModuleModal
          isOpen={this.state.createModal}
          toggle={() => this.setState({ createModal: false })}
          onModuleTypeSelected={this.createModuleByType}
        />
      </div>
    );
  }

  createModuleByType = (type, defaultMinVersion) => async () => {
    try {
      const moduleId = await createModule(
        this.props.configuration.data.id,
        type,
        defaultMinVersion
      );
      this.onModuleCreated(moduleId);
    } catch {
      NotificationManager.error("Unable to create your module");
    }
  };

  onModuleCreated = (moduleId) => {
    this.props.history.push(`/modules/app/modules/settings/${moduleId}`, {
      site: this.props.configuration.data.id,
      firstLaunch: true,
    });
  };

  onEditPressed = (module) => () => {
    this.props.history.push(`/modules/app/modules/settings/${module.id}`, {
      site: this.props.configuration.data.id,
      firstLaunch: false,
    });
  };

  onModuleDataChange = (id) => (data) => {
    updateModuleMetaData(this.props.configuration.data.id, id, data)
      .then(() => {
        NotificationManager.success("Module Updated");
        this.refreshModules();

        ReactGA.event({
          category: "App Management",
          action: "Module Toggled",
          label: "Enabled",
          value: Number(data.enabled),
        });
      })
      .catch(() => {
        NotificationManager.error("Module Failed to update");
        this.refreshModules();
      });
  };

  refreshModules = () => {
    fetchAllModules(this.props.configuration.data.id)
      .then((modules) => {
        this.setState({ modules: modules, loading: false });
      })
      .catch(() => {
        this.setState({ modules: [], loading: false });
      });
  };
}

export default withRouter(connect(mapStateToProps)(AppModuleScreen));
