import React from 'react';
import Toggle from 'react-toggle';
import ReactGA from 'react-ga';
import { NotificationManager } from 'react-notifications';
import { fetchFoodSettings, updateFoodSettings } from '../actions/settings';

const FEATURE_FLAG_HIDE_PRICES = 'hidePrices';
const FEATURE_FLAG_READ_ONLY = 'readOnly';
const FEATURE_FLAG_TAKEOUT = 'takeOut';

const configurable_settings = [FEATURE_FLAG_HIDE_PRICES, FEATURE_FLAG_READ_ONLY, FEATURE_FLAG_TAKEOUT];
const settings = [
  {
    title: 'Hide Prices',
    description: 'Enable to hide all prices on menu. If disabled, all prices will show on menu and during ordering process.',
    type: 'toggle',
    node: FEATURE_FLAG_HIDE_PRICES,
  },
  {
    title: 'Read Only',
    description: 'Enable to remove ability to place an order. Menu becomes read-only to the end user. If disabled, user can place orders.',
    type: 'toggle',
    node: FEATURE_FLAG_READ_ONLY,
  },
  {
    title: 'TakeOut',
    description: 'Enable to support take-out option when placing an order. If disabled, user will not be presented with this option.',
    type: 'toggle',
    node: FEATURE_FLAG_TAKEOUT,
  },
]

export default class FoodModuleSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      flags: {},
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.refreshSettings();
  }

  refreshSettings = () => {
    fetchFoodSettings(this.props.site, this.props.moduleId).then( flags => {
      this.setState({
        flags: flags,
        loading: false,
      });
    }).catch( () => {
      this.setState({
        flags: {},
        loading: false,
      })
    });
  }

  render() {
    return (
          <div className="SettingsContainer">
            {
              settings.map( item => {
                // check if we support this flag
                if (this.state.flags[item.node] != null) {
                  return (
                    <div key={item.title} className="SettingsItem" style={{display: 'flex', flexDirection: 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row', flex: 1, justifyItems: 'center'}}>
                        <div style={{flex: 1}}>
                          <h1>{item.title}</h1>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          {
                            this.renderControl(item.type, item.node, this.state.flags[item.node])
                          }
                        </div>
                      </div>
                      <div>
                        <h2>{item.description}</h2>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            }
          </div>
    )
  }

  renderControl = (type, node, value) => {
    if (type === 'toggle') {
      return <Toggle defaultChecked={value} onChange={this.onSettingsChanged(node)} />;
    }
  }

  onSettingsChanged = node => event => {
    if (configurable_settings.includes(node)) {
      const checked = event.target.checked;
      updateFoodSettings(this.props.site, this.props.moduleId, node, checked).then( () => {
        NotificationManager.success('Settings have been updated');
        this.refreshSettings();

        ReactGA.event({
          category: 'App Management',
          action: 'Toggled Food Module Setting',
          label: node,
          value: Number(checked)
        });

      }).catch( () => {
        NotificationManager.error('Unable to update settings');
      })
    }
  }
}