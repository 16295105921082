import appConfig from "./app/config";
import foodConfig from "./food/config";
import signConfig from "./sign/config";
import organizationConfig from "./organization/config";
import ticketingConfig from "./ticketing/config";

export const ModuleMap = {
  app: appConfig,
  sign: signConfig,
  ticketing: ticketingConfig,
  food: foodConfig,
  organization: organizationConfig,
};
