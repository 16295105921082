import store from '../store.js';

import { getDatabase, ref, get } from 'firebase/database';

export async function fetchConfiguration(id) {
  store.dispatch({
    type: 'CONFIG_FETCHING',
    payload: {}
  });

  try {

    // site config
    const configRef = ref(getDatabase(), `/sites/${id}/config`);
    const data = await get(configRef);

    // permissions
    const permissionsRef = ref(getDatabase(), `/permissions/`);
    const permissionsData = await get(permissionsRef);

    // global config
    const globalConfigRef = ref(getDatabase(), `/config/global/`);
    const globalConfigData = await get(globalConfigRef);

    store.dispatch({
      type: 'CONFIG_SUCCESS',
      payload: data.val(),
      global: globalConfigData.val(),
      site: id,
      permissions: permissionsData.val()
    });

  } catch {
    store.dispatch({
      type: 'CONFIG_FAILURE',
      payload: {}
    });
  }
}
