import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import 'react-toggle/style.css';
import { Input, Button } from 'reactstrap';
import { fetchNotificationEmails, updateNotificationEmails } from '../actions/orders';
import { NotificationManager } from 'react-notifications';

const mapStateToProps = function(state){
  return {
    configuration: state.configuration,
  }
}

class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newEmail: '',
      notificationEmails: []
    }
  }
  componentDidMount() {
    ReactGA.pageview('/modules/food/menu/settings/');
    this.refreshSettings();
  }

  refreshSettings = () => {
    fetchNotificationEmails(this.props.configuration.data.id).then( emails => {
      this.setState({ notificationEmails: emails });
    })
  }

  render() {
    return (
      <div>
        <ModuleHeader title='Food Services' subTitle='Settings' />
        <div className="Body">
          <div className="SettingsContainer">
            <div className="SettingsItem" style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'row', flex: 1, justifyItems: 'center'}}>
                <div style={{flex: 1}}>
                  <h1>Order Notification Emails</h1>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {/* <Toggle defaultChecked={true} /> */}
                </div>
              </div>
              <div style={{borderBottom: '1px solid #d3d3d3', paddingBottom: 10}}>
                <h2>Email Addresses to receive notifications when a food order is placed for {this.props.configuration.data.id}</h2>
              </div>
              <div className="SettingsEmailList" >
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div style={{width: 250, paddingRight: 10}}>
                    <Input type="email" placeholder='Add email address' value={this.state.newEmail} onChange={ event => this.setState({ newEmail: event.target.value }) } />
                  </div>
                  <Button color="success" style={{width: 50}} disabled={!this.state.newEmail || this.state.newEmail.trim().length === 0} onClick={ () => {
                    // this.setState({ notificationEmails: this.state.notificationEmails.concat(this.state.newEmail), newEmail: ''});
                    this.onEmailListChange(this.state.notificationEmails.concat(this.state.newEmail));
                  }}>+</Button>
                </div>
                
                {
                  this.state.notificationEmails
                  ?
                  this.state.notificationEmails.map( (email, index) => {
                    return (
                      <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 5}}>
                        <div className="SettingsEmailItem">{email}</div>
                        <img alt='delete' src={require('./../../../img/delete.png')} style={{marginLeft: 10, height: 15, width: 15, cursor: 'pointer'}} onClick={ () => {
                          let newEmails = this.state.notificationEmails;
                          newEmails.splice(index, 1);
                          this.onEmailListChange(newEmails);
                        }} />
                      </div>
                    );
                  })
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderControl = (type, node, value) => {
    if (type === 'toggle') {
      return <Toggle defaultChecked={value} onChange={this.onSettingsChanged(node)} />;
    }
  }

  onEmailListChange = newList => {
    updateNotificationEmails(this.props.configuration.data.id, newList).then( () => {
      NotificationManager.success('Settings have been updated');
      this.setState({ newEmail: '' });
      this.refreshSettings();

      ReactGA.event({
        category: 'Food Services',
        action: 'Modify Food Services Settings'
      });

    }).catch( () => {
      NotificationManager.error('Unable to update settings');
    }) 
  }
}

export default connect(mapStateToProps)(SettingsScreen)
