import React from 'react';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import MenuView from './MenuView';
import LoadingScreen from '../../../screens/LoadingScreen';
import { fetchPermanentMenu, savePermanentMenu } from '../actions';

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  }
}

class PermanentMenuScreen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      menu: {}
    };
    this.draftMenu = {};
  }

  componentDidMount() {
    ReactGA.pageview('/modules/food/menu/permanent/');
    this.setState({ processing: true });
    this.refreshMenu();
  }

  refreshMenu = () => {
    fetchPermanentMenu(this.props.configuration.data.id).then(menu => {
      this.setState({ menu: menu, processing: false });
    }).catch(() => {
      this.setState({ processing: false });
    })
  }

  render() {
    return (
      <div>
        <ModuleHeader title='Food Services' subTitle='Permanent Menu' action actionTitle="Save & Publish" onAction={this.onSaveAndPublish} />
        {this.renderMenu()}
      </div>
    );
  }

  renderMenu = () => {
    if (this.state.processing) {
      return <LoadingScreen />
    }

    return (
      <MenuView menu={this.state.menu} onSave={this.onMenuSave} />
    )
  }

  onSaveAndPublish = () => {
    savePermanentMenu(this.props.configuration.data.id, this.draftMenu).then(() => {
      NotificationManager.success('Updated Permanent Menu')

      ReactGA.event({
        category: 'Food Services',
        action: 'Publish Permanent Menu'
      });

    }, (error) => {
      NotificationManager.error(error)
    });
  }

  onMenuSave = (id, menu) => {
    this.draftMenu = menu;
  }
}

export default connect(mapStateToProps)(PermanentMenuScreen)