import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModuleSettingsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      confirm: false
    }
  }

  render() {
    const {
      active,
      confirmColor,
      cancelColor,
      deleteColor,
      showDelete,
      title
    } = this.props;

    const deleteText = this.state.confirm ? 'Please Confirm Deletion' : 'Delete';

    return (
      <div>
        <Modal isOpen={active} toggle={this.onCancel} className={this.props.className}>
          <ModalHeader><div>{title}</div></ModalHeader>
          <ModalBody>
            {this.props.children}
          </ModalBody>
          <ModalFooter>
            <div style={{flex: 1, display: 'flex', flexDirection: 'row'}}>
              { showDelete ? <Button color={deleteColor} onClick={this.onDelete}>{deleteText}</Button> : null }
              <div style={{flex: 1}}/>
              <Button color={cancelColor} onClick={this.onCancel} style={{marginRight: 5}}>Cancel</Button>
              <Button color={confirmColor} onClick={this.onCreate}>Save</Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  onCreate = () => {
    if (this.props.onCreate) {
      this.props.onCreate({});
    }
  }

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onDelete = () => {
    if (!this.state.confirm) {
      this.setState({confirm: true});
    } else if (this.props.onDelete) {
      this.props.onDelete();
    }
  }
}

export default ModuleSettingsModal;