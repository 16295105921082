import React from "react";
import { TicketListView } from "./view/TicketListView";
import { TicketingSettings } from "./view/Settings";
import { TicketView } from "./view/TicketView";

const config = {
  sidebar: {
    title: "Ticketing",
    items: [
      {
        title: "Concierge",
        path: "/modules/ticketing/concierge",
      },
      {
        title: "Maintenance",
        path: "/modules/ticketing/maintenance",
      },
      {
        title: "Settings",
        path: "/modules/ticketing/settings",
      },
    ],
  },
  routes: [
    {
      path: "/modules/ticketing/concierge/:id",
      main: () => <TicketView type="concierge" title="Concierge" />,
    },
    {
      path: "/modules/ticketing/concierge/",
      main: () => <TicketListView type="concierge" title="Concierge" />,
    },
    {
      path: "/modules/ticketing/maintenance/:id",
      main: () => <TicketView type="maintenance" title="Maintenance" />,
    },
    {
      path: "/modules/ticketing/maintenance/",
      main: () => <TicketListView type="maintenance" title="Maintenance" />,
    },
    {
      path: "/modules/ticketing/settings",
      main: () => <TicketingSettings types={["concierge", "maintenance"]} />,
    },
  ],
};

export default config;
