import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Alert,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import ModuleHeader from "../../../components/ModuleHeader";
import BackButton from "../../../components/BackButton";
import ImageUploader from "./ImageUploader";
import ModalDialog from "../../../components/ModalDialog";
import ImageSelectorModal from "./ImageSelectorModal";
import { ClipLoader } from "react-spinners";
import {
  deleteInformationItem,
  updateInformationItem,
  saveInformationItem,
} from "../action";
import { NotificationManager } from "react-notifications";
import ReactGA from "react-ga";
import { SimpleEditor } from "../../../components/SimpleEditor";
import { HuePicker } from "react-color";

const mapStateToProps = function (state) {
  return {
    configuration: state.configuration,
  };
};
class NewsItemScreen extends React.Component {
  constructor(props) {
    super(props);

    // item to edit if required
    const existingItem = this.props.location.state.item || {};
    const bCreateMode = existingItem.id == null;

    this.state = {
      editMode: !bCreateMode,
      editModeKey: existingItem.id,
      fetching: false,
      processing: false,
      confirmDelete: false,
      errors: [],
      published_ipad: bCreateMode ? true : existingItem.published_ipad ?? true,
      published_sign: bCreateMode ? true : existingItem.published_sign ?? true,
      title: existingItem.title,
      description: existingItem.description,
      imageUrl: existingItem.imageUrl,
      initialWidthOfContent: 0,
      dropdownOpen: false,
      previewModeText: "Digital Sign",
      previewMode: "ds",
      selectImageModal: false,
      ds_image_only: bCreateMode ? false : existingItem.ds_image_only ?? false,
      contain_image: bCreateMode ? false : existingItem.contain_image ?? false,
      signOptions: {
        overlay: bCreateMode
          ? "half"
          : existingItem.signOptions?.overlay ?? "half",
        fullImage: bCreateMode
          ? false
          : existingItem.signOptions?.fullImage ?? false,
        backgroundColor: bCreateMode
          ? "#e6e6e6"
          : existingItem.signOptions?.backgroundColor ?? "#e6e6e6",
        backgroundImage: bCreateMode
          ? true
          : existingItem.signOptions?.backgroundImage ?? true,
      },
    };
  }

  componentDidMount() {
    const mode = this.state.editMode ? "edit" : "create";
    ReactGA.pageview(`/modules/sign/news/${mode}`);

    if (this.state.initialWidthOfContent === 0) {
      this.setState({
        initialWidthOfContent: this.divElement.clientWidth - 10,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.editMode &&
      this.props.configuration.data.id !== this.props.location.state.site
    ) {
      this.goBack();
    }
  }

  render() {
    const disableInput = this.state.processing;
    const mode = this.state.editMode ? "Edit" : "Create";
    return (
      <div ref={(divElement) => (this.divElement = divElement)}>
        <ModuleHeader
          title="Digital Sign"
          subTitle={`${mode} News & Events Item`}
        />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <BackButton title="Back to News & Events" onClick={this.goBack} />
          <div style={{ flex: 1 }} />
        </div>
        <div style={{ borderTop: "1px solid #D3D3D3", paddingBottom: 10 }} />
        <Form onSubmit={this._onSaveItem}>
          <FormGroup>
            <Label for="title" style={{ fontSize: "1.5em" }}>
              Title
            </Label>
            <Input
              type="text"
              name="title"
              id="title"
              defaultValue={this.state.title}
              onChange={(event) => this.setState({ title: event.target.value })}
              disabled={disableInput}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description" style={{ fontSize: "1.5em" }}>
              Description
            </Label>
            <SimpleEditor
              value={this.state.description || ""}
              onChange={(value) => this.setState({ description: value })}
              disabled={disableInput}
            />
          </FormGroup>
          <FormGroup>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label for="image" style={{ flex: 1, fontSize: "1.5em" }}>
                Image
              </Label>
              <Dropdown
                direction="left"
                size="sm"
                isOpen={this.state.dropdownOpen}
                toggle={() => {
                  this.setState((prevState) => ({
                    dropdownOpen: !prevState.dropdownOpen,
                  }));
                }}
              >
                <DropdownToggle caret>
                  Previewing {this.state.previewModeText}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) =>
                      this.setState({
                        previewMode: "ds",
                        previewModeText: e.currentTarget.textContent,
                      })
                    }
                  >
                    Digital Sign
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) =>
                      this.setState({
                        previewMode: "ipad",
                        previewModeText: e.currentTarget.textContent,
                      })
                    }
                  >
                    iPad
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>

            <br />
            <ImageUploader
              imageUrl={this.state.imageUrl}
              previewMode={this.state.previewMode}
              signOptions={this.state.signOptions}
              imageOnlyDS={this.state.ds_image_only}
              containImage={this.state.contain_image}
              width={this.state.initialWidthOfContent}
              title={this.state.title}
              details={this.state.description}
              initialValue={this.state.imageUrl}
              onUploadImage={() => this.setState({ selectImageModal: true })}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <span className="SmallNote">
                (this preview is an appromixation)
              </span>
            </div>
          </FormGroup>
          <br />
          <Label for="options" style={{ fontSize: "1.5em" }}>
            Digital Sign Options
          </Label>
          <div className="SettingsContainer">
            <div className="SettingsItem">
              <h1>Text Options</h1>
              <h2>
                Select the option with regards to how text (title and content)
                are displayed
              </h2>
              <div style={{ marginTop: 10, marginBottom: 5 }}>
                <FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="TextOption"
                        checked={this.state.signOptions.overlay === "half"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            this.setState((state) => ({
                              signOptions: Object.assign(state.signOptions, {
                                overlay: "half",
                              }),
                            }));
                          }
                        }}
                      />
                      {` Display Text on left half of canvas (Default)`}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="TextOption"
                        checked={this.state.signOptions.overlay === "full"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            this.setState((state) => ({
                              signOptions: Object.assign(state.signOptions, {
                                overlay: "full",
                              }),
                            }));
                          }
                        }}
                      />
                      {` Display Text on full width of canvas`}
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="radio"
                        name="TextOption"
                        checked={this.state.signOptions.overlay === "none"}
                        onChange={(event) => {
                          if (event.target.checked) {
                            this.setState((state) => ({
                              signOptions: Object.assign(state.signOptions, {
                                overlay: "none",
                              }),
                            }));
                          }
                        }}
                      />
                      {` Do not display any text, only display the Image`}
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
            </div>

            <div className="SettingsItem">
              <h1>Background Options</h1>
              <h2>
                Select options of the primary image and how it is displayed
              </h2>
              <div style={{ marginTop: 10, marginBottom: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="BackgroundOption"
                      checked={this.state.signOptions.backgroundImage}
                      onChange={(event) => {
                        if (event.target.checked) {
                          this.setState((state) => ({
                            signOptions: Object.assign(state.signOptions, {
                              backgroundImage: true,
                            }),
                          }));
                        }
                      }}
                    />
                    {` Display image as background`}
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="BackgroundOption"
                      checked={!this.state.signOptions.backgroundImage}
                      onChange={(event) => {
                        if (event.target.checked) {
                          this.setState((state) => ({
                            signOptions: Object.assign(state.signOptions, {
                              backgroundImage: false,
                            }),
                          }));
                        }
                      }}
                    />
                    {`Display solid colour as background`}
                  </Label>
                </FormGroup>
                <div style={{ marginLeft: 20, marginTop: 10 }}>
                  <HuePicker
                    color={this.state.signOptions.backgroundColor}
                    onChange={(color) => {
                      this.setState((state) => ({
                        signOptions: Object.assign(state.signOptions, {
                          backgroundColor: color.hex,
                        }),
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="SettingsItem" style={{ borderBottom: "none" }}>
              <h1>Image Options</h1>
              <div style={{ marginTop: 10, marginBottom: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.signOptions.fullImage}
                      onChange={(event) => {
                        const checked = event.target.checked;
                        this.setState((state) => ({
                          signOptions: Object.assign(state.signOptions, {
                            fullImage: checked,
                          }),
                        }));
                      }}
                      disabled={disableInput}
                    />
                    Show Entire Image on right hand side of canvas
                  </Label>
                </FormGroup>
              </div>
            </div>
          </div>
          <br />
          <Label for="options" style={{ fontSize: "1.5em" }}>
            iPad Options
          </Label>
          <div className="SettingsContainer">
            <div className="SettingsItem" style={{ border: "none" }}>
              <div style={{ marginTop: 10, marginBottom: 5 }}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.contain_image}
                      onChange={(event) =>
                        this.setState({ contain_image: event.target.checked })
                      }
                      disabled={disableInput}
                    />
                    Show Entire Image (Ensure entire image is visible)
                  </Label>
                </FormGroup>
              </div>
            </div>
          </div>
          <hr />
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.published_ipad}
                onChange={(event) =>
                  this.setState({ published_ipad: event.target.checked })
                }
                disabled={disableInput}
              />
              Publish to iPad
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.published_sign}
                onChange={(event) =>
                  this.setState({ published_sign: event.target.checked })
                }
                disabled={disableInput}
              />
              Publish to Digital Sign
            </Label>
          </FormGroup>

          {this.state.errors.length > 0 ? (
            <Alert color="danger" style={{ marginTop: 10 }}>
              {this.state.errors.map((error) => {
                return (
                  <h1 className="ErrorItem" key={error}>
                    {error}
                  </h1>
                );
              })}
            </Alert>
          ) : null}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 40,
              }}
            >
              <Button
                color="success"
                type="submit"
                disabled={disableInput}
                style={{ marginRight: 5 }}
              >
                {this.state.editMode ? "Update Item" : "Create Item"}
              </Button>
              <Button
                onClick={() => {
                  this.props.history.push("/modules/sign/news/");
                }}
                color="danger"
                disabled={disableInput}
                style={{ marginRight: 5 }}
              >
                Cancel
              </Button>
              <ClipLoader loading={this.state.processing} />
            </div>
            {this.state.editMode ? (
              <div
                style={{
                  alignItems: "right",
                  display: "flex",
                  marginTop: 10,
                  marginBottom: 40,
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({ confirmDelete: true });
                  }}
                  color="danger"
                  disabled={disableInput}
                >
                  Delete Item
                </Button>
                <ModalDialog
                  active={this.state.confirmDelete}
                  title="Delete Confirmation"
                  body="Are you sure you want to delete this item?"
                  confirmColor="danger"
                  cancelColor="secondary"
                  onConfirm={() => this._deleteInformationItem()}
                  onCancel={() => {
                    this.setState({ confirmDelete: false });
                  }}
                />
              </div>
            ) : null}
          </div>
        </Form>

        <ImageSelectorModal
          title="Select Image"
          building={this.props.configuration.data.id}
          active={this.state.selectImageModal}
          onCancel={() => this.setState({ selectImageModal: false })}
          onSuccess={(imageUrl) => {
            this.setState({
              imageUrl: imageUrl,
              selectImageModal: false,
            });
          }}
        />
      </div>
    );
  }

  goBack = () => {
    this.props.history.push("/modules/sign/news");
  };

  _deleteInformationItem = () => {
    // ensure we are in edit mode
    let errors = [];
    if (this.state.editMode) {
      if (this.state.editModeKey.length > 0) {
        deleteInformationItem(
          this.props.configuration.data.id,
          this.state.editModeKey
        ).then(
          () => {
            NotificationManager.success("Information Item Deleted");
            this.props.history.push("/modules/sign/news");

            ReactGA.event({
              category: "Digital Sign",
              action: "Delete Information Item",
            });
          },
          (error) => {
            errors.push(error);
            this.setState({ processing: false, errors: errors });
          }
        );
      }
    }
  };

  _onSaveItem = (event) => {
    let payload = {
      title: this.state.title,
      imageUrl: this.state.imageUrl || "",
      description: this.state.description,
      published_ipad: this.state.published_ipad,
      published_sign: this.state.published_sign,
      ds_image_only: this.state.ds_image_only,
      contain_image: this.state.contain_image,
      signOptions: {
        overlay: this.state.signOptions.overlay,
        fullImage: this.state.signOptions.fullImage,
        backgroundImage: this.state.signOptions.backgroundImage,
        backgroundColor: this.state.signOptions.backgroundColor,
      },
    };

    // validation
    let errors = [];
    if (this.state.title == null || this.state.title.trim().length === 0) {
      errors.push("Title is required");
    }

    if (
      this.state.description == null ||
      this.state.description.trim().length === 0
    ) {
      errors.push("Description is required");
    }

    if (errors.length === 0) {
      this.setState({ processing: true });

      if (this.state.editMode) {
        ReactGA.event({
          category: "Digital Sign",
          action: "Modify News & Information Item",
        });

        updateInformationItem(
          this.props.configuration.data.id,
          this.state.editModeKey,
          payload
        ).then(
          () => {
            NotificationManager.success("Information Item Updated");
            this.setState({ processing: false, errors: [] });
          },
          (error) => {
            errors.push(error);
            this.setState({ processing: false, errors: errors });
          }
        );
      } else {
        ReactGA.event({
          category: "Digital Sign",
          action: "Create News & Information Item",
        });

        saveInformationItem(this.props.configuration.data.id, payload).then(
          () => {
            NotificationManager.success("New Information Item Created");
            this.props.history.push("/modules/sign/news");
          },
          (error) => {
            errors.push("An error occurred, unable to complete item");
            this.setState({ processing: false, errors: errors });
          }
        );
      }
    } else {
      this.setState({ errors: errors });
    }

    event.preventDefault();
  };
}

export default withRouter(connect(mapStateToProps)(NewsItemScreen));
