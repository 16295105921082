import { useEffect, useState } from "react";
import { fetchTicket, updateTicketState } from "../action";
import { useSelector } from "react-redux";
import ModuleHeader from "../../../components/ModuleHeader";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Badge, Button } from "reactstrap";
import BackButton from "../../../components/BackButton";
import LoadingScreen from "../../../screens/LoadingScreen";
import { NotificationManager } from "react-notifications";

const StateColor = {
  open: "success",
  closed: "danger",
};

const TicketContent = ({ ticket, onTicketStateChange }) => {
  return (
    <>
      <div
        className="TicketInfo"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9F9F9",
          borderRadius: 4,
          padding: 8,
        }}
      >
        <div style={{ display: "flex" }}>
          <p style={{ flex: 1, padding: 0, margin: 0 }}></p>
          <Badge color={StateColor[ticket.state]}>{`${ticket.state}`}</Badge>
        </div>
        <div style={{ display: "flex" }}>
          <ul>
            <li>Created: </li>
            <li>Resident: </li>
            <li>Email: </li>
            <li>Phone: </li>
            <li>Room: </li>
          </ul>
          <ul>
            <li>
              <b>{`${moment(ticket.createdDate).format(
                "YYYY-MM-DD hh:mm a"
              )}`}</b>
            </li>
            <li>{ticket.user?.name}</li>
            <li>{ticket.user?.email}</li>
            <li>{ticket.user?.phone}</li>
            <li>{ticket.user?.room}</li>
          </ul>
        </div>
      </div>
      <div
        style={{
          padding: 20,
          border: 4,
          borderColor: "#F9F9F9",
          borderStyle: "solid",
        }}
      >
        <p>{ticket.description}</p>
      </div>
      <div
        className="TicketInfo"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          backgroundColor: "#F9F9F9",
          borderRadius: 4,
          padding: 8,
        }}
      >
        {ticket.state == "open" ? (
          <Button
            color="danger"
            onClick={() => {
              onTicketStateChange("closed", ticket.id);
            }}
          >
            Close Ticket
          </Button>
        ) : null}
        {ticket.state == "closed" ? (
          <Button
            color="success"
            onClick={() => {
              onTicketStateChange("open", ticket.id);
            }}
          >
            Re-Open Ticket
          </Button>
        ) : null}
      </div>
    </>
  );
};

const ErrorView = () => {
  return (
    <div style={{ flexDirection: "row", flex: 1, textAlign: "center" }}>
      <label>No Ticket Found</label>
    </div>
  );
};

export const TicketView = ({ type, title }) => {
  const configuration = useSelector((state) => state.configuration);
  const history = useHistory();
  const params = useParams();
  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    reload();
  }, [configuration]);

  const reload = async () => {
    setLoading(true);
    const ticket = await fetchTicket(configuration.data.id, type, params.id);
    if (ticket) {
      setTicket(ticket);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onNewTicketState = async (newState, ticketId) => {
    if (
      await updateTicketState(configuration.data.id, type, ticketId, newState)
    ) {
      NotificationManager.success("Ticket status updated");
      reload();
    } else {
      NotificationManager.error("Unable to update ticket state");
    }
  };

  return (
    <>
      <ModuleHeader title="Ticketing" subTitle={title} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <BackButton title="Back to Tickets" onClick={history.goBack} />
      </div>
      {error ? (
        <ErrorView />
      ) : loading ? (
        <LoadingScreen />
      ) : (
        <TicketContent ticket={ticket} onTicketStateChange={onNewTicketState} />
      )}
    </>
  );
};
