import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

class PreviewIpad extends Component {
  render() {
    const { title, details } = this.props;
    return (
      <div style={{ backgroundColor: "#F9F9F9" }}>
        <div className="CenteredImage">{this.props.children}</div>
        <div className="PreviewBasic">
          <span
            style={{
              paddingTop: 10,
              paddingLeft: 20,
              fontFamily: "Helvetica Neue",
              fontSize: 24,
              fontWeight: "800",
              color: "#000",
            }}
          >
            {title}
          </span>
          <h2
            style={{
              paddingTop: 40,
              padding: 20,
              fontFamily: "Helvetica Neue",
              fontSize: 20,
              fontWeight: "300",
              color: "#757a90",
            }}
          >
            <ReactMarkdown children={details} />
          </h2>
        </div>
      </div>
    );
  }
}

export default PreviewIpad;
