import React from 'react';
import { DayPickerRangeController } from 'react-dates';

export default class WeekRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: 'startDate',
      startDate: null,
      endDate: null
    }

    this.onDatesChange = this.onDatesChange.bind(this);
  }
  
  render() {
    return (
      <DayPickerRangeController
        numberOfMonths={1}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        startDateOffset={this.onStartDateOffset}
        endDateOffset={this.onEndDateOffset}
        onDatesChange={this.onDatesChange}
        firstDayOfWeek={1}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput || 'startDate' })}
      />
    );
  }

  onDatesChange({ startDate, endDate }) {
    if (this.props.onDatesChange) {
      this.props.onDatesChange(startDate, endDate);
    }
  }

  onStartDateOffset = day => {
    return day.subtract(day.day() -1, 'days');
  }

  onEndDateOffset = day => {
    return day.add(7-day.day(), 'days');
  }
}