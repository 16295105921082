import React from 'react';
import OrderScreen from './view/OrderScreen';
import WeeklyMenuScreen from './view/WeeklyMenuScreen';
import PermanentMenuScreen from './view/PermanentMenuScreen';
import ClosedOrderScreen from './view/ClosedOrderScreen';
import SettingsScreen from './view/SettingsScreen';
import StaticMenuScreen from './view/StaticMenuScreen';

const config = {
  sidebar: {
    title: 'Food Services',
    items: [
      {
        title: 'Orders',
        path: '/modules/food/orders'
      },
      {
        title: 'Weekly Menu',
        path: '/modules/food/menu/weekly'
      },
      {
        title: 'Permanent Menu',
        path: '/modules/food/menu/permanent'
      },
      {
        title: 'PDF Menus',
        path: '/modules/food/menu/static'
      },
      {
        title: 'Settings',
        path: '/modules/food/menu/settings'
      }
    ]
  },
  routes: [
    {
      path: '/modules/food/orders/closed',
      main: () => <ClosedOrderScreen />
    },
    {
      path: '/modules/food/orders/',
      main: () => <OrderScreen />
    },
    {
      path: '/modules/food/menu/static',
      main: () => <StaticMenuScreen />
    },
    {
      path: '/modules/food/menu/weekly',
      main: () => <WeeklyMenuScreen />
    },
    {
      path: '/modules/food/menu/permanent',
      main: () => <PermanentMenuScreen />
    },
    {
      path: '/modules/food/menu/settings',
      main: () => <SettingsScreen />
    }
  ]
};

export default config;
