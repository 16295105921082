import { equalTo, getDatabase, orderByChild, ref, query, get, set, push } from 'firebase/database';

export function fetchAllAgents(site) {
  return new Promise(async (resolve, reject) => {
    try {
      const agentListRef = query(ref(getDatabase(), '/superusers/'), orderByChild("type"), equalTo("agent"));
      const agentListData = await get(agentListRef);

      const invitationRef = ref(getDatabase(), `/invitations`);
      const invitationData = await get(invitationRef);

      // agents
      let agents = [];
      for (let id in agentListData.val()) {
        const user = agentListData.val()[id];
        if (user.sites) {
          for (const userSite in user.sites) {
            if (userSite === site) {
              let permissions = [];
              for (let permission in user.permissions) {
                if (user.permissions[permission]) {
                  permissions.push(permission);
                }
              }
              agents.push({
                id: id,
                email: user.email,
                permissions: permissions,
                pending: false
              });
              break;
            }
          }
        }
      }

      // invitations
      for (let id in invitationData.val()) {
        const invitation = invitationData.val()[id];
        if (site === invitation.site && invitation.permissions) {
          let permissions = [];
          for (let permission in invitation.permissions) {
            if (invitation.permissions[permission]) {
              permissions.push(permission);
            }
          }
          agents.push({
            id: id,
            email: invitation.email,
            permissions: permissions,
            pending: true
          });
        }
      }

      resolve(
        agents.sort((a, b) => {
          // primary sort on position
          if (a.email < b.email) {
            return -1;
          } else if (a.email > b.email) {
            return 1;
          }
          return 0;
        })
      );

    } catch (e) {
      reject();
    }
  });
}

export function fetchAgent(agentId, pending, site) {
  return new Promise(async (resolve, reject) => {
    if (pending) {

      try {
        const invitationRef = ref(getDatabase(), `/invitations/${agentId}`);
        const invitationData = await get(invitationRef);

        if (invitationData.val().site && invitationData.val().site === site) {
          let permissionsArr = [];
          const permissions = invitationData.val().permissions || {};
          for (const permission in permissions) {
            if (permissions[permission]) {
              permissionsArr.push(permission);
            }
          }
          resolve({
            id: agentId,
            email: invitationData.val().email,
            permissions: permissionsArr,
            pending: true
          });
        } else {
          reject();
        }

      } catch {
        reject();
      }

    } else {

      try {
        const agentRef = ref(getDatabase(), `/superusers/${agentId}`);
        const agentData = await get(agentRef);

        let permissionsArr = [];
        const permissions = agentData.val().permissions || {};
        for (const permission in permissions) {
          if (permissions[permission]) {
            permissionsArr.push(permission);
          }
        }
        resolve({
          id: agentId,
          email: agentData.val().email,
          permissions: permissionsArr,
          pending: false
        });
      } catch (e) {
        reject();
      }
    }
  });
}

export function modifyAgent(agentId, permissions, pending, site) {
  // convert permissions into something we can use
  let permissionsObject = {};
  permissions.forEach(permission => {
    permissionsObject[permission] = true;
  });

  return new Promise(async (resolve, reject) => {
    if (pending) {
      const invitationRef = ref(getDatabase(), `/invitations/${agentId}/permissions`);
      await set(invitationRef, permissionsObject);
      resolve();
    } else {
      const agentRef = ref(getDatabase(), `/superusers/${agentId}/permissions`);
      await set(agentRef, permissionsObject);
      resolve();
    }
  });
}

export function inviteAgent(email, permissions, site) {
  // convert permissions into something we can use
  let permissionsObject = {};
  permissions.forEach(permission => {
    permissionsObject[permission] = true;
  });

  return new Promise(async (resolve, reject) => {
    if (email.trim().length === 0) {
      return reject();
    }

    const lookup = `${email}_${site}`;
    const invite = {
      email: email,
      site: site,
      lookup: lookup,
      type: 'agent',
      permissions: permissionsObject
    };

    // check to see if an invite for this user already exists
    const invitationQuery = query(ref(getDatabase(), `/invitations`, orderByChild("lookup"), equalTo(lookup)));
    const invitationData = await get(invitationQuery);
    if (invitationData.exists()) {
      reject(); // already exists
    }

    const invitationRef = ref(getDatabase(), `/invitations`);
    await push(invitationRef, invite);
    resolve();
  });
}
