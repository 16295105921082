import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Badge, ListGroup, ListGroupItem, Input } from 'reactstrap';
import ReactGA from 'react-ga';

import ModuleHeader from '../../../components/ModuleHeader';
import LoadingScreen from '../../../screens/LoadingScreen';
import { fetchAllAgents } from '../action';

const mapStateToProps = function(state) {
  return {
    configuration: state.configuration
  };
};

class TeamManagementScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      agents: [],
      agentSearchFilter: null
    };
  }
  componentDidMount() {
    ReactGA.pageview('/modules/organization/team/');

    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configuration.data.id !== this.props.configuration.data.id) {
      this.refresh();
    }
  }

  refresh() {
    this.setState({ loading: true });

    fetchAllAgents(this.props.configuration.data.id)
      .then(agents => {
        this.setState({ agents: agents, loading: false });
      })
      .catch(() => {
        this.setState({ agents: [], loading: false });
      });
  }

  render() {
    return (
      <Fragment>
        <ModuleHeader title='Organization' subTitle='Team' />
        {this.renderAgents()}
      </Fragment>
    );
  }

  onSearchAgents = event => {
    this.setState({ agentSearchFilter: event.target.value });
  };

  renderAgents = () => {
    if (this.state.loading) {
      return <LoadingScreen />;
    } else {
      return (
        <Fragment>
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
            <div style={{ flex: 1 }} />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ paddingRight: 10 }}>
                <Input
                  style={{ padding: 10, height: 40 }}
                  type='search'
                  placeholder='Search Agents ...'
                  onChange={this.onSearchAgents}
                />
              </div>
              {/* <Button style={{height: 40}} onClick={this.onInviteUser}>Invite Agent</Button> */}
            </div>
          </div>

          <ListGroup>
            <ListGroupItem disabled style={{ backgroundColor: '#e8ecef', paddingTop: 0, paddingBottom: 0 }}>
              <div className='ListHeader' style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>agents</div>
              </div>
            </ListGroupItem>
            {this.state.agents.length > 0 ? (
              this.state.agents.map(agent => {
                // filter out based on search filter (email / firstname / lastname)
                const searchString = agent.email;
                if (
                  this.state.agentSearchFilter &&
                  !searchString.toLowerCase().includes(this.state.agentSearchFilter.toLowerCase())
                ) {
                  return null;
                }

                return (
                  <ListGroupItem key={agent.id} action style={{ cursor: 'pointer' }} onClick={this.onModifyUser(agent)}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ flex: 1 }}>
                        {agent.email} {agent.pending ? ' (pending)' : null}
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {agent.permissions.map(permission => (
                          <div key={permission} style={{ marginLeft: 10 }}>
                            <Badge>{permission}</Badge>
                          </div>
                        ))}
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })
            ) : (
              <div className='EmptyListResponse' style={{ marginTop: 20, textAlign: 'center' }}>
                There are no agents
              </div>
            )}
          </ListGroup>
        </Fragment>
      );
    }
  };

  onModifyUser = user => () => {
    this.props.history.push({
      pathname: '/modules/organization/team/agent',
      state: { user: user, building: this.props.configuration.data.id }
    });
  };

  onInviteUser = () => {
    this.props.history.push({
      pathname: '/modules/organization/team/agent',
      state: { building: this.props.configuration.data.id }
    });
  };
}

export default withRouter(connect(mapStateToProps)(TeamManagementScreen));
