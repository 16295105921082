import { getDatabase, ref, get, push, remove, update, set } from "firebase/database";

export function fetchFoodSettings(site, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data`);
      const data = await get(dataRef);
      resolve({
        readOnly: data.val().readOnly,
        hidePrices: data.val().hidePrices,
        takeOut: data.val().takeOut,
      });
    } catch {
      reject();
    }
  });
}

export function updateFoodSettings(site, moduleId, node, checked) {
  let setting = {};
  setting[node] = checked;
  return new Promise(async (resolve, reject) => {
    try {
      const dataRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/`);
      await update(dataRef, setting);
      resolve();
    } catch {
      reject();
    }
  });
}

export function fetchInformationSettings(site, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const dataRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data`);
      const data = await get(dataRef);
      // information items
      const rawInfo =
      (data.val().information && data.val().information.items) || {};
      const positionInfo =
        (data.val().information && data.val().information.position) || {};
      let infoItems = [];
      for (var infoItem in rawInfo) {
        let pData = positionInfo[infoItem] || {};
        let position = pData.position || 0;

        infoItems.push({
          id: infoItem,
          title: rawInfo[infoItem].title,
          content: rawInfo[infoItem].content,
          position: position,
        });
      }

      // contacts
      const rawContacts =
        (data.val().contacts && data.val().contacts.items) || {};
      const positionContact =
        (data.val().contacts && data.val().contacts.position) || {};
      let contacts = [];
      for (var contactKey in rawContacts) {
        let pData = positionContact[contactKey] || {};
        let position = pData.position || 0;

        contacts.push({
          id: contactKey,
          name: rawContacts[contactKey].name,
          contact: rawContacts[contactKey].contact,
          phone: rawContacts[contactKey].phone,
          email: rawContacts[contactKey].email,
          position: position,
        });
      }

      resolve({
        infoItems: infoItems.sort(_sortPosition),
        contactItems: contacts.sort(_sortPosition),
      });
    } catch {
      reject();
    }
  });
}

export function fetchSublanderModules(site, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const modulesRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/modules`);
      const data = await get(modulesRef);
      const ret = [];
      const modulesObj = data.val();
      for (var moduleId in modulesObj) {
        const metaData = modulesObj[moduleId].meta || {};
        ret.push({
          id: moduleId,
          color: metaData.landerColor || "#E6E6E6",
          icon: metaData.landerIcon || null,
          name: metaData.title,
          enabled: metaData.enabled,
          type: modulesObj[moduleId].type,
          description: modulesObj[moduleId].description,
          minVersion: metaData.minVersion,
          maxVersion: metaData.maxVersion,
        });
      }
      resolve(ret);
    } catch {
      reject();
    }
  });
}

export function addInformationItem(site, moduleId, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const itemsRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/information/items`);
      await push(itemsRef);
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateInformationItem(site, moduleId, id, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const itemRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/information/items/${id}/`);
      await update(itemRef, {
        title: data.title,
        content: data.content,
      });
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteInformationItem(site, moduleId, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const itemRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/information/items/${id}`);
      await remove(itemRef);
      resolve();
    } catch {
      reject("Something went wrong, unable to delete item.");
    }
  });
}

export function updateInformationItemPositionByIndex(site, moduleId, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const positionRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/information/position`);
      await set(positionRef, items);
      resolve();
    } catch {
      reject("Something went wrong, unable to update order.");
    }
  });
}

export function addContactItem(site, moduleId, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const newItemRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/contacts/items`);
      await push(newItemRef, data);
      resolve();
    } catch {
      reject();
    }
  });
}

export function updateContactItem(site, moduleId, id, data) {
  return new Promise(async (resolve, reject) => {
    try {
      const itemRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/contacts/items/${id}/`);
      await update(itemRef, {
        name: data.name,
        contact: data.contact,
        phone: data.phone,
        email: data.email,
      });
      resolve();
    } catch {
      reject();
    }
  });
}

export function deleteContactItem(site, moduleId, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const itemsRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/contacts/items/${id}`);
      await remove(itemsRef);
      resolve();
    } catch {
      reject("Something went wrong, unable to delete item.");
    }
  });
}

export function updateContactItemPositionByIndex(site, moduleId, items) {
  // create a structure to represent multiple items and the props that we are changing for them
  // {
  //   <id>: {
  //     position: <new_position>
  //   },
  //   <id>: {
  //     position: <new_position>
  //   },
  // }
  return new Promise(async (resolve, reject) => {
    try {
      const positionRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/contacts/position`);
      await set(positionRef, items);
      resolve();
    } catch {
      reject("Something went wrong, unable to update order.");
    }
  });
}

export function updateWebviewUri(site, moduleId, uri) {
  return new Promise(async (resolve, reject) => {
    try {
      const moduleRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/`);
      await update(moduleRef, { url: uri, uri: uri });
      resolve();
    } catch (e){
      console.log(e);
      reject();
    }
  });
}

export function fetchModuleById(site, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const moduleSnapshotRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}`);
      const moduleSnapshot = await get(moduleSnapshotRef);

      const moduleMetaSnapshot = moduleSnapshot.child("meta");
      resolve({
        id: moduleSnapshot.key,
        type: moduleSnapshot.child("type").val(),
        parentModuleId: moduleSnapshot.child("parentModuleId").val(),
        meta: {
          color: moduleMetaSnapshot.child("landerColor").val(),
          icon: moduleMetaSnapshot.child("landerIcon").val(),
          name: moduleMetaSnapshot.child("title").val(),
          description: moduleSnapshot.child("description").val(),
          trackingId: moduleMetaSnapshot.child("trackingId").val(),
          minVersion: moduleMetaSnapshot.child("minVersion").val(),
          maxVersion: moduleMetaSnapshot.child("maxVersion").val(),
          enabled: moduleMetaSnapshot.child("enabled").val(),
        },
        data: moduleSnapshot.child("data").val(),
      });
    } catch (error) {
      reject(error);
    }
  });
}

export function createModule(
  site,
  type,
  defaultMinVersion,
  parentModuleId = null
) {
  return new Promise(async (resolve, reject) => {
    try {
      const newModuleRef = ref(getDatabase(), `/sites/${site}/modules/`);
      const blankModule = {
        type: type,
        parentModuleId: parentModuleId,
        description: "",
        data: {},
        meta: {
          enabled: false,
          landerColor: "#C6C6C6",
          landerIcon: "",
          minVersion: defaultMinVersion,
          title: "New Module",
        },
      };
      const childRef = await push(newModuleRef, blankModule);

      resolve(childRef.key);
    } catch (error) {
      reject();
    }
  });
}

export function deleteModuleById(site, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const moduleRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}`);
      await remove(moduleRef);
      resolve();
    } catch (error) {
      reject();
    }
  });
}

export function updateFeedbackSettings(site, moduleId, description, emails) {
  return new Promise(async (resolve, reject) => {
    try {
      const moduleRef = ref(getDatabase(), `/sites/${site}/modules/${moduleId}/data/`);
      await update(moduleRef, {
        description: description,
        emails: emails,
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
}

function _sortPosition(a, b) {
  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}
